@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $spread $color;
    -moz-box-shadow:inset $top $left $blur $spread $color;
    box-shadow:inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin gradient($from, $to) {
  background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background: -moz-linear-gradient(top,  $from, $to);
  filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}

@mixin reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

@mixin reset-input {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
  &[type=search] {
    -webkit-appearance: textfield;
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

@mixin reset-textarea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

@mixin flex-box {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin absolute-position($alignment: 'left', $x: 0, $y: 'center') {
  position: absolute;
  z-index: 100;
  @if $alignment == 'left' {
    left: $x;
  } @else {
    right: $x;
  }
  @if $y == 'center' {
    top: 50%;
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  } @else {
    top: $y;
  }
}

@mixin transition($properties: all, $duration: .2s, $animation: ease-in-out) {
  -o-transition: $properties $duration $animation;
  -moz-transition: $properties $duration $animation;
  -webkit-transition: $properties $duration $animation;
  transition: $properties $duration $animation;
}

@mixin rotate($deg: -180deg) {
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -webkit-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin center($maxWidth, $leftRightPadding: false) {
  max-width: $maxWidth;
  width: 100%;
  margin: 0 auto;
  @if $leftRightPadding {
    padding-left: $leftRightPadding;
    padding-right: $leftRightPadding;
  }
}

@mixin calc-size($property, $percents, $pxSize, $operator) {
  $remSize: $pxSize / 16px;
  #{$property}: calc(#{$percents $operator #{$remSize}rem});
}

@mixin input-placeholder-color($color: transparent, $style: italic) {
  &::-webkit-search-decoration {
    display: none;
  }
  &::-webkit-input-placeholder {
    opacity: 1;
    color: $color;
    font-style: $style;
  }
  &::-moz-placeholder {
    opacity: 1;
    color: $color;
    font-style: $style;
  }
  &:-ms-input-placeholder {
    opacity: 1;
    color: $color;
    font-style: $style;
  }
  &:-moz-placeholder {
    opacity: 1;
    color: $color;
    font-style: $style;
  }
}
