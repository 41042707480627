@import './settings/settings';

.erom-editor {
  &__title {
    font-family: font(Montserrat), sans-serif;
    font-size: rem(28px);
    line-height: rem(47px);
    margin-bottom: rem(14px);
  }
  &__content--inner {
    font-family: font(Montserrat), sans-serif;
    h1 {
      color: color(black, .8);
      font-size: rem(38px);
      line-height: rem(47px);
      font-weight: 400;
      margin-bottom: rem(35px);
    }
    h2 {
      color: color(black);
      font-size: rem(28px);
      line-height: rem(34px);
      font-weight: 600;
      margin-bottom: rem(25px);
    }
    h3 {
      color: color(black);
      font-size: rem(24px);
      line-height: rem(34px);
      font-weight: 600;
      margin-bottom: rem(25px);
    }
    h4 {
      color: color(main);
      font-size: rem(22px);
      line-height: rem(30px);
      font-weight: 500;
      margin-bottom: rem(21px);
    }
    h5 {
      color: color(main);
      font-size: rem(18px);
      line-height: rem(26px);
      font-weight: 500;
      margin-bottom: rem(18px);
    }
    h6 {
      color: color(main);
      font-size: rem(18px);
      line-height: rem(26px);
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      margin-bottom: rem(18px);
    }
    p {
      color: color(main);
      font-size: rem(18px);
      line-height: rem(26px);
      max-width: rem(736px);
      font-family: Arial, Helvetica, sans-serif;
      margin-bottom: rem(20px);
      &.p2 {
        font-size: rem(18px);
        line-height: 1.56;
      }
    }
    code {
      font-family: font(JetBrains), monospace;
      font-size: rem(18px);
      line-height: rem(28px);
      color: color(main);
    }
    pre {
      code {
        display: inline-block;
        width: 100%;
        background-color: color(main, .1);
        padding: rem(2px) rem(4px);
      }
    }
    list-block,
    full-editor-block {
      li,
      dd {
        font-size: rem(18px);
        line-height: rem(20px);
        color: color(main);
        font-family: Arial, Helvetica, sans-serif;
        margin: 0 0 0 rem(25px);
      }
      li {
        img {
          margin: rem(15px) 0;
        }
        &:not(:last-of-type) {
          margin-bottom: rem(15px);
        }
        ul,
        ol {
          margin-top: rem(25px);
          margin-bottom: rem(25px);
        }
      }
      ul,
      ol {
        margin: 0 0 rem(40px);
        padding: 0;
        li {
          position: relative;
          ul,
          ol {
            padding: 0 0 0 rem(12px);
          }
        }
      }
      ol {
        & > li {
          & > ul {
            list-style: disc;
          }
        }
      }
      ul {
        li {
          &::marker {
            font-size: rem(18px);
            color: color(main, .7);
          }
          ul {
            li {
              &:before {
                background-color: transparent;
                border: rem(2px) solid color(main, .4);
              }
            }
          }
        }
      }
      ol {
        margin-left: rem(25px);
        li {
          padding-left: 0;
        }
      }
      dl {
        margin: 0;
        padding: 0;
        counter-reset: dt;
        dt {
          font-size: rem(20px);
          line-height: rem(28px);
          color: color(black);
          font-family: font(Montserrat), sans-serif;
          font-weight: 600;
          padding-left: rem(25px);
          counter-increment: dt;
          position: relative;
          &:before {
            content: counter(dt) '.';
            position: absolute;
            left: 0;
            top: 0;
            color: color(main);
            display: block;
            margin-right: rem(12px);
          }
        }
        dd {
          margin-top: rem(10px);
          & + dt {
            margin-top: rem(25px);
          }
        }
      }
    }
    a {
      background: unset;
      color: color(main);
      cursor: pointer;
    }
    p {
      a {
        &[href] {
          text-decoration: underline;
        }
      }
    }
    figure {
      display: inline-block;
      margin-bottom: rem(30px);
    }
    figcaption {
      display: block;
      font-size: rem(14px);
      line-height: rem(20px);
      color: color(black, .7);
      font-family: Arial, Helvetica, sans-serif;
      margin-top: rem(10px);
    }
    image-block {
      img {
        width: 100%;
        height: auto;
      }
    }
    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
    }
    hr {
      border: none;
      margin-top: rem(30px);
      margin-bottom: rem(30px);
      border-top: rem(1px) solid color(darkTeal, .3);
    }
    .table_block-type {
      display: block;
      overflow-x: auto;
      padding-bottom: rem(20px);
    }
    table-block,
    full-editor-block {
      table {
        border: rem(1px) solid #EBEBEB;
        thead {
          background-color: #EBEBEB;
          text-align: left;
          th {
            color: color(black);
            font-size: rem(18px);
            line-height: rem(26px);
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            padding: rem(30px) rem(20px);
            &:not(:last-of-type) {
              border-right: rem(1px) solid color(white);
            }
          }
        }
        tbody {
          td {
            color: color(black);
            font-size: rem(16px);
            line-height: rem(24px);
            font-family: Arial, Helvetica, sans-serif;
            padding: rem(20px);
            &:not(:last-of-type) {
              border-right: rem(1px) solid #EBEBEB;
            }
          }
          tr {
            &:nth-of-type(2n) {
              background-color: color(black, .03);
            }
          }
        }
        a {
          text-decoration: underline;
        }
      }
    }
    paragraph-block,
    heading-block,
    list-block,
    full-editor-block {
      max-width: rem(736px);
    }
    full-editor-block {
      position: relative;
      &:before {
        content: ' ';
        display: block;
        position: absolute;
        margin-top: negativeRem(50px);
        margin-left: negativeRem(30px);
        width: rem(1px);
        height: calc(100% + #{rem(50px)});
        background-color: color(black, .08);
      }
    }
    blockquote {
      position: relative;
      margin: rem(40px) 0;
      font-size: rem(20px);
      line-height: rem(28px);
      color: color(black);
      font-family: font(Montserrat), sans-serif;
      font-weight: 500;
      padding: rem(20px) 0 rem(20px) rem(28px);
      p {
        font-size: rem(20px);
        line-height: rem(28px);
        color: color(black);
        font-family: font(Montserrat), sans-serif;
        font-weight: 500;
        margin: 0;
        &:not(:last-of-type) {
          margin-bottom: rem(30px);
        }
      }
      cite {
        display: block;
        font-size: rem(16px);
        line-height: rem(24px);
        color: color(black);
        font-family: font(Montserrat), sans-serif;
        font-weight: 500;
        font-style: normal;
        margin: rem(30px) 0 0;
        &:empty {
          position: relative;
          &:before {
            content: 'Cite - add content';
            font-size: rem(16px);
            line-height: rem(24px);
            color: color(black);
            font-family: font(Montserrat), sans-serif;
            font-weight: 500;
            font-style: normal;
            pointer-events: none;
            opacity: .5;
          }
        }
      }
      &:before {
        content: ' ';
        position: absolute;
        display: block;
        width: rem(8px);
        height: 100%;
        left: 0;
        top: 0;
        background-color: color(greenMint);
      }
    }
    strong,
    b {
      font-weight: bold;
    }
    .erom-block {
      &__iframe {
        iframe {
          width: 100%;
        }
      }
      &__video {
        &--container {
          position: relative;
          overflow: hidden;
          width: 100%;
          padding-top: 56.25%;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      &__device-check {
        margin-left: negativeRem(38px);
        &__content {
          margin-bottom: rem(68px);
          padding: rem(30px);
          border-left: rem(8px) solid color(orange);
          background-color: color(orange, .03);
          position: relative;
        }
        &__title {
          @include flex-box;
          -webkit-align-items: flex-start;
          align-items: flex-start;
          font-weight: 500;
          font-size: rem(16px);
          line-height: rem(24px);
          color: color(black);
          font-family: font(Montserrat), sans-serif;
          svg {
            color: color(orange);
            margin-right: rem(10px);
            width: rem(20px);
            height: rem(21px);
            display: inline-block;
          }
        }
        &__close {
          @include reset-button;
          color: color(orange);
          position: absolute;
          top: rem(10px);
          right: rem(10px);
          width: rem(10px);
          height: rem(16px);
        }
      }
      &__title {
        position: relative;
        max-width: rem(753px);
      }
    }
  }
  &__group {
    margin-top: rem(50px);
  }
  &__rows-item {
    width: 100%;
    max-width: rem(1204px);
    margin: 0 auto;
  }
}

.erom {
  &-visually-hidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  &-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: rem(30px);
  }
  &-call-to-action-block {
    @include flex-box;
    font-weight: 500;
    border-bottom: rem(10px) solid color(greenMint);
    & > div {
      @include flex-box;
      width: 100%;
      padding: rem(100px);
      &:first-of-type {
        background-color: #F7F7F7;
        svg {
          color: #7F7F7F;
        }
        &:hover {
          svg {
            color: #000000;
          }
        }
      }
      &:last-of-type {
        background-color: color(darkTeal);
        svg {
          color: #7FADB6;
        }
        &:hover {
          svg {
            color: #ffffff;
          }
        }
      }
      & > div {
        max-width: rem(390px);
      }
    }
    &__title {
      font-size: rem(20px);
      line-height: rem(28px);
      margin-bottom: 0;
    }
    &__text {
      font-size: rem(16px);
      line-height: rem(24px);
      margin-top: rem(30px);
      a {
        color: currentColor;
        text-decoration: underline;
      }
    }
    &__actions {
      margin-top: rem(50px);
      a {
        display: inline-block;
        border: rem(1px) solid #7F7F7F;
        border-radius: rem(50px);
        padding: rem(15px) rem(20px);
        &:hover {
          border-color: currentColor !important;
        }
      }
    }
    &__icon {
      display: block;
      margin-bottom: rem(15px);
      svg {
        width: auto;
        height: rem(25px);
      }
    }
  }
}

.short-guide-section {
  position: relative;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    right: 100%;
    background-color: color(black, .03);
    width: 100vw;
    height: 100%;
  }
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: negativeRem(30px);
    width: rem(1px);
    height: 100%;
    background-color: color(white);
  }
}

.gray-left-border {
  position: relative;
  display: inline-block;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    margin-top: negativeRem(50px);
    margin-left: negativeRem(30px);
    width: rem(1px);
    height: calc(100% + #{rem(50px)});
    background-color: color(black, .08);
  }
  &:after {
    content: ' ';
    display: block;
    clear: both;
  }
}

@media screen and (max-width: rem(1180px)) {
  .erom-editor__group {
    margin-top: rem(20px);
  }
  .erom-call-to-action-block {
    display: block;
    & > div {
      display: block;
      padding: rem(100px) rem(50px);
      & > div {
        max-width: rem(680px);
      }
    }
  }
}

@media screen and (max-width: rem(600px)) {
  .erom-call-to-action-block {
    & > div {
      padding: rem(50px) rem(15px);
    }
  }
  .short-guide-section {
    .erom-editor__block-content {
      padding: rem(40px) rem(20px) !important;
    }
    &:after {
      left: 0;
    }
  }
}
