/*!
 * Bootstrap v2.3.2
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.tb-megamenu .clearfix {
  *zoom: 1;
}
.tb-megamenu .clearfix:before,
.tb-megamenu .clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .clearfix:after {
  clear: both;
}
.tb-megamenu .hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.tb-megamenu .input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tb-megamenu .row {
  margin-left: -20px;
  *zoom: 1;
}
.tb-megamenu .row:before,
.tb-megamenu .row:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .row:after {
  clear: both;
}
.tb-megamenu [class*="span"] {
  float: left;
  min-height: 1px;
  margin-left: 20px;
}
.tb-megamenu .row-fluid {
  width: 100%;
  *zoom: 1;
}
.tb-megamenu .row-fluid:before,
.tb-megamenu .row-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .row-fluid:after {
  clear: both;
}
.tb-megamenu .row-fluid [class*="span"] {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-left: 2.127659574468085%;
  *margin-left: 2.074468085106383%;
}
.tb-megamenu .row-fluid [class*="span"]:first-child {
  margin-left: 0;
}
.tb-megamenu .row-fluid .controls-row [class*="span"] + [class*="span"] {
  margin-left: 2.127659574468085%;
}
.tb-megamenu .row-fluid .span12 {
  width: 100%;
  *width: 99.94680851063829%;
}
.tb-megamenu .row-fluid .span11 {
  width: 91.48936170212765%;
  *width: 91.43617021276594%;
}
.tb-megamenu .row-fluid .span10 {
  width: 82.97872340425532%;
  *width: 82.92553191489361%;
}
.tb-megamenu .row-fluid .span9 {
  width: 74.46808510638297%;
  *width: 74.41489361702126%;
}
.tb-megamenu .row-fluid .span8 {
  width: 65.95744680851064%;
  *width: 65.90425531914893%;
}
.tb-megamenu .row-fluid .span7 {
  width: 57.44680851063829%;
  *width: 57.39361702127659%;
}
.tb-megamenu .row-fluid .span6 {
  width: 48.93617021276595%;
  *width: 48.88297872340425%;
}
.tb-megamenu .row-fluid .span5 {
  width: 40.42553191489362%;
  *width: 40.37234042553192%;
}
.tb-megamenu .row-fluid .span4 {
  width: 31.914893617021278%;
  *width: 31.861702127659576%;
}
.tb-megamenu .row-fluid .span3 {
  width: 23.404255319148934%;
  *width: 23.351063829787233%;
}
.tb-megamenu .row-fluid .span2 {
  width: 14.893617021276595%;
  *width: 14.840425531914894%;
}
.tb-megamenu .row-fluid .span1 {
  width: 6.382978723404255%;
  *width: 6.329787234042553%;
}
.tb-megamenu .row-fluid .offset12 {
  margin-left: 104.25531914893617%;
  *margin-left: 104.14893617021275%;
}
.tb-megamenu .row-fluid .offset12:first-child {
  margin-left: 102.12765957446808%;
  *margin-left: 102.02127659574467%;
}
.tb-megamenu .row-fluid .offset11 {
  margin-left: 95.74468085106382%;
  *margin-left: 95.6382978723404%;
}
.tb-megamenu .row-fluid .offset11:first-child {
  margin-left: 93.61702127659574%;
  *margin-left: 93.51063829787232%;
}
.tb-megamenu .row-fluid .offset10 {
  margin-left: 87.23404255319149%;
  *margin-left: 87.12765957446807%;
}
.tb-megamenu .row-fluid .offset10:first-child {
  margin-left: 85.1063829787234%;
  *margin-left: 84.99999999999999%;
}
.tb-megamenu .row-fluid .offset9 {
  margin-left: 78.72340425531914%;
  *margin-left: 78.61702127659572%;
}
.tb-megamenu .row-fluid .offset9:first-child {
  margin-left: 76.59574468085106%;
  *margin-left: 76.48936170212764%;
}
.tb-megamenu .row-fluid .offset8 {
  margin-left: 70.2127659574468%;
  *margin-left: 70.10638297872339%;
}
.tb-megamenu .row-fluid .offset8:first-child {
  margin-left: 68.08510638297872%;
  *margin-left: 67.9787234042553%;
}
.tb-megamenu .row-fluid .offset7 {
  margin-left: 61.70212765957446%;
  *margin-left: 61.59574468085106%;
}
.tb-megamenu .row-fluid .offset7:first-child {
  margin-left: 59.574468085106375%;
  *margin-left: 59.46808510638297%;
}
.tb-megamenu .row-fluid .offset6 {
  margin-left: 53.191489361702125%;
  *margin-left: 53.085106382978715%;
}
.tb-megamenu .row-fluid .offset6:first-child {
  margin-left: 51.063829787234035%;
  *margin-left: 50.95744680851063%;
}
.tb-megamenu .row-fluid .offset5 {
  margin-left: 44.68085106382979%;
  *margin-left: 44.57446808510638%;
}
.tb-megamenu .row-fluid .offset5:first-child {
  margin-left: 42.5531914893617%;
  *margin-left: 42.4468085106383%;
}
.tb-megamenu .row-fluid .offset4 {
  margin-left: 36.170212765957444%;
  *margin-left: 36.06382978723405%;
}
.tb-megamenu .row-fluid .offset4:first-child {
  margin-left: 34.04255319148936%;
  *margin-left: 33.93617021276596%;
}
.tb-megamenu .row-fluid .offset3 {
  margin-left: 27.659574468085104%;
  *margin-left: 27.5531914893617%;
}
.tb-megamenu .row-fluid .offset3:first-child {
  margin-left: 25.53191489361702%;
  *margin-left: 25.425531914893618%;
}
.tb-megamenu .row-fluid .offset2 {
  margin-left: 19.148936170212764%;
  *margin-left: 19.04255319148936%;
}
.tb-megamenu .row-fluid .offset2:first-child {
  margin-left: 17.02127659574468%;
  *margin-left: 16.914893617021278%;
}
.tb-megamenu .row-fluid .offset1 {
  margin-left: 10.638297872340425%;
  *margin-left: 10.53191489361702%;
}
.tb-megamenu .row-fluid .offset1:first-child {
  margin-left: 8.51063829787234%;
  *margin-left: 8.404255319148938%;
}
.tb-megamenu [class*="span"].hide,
.tb-megamenu .row-fluid [class*="span"].hide {
  display: none;
}
.tb-megamenu [class*="span"].pull-right,
.tb-megamenu .row-fluid [class*="span"].pull-right {
  float: right;
}
.tb-megamenu .btn {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #333333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e6e6e6;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border: 1px solid #cccccc;
  *border: 0;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *margin-left: .3em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.tb-megamenu .btn:hover,
.tb-megamenu .btn:focus,
.tb-megamenu .btn:active,
.tb-megamenu .btn.active,
.tb-megamenu .btn.disabled,
.tb-megamenu .btn[disabled] {
  color: #333333;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}
.tb-megamenu .btn:active,
.tb-megamenu .btn.active {
  background-color: #cccccc \9;
}
.tb-megamenu .btn:first-child {
  *margin-left: 0;
}
.tb-megamenu .btn:hover,
.tb-megamenu .btn:focus {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.tb-megamenu .btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.tb-megamenu .btn.active,
.tb-megamenu .btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.tb-megamenu .btn.disabled,
.tb-megamenu .btn[disabled] {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.tb-megamenu .btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.tb-megamenu .btn-large [class^="icon-"],
.tb-megamenu .btn-large [class*=" icon-"] {
  margin-top: 4px;
}
.tb-megamenu .btn-small {
  padding: 2px 10px;
  font-size: 11.9px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.tb-megamenu .btn-small [class^="icon-"],
.tb-megamenu .btn-small [class*=" icon-"] {
  margin-top: 0;
}
.tb-megamenu .btn-mini [class^="icon-"],
.tb-megamenu .btn-mini [class*=" icon-"] {
  margin-top: -1px;
}
.tb-megamenu .btn-mini {
  padding: 0 6px;
  font-size: 10.5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.tb-megamenu .btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tb-megamenu .btn-block + .btn-block {
  margin-top: 5px;
}
.tb-megamenu input[type="submit"].btn-block,
.tb-megamenu input[type="reset"].btn-block,
.tb-megamenu input[type="button"].btn-block {
  width: 100%;
}
.tb-megamenu .btn-primary.active,
.tb-megamenu .btn-warning.active,
.tb-megamenu .btn-danger.active,
.tb-megamenu .btn-success.active,
.tb-megamenu .btn-info.active,
.tb-megamenu .btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}
.tb-megamenu .btn-primary {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #006dcc;
  background-image: -moz-linear-gradient(top, #0088cc, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
  background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
  background-image: -o-linear-gradient(top, #0088cc, #0044cc);
  background-image: linear-gradient(to bottom, #0088cc, #0044cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #0044cc;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tb-megamenu .btn-primary:hover,
.tb-megamenu .btn-primary:focus,
.tb-megamenu .btn-primary:active,
.tb-megamenu .btn-primary.active,
.tb-megamenu .btn-primary.disabled,
.tb-megamenu .btn-primary[disabled] {
  color: #ffffff;
  background-color: #0044cc;
  *background-color: #003bb3;
}
.tb-megamenu .btn-primary:active,
.tb-megamenu .btn-primary.active {
  background-color: #003399 \9;
}
.tb-megamenu .btn-warning {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #f89406;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tb-megamenu .btn-warning:hover,
.tb-megamenu .btn-warning:focus,
.tb-megamenu .btn-warning:active,
.tb-megamenu .btn-warning.active,
.tb-megamenu .btn-warning.disabled,
.tb-megamenu .btn-warning[disabled] {
  color: #ffffff;
  background-color: #f89406;
  *background-color: #df8505;
}
.tb-megamenu .btn-warning:active,
.tb-megamenu .btn-warning.active {
  background-color: #c67605 \9;
}
.tb-megamenu .btn-danger {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #da4f49;
  background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffbd362f', GradientType=0);
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #bd362f;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tb-megamenu .btn-danger:hover,
.tb-megamenu .btn-danger:focus,
.tb-megamenu .btn-danger:active,
.tb-megamenu .btn-danger.active,
.tb-megamenu .btn-danger.disabled,
.tb-megamenu .btn-danger[disabled] {
  color: #ffffff;
  background-color: #bd362f;
  *background-color: #a9302a;
}
.tb-megamenu .btn-danger:active,
.tb-megamenu .btn-danger.active {
  background-color: #942a25 \9;
}
.tb-megamenu .btn-success {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bb75b;
  background-image: -moz-linear-gradient(top, #62c462, #51a351);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
  background-image: -webkit-linear-gradient(top, #62c462, #51a351);
  background-image: -o-linear-gradient(top, #62c462, #51a351);
  background-image: linear-gradient(to bottom, #62c462, #51a351);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff51a351', GradientType=0);
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #51a351;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tb-megamenu .btn-success:hover,
.tb-megamenu .btn-success:focus,
.tb-megamenu .btn-success:active,
.tb-megamenu .btn-success.active,
.tb-megamenu .btn-success.disabled,
.tb-megamenu .btn-success[disabled] {
  color: #ffffff;
  background-color: #51a351;
  *background-color: #499249;
}
.tb-megamenu .btn-success:active,
.tb-megamenu .btn-success.active {
  background-color: #408140 \9;
}
.tb-megamenu .btn-info {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #2f96b4;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tb-megamenu .btn-info:hover,
.tb-megamenu .btn-info:focus,
.tb-megamenu .btn-info:active,
.tb-megamenu .btn-info.active,
.tb-megamenu .btn-info.disabled,
.tb-megamenu .btn-info[disabled] {
  color: #ffffff;
  background-color: #2f96b4;
  *background-color: #2a85a0;
}
.tb-megamenu .btn-info:active,
.tb-megamenu .btn-info.active {
  background-color: #24748c \9;
}
.tb-megamenu .btn-inverse {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #363636;
  background-image: -moz-linear-gradient(top, #444444, #222222);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444444), to(#222222));
  background-image: -webkit-linear-gradient(top, #444444, #222222);
  background-image: -o-linear-gradient(top, #444444, #222222);
  background-image: linear-gradient(to bottom, #444444, #222222);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff222222', GradientType=0);
  border-color: #222222 #222222 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #222222;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tb-megamenu .btn-inverse:hover,
.tb-megamenu .btn-inverse:focus,
.tb-megamenu .btn-inverse:active,
.tb-megamenu .btn-inverse.active,
.tb-megamenu .btn-inverse.disabled,
.tb-megamenu .btn-inverse[disabled] {
  color: #ffffff;
  background-color: #222222;
  *background-color: #151515;
}
.tb-megamenu .btn-inverse:active,
.tb-megamenu .btn-inverse.active {
  background-color: #080808 \9;
}
.tb-megamenu button.btn,
.tb-megamenu input[type="submit"].btn {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
.tb-megamenu button.btn::-moz-focus-inner,
.tb-megamenu input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.tb-megamenu button.btn.btn-large,
.tb-megamenu input[type="submit"].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px;
}
.tb-megamenu button.btn.btn-small,
.tb-megamenu input[type="submit"].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
.tb-megamenu button.btn.btn-mini,
.tb-megamenu input[type="submit"].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px;
}
.tb-megamenu .btn-link,
.tb-megamenu .btn-link:active,
.tb-megamenu .btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.tb-megamenu .btn-link {
  border-color: transparent;
  cursor: pointer;
  color: #0088cc;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tb-megamenu .btn-link:hover,
.tb-megamenu .btn-link:focus {
  color: #005580;
  text-decoration: underline;
  background-color: transparent;
}
.tb-megamenu .btn-link[disabled]:hover,
.tb-megamenu .btn-link[disabled]:focus {
  color: #333333;
  text-decoration: none;
}
.tb-megamenu [class^="icon-"],
.tb-megamenu [class*=" icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  *margin-right: .3em;
  line-height: 14px;
  vertical-align: text-top;
  background-position: 14px 14px;
  background-repeat: no-repeat;
  margin-top: 1px;
}
/* White icons with optional class, or on hover/focus/active states of certain elements */
.tb-megamenu .icon-white,
.tb-megamenu .nav-pills > .active > a > [class^="icon-"],
.tb-megamenu .nav-pills > .active > a > [class*=" icon-"],
.tb-megamenu .nav-list > .active > a > [class^="icon-"],
.tb-megamenu .nav-list > .active > a > [class*=" icon-"],
.tb-megamenu .navbar-inverse .nav > .active > a > [class^="icon-"],
.tb-megamenu .navbar-inverse .nav > .active > a > [class*=" icon-"],
.tb-megamenu .dropdown-menu > li > a:hover > [class^="icon-"],
.tb-megamenu .dropdown-menu > li > a:focus > [class^="icon-"],
.tb-megamenu .dropdown-menu > li > a:hover > [class*=" icon-"],
.tb-megamenu .dropdown-menu > li > a:focus > [class*=" icon-"],
.tb-megamenu .dropdown-menu > .active > a > [class^="icon-"],
.tb-megamenu .dropdown-menu > .active > a > [class*=" icon-"],
.tb-megamenu .dropdown-submenu:hover > a > [class^="icon-"],
.tb-megamenu .dropdown-submenu:focus > a > [class^="icon-"],
.tb-megamenu .dropdown-submenu:hover > a > [class*=" icon-"],
.tb-megamenu .dropdown-submenu:focus > a > [class*=" icon-"] {
}
.tb-megamenu .icon-glass {
  background-position: 0      0;
}
.tb-megamenu .icon-music {
  background-position: -24px 0;
}
.tb-megamenu .icon-search {
  background-position: -48px 0;
}
.tb-megamenu .icon-envelope {
  background-position: -72px 0;
}
.tb-megamenu .icon-heart {
  background-position: -96px 0;
}
.tb-megamenu .icon-star {
  background-position: -120px 0;
}
.tb-megamenu .icon-star-empty {
  background-position: -144px 0;
}
.tb-megamenu .icon-user {
  background-position: -168px 0;
}
.tb-megamenu .icon-film {
  background-position: -192px 0;
}
.tb-megamenu .icon-th-large {
  background-position: -216px 0;
}
.tb-megamenu .icon-th {
  background-position: -240px 0;
}
.tb-megamenu .icon-th-list {
  background-position: -264px 0;
}
.tb-megamenu .icon-ok {
  background-position: -288px 0;
}
.tb-megamenu .icon-remove {
  background-position: -312px 0;
}
.tb-megamenu .icon-zoom-in {
  background-position: -336px 0;
}
.tb-megamenu .icon-zoom-out {
  background-position: -360px 0;
}
.tb-megamenu .icon-off {
  background-position: -384px 0;
}
.tb-megamenu .icon-signal {
  background-position: -408px 0;
}
.tb-megamenu .icon-cog {
  background-position: -432px 0;
}
.tb-megamenu .icon-trash {
  background-position: -456px 0;
}
.tb-megamenu .icon-home {
  background-position: 0 -24px;
}
.tb-megamenu .icon-file {
  background-position: -24px -24px;
}
.tb-megamenu .icon-time {
  background-position: -48px -24px;
}
.tb-megamenu .icon-road {
  background-position: -72px -24px;
}
.tb-megamenu .icon-download-alt {
  background-position: -96px -24px;
}
.tb-megamenu .icon-download {
  background-position: -120px -24px;
}
.tb-megamenu .icon-upload {
  background-position: -144px -24px;
}
.tb-megamenu .icon-inbox {
  background-position: -168px -24px;
}
.tb-megamenu .icon-play-circle {
  background-position: -192px -24px;
}
.tb-megamenu .icon-repeat {
  background-position: -216px -24px;
}
.tb-megamenu .icon-refresh {
  background-position: -240px -24px;
}
.tb-megamenu .icon-list-alt {
  background-position: -264px -24px;
}
.tb-megamenu .icon-lock {
  background-position: -287px -24px;
}
.tb-megamenu .icon-flag {
  background-position: -312px -24px;
}
.tb-megamenu .icon-headphones {
  background-position: -336px -24px;
}
.tb-megamenu .icon-volume-off {
  background-position: -360px -24px;
}
.tb-megamenu .icon-volume-down {
  background-position: -384px -24px;
}
.tb-megamenu .icon-volume-up {
  background-position: -408px -24px;
}
.tb-megamenu .icon-qrcode {
  background-position: -432px -24px;
}
.tb-megamenu .icon-barcode {
  background-position: -456px -24px;
}
.tb-megamenu .icon-tag {
  background-position: 0 -48px;
}
.tb-megamenu .icon-tags {
  background-position: -25px -48px;
}
.tb-megamenu .icon-book {
  background-position: -48px -48px;
}
.tb-megamenu .icon-bookmark {
  background-position: -72px -48px;
}
.tb-megamenu .icon-print {
  background-position: -96px -48px;
}
.tb-megamenu .icon-camera {
  background-position: -120px -48px;
}
.tb-megamenu .icon-font {
  background-position: -144px -48px;
}
.tb-megamenu .icon-bold {
  background-position: -167px -48px;
}
.tb-megamenu .icon-italic {
  background-position: -192px -48px;
}
.tb-megamenu .icon-text-height {
  background-position: -216px -48px;
}
.tb-megamenu .icon-text-width {
  background-position: -240px -48px;
}
.tb-megamenu .icon-align-left {
  background-position: -264px -48px;
}
.tb-megamenu .icon-align-center {
  background-position: -288px -48px;
}
.tb-megamenu .icon-align-right {
  background-position: -312px -48px;
}
.tb-megamenu .icon-align-justify {
  background-position: -336px -48px;
}
.tb-megamenu .icon-list {
  background-position: -360px -48px;
}
.tb-megamenu .icon-indent-left {
  background-position: -384px -48px;
}
.tb-megamenu .icon-indent-right {
  background-position: -408px -48px;
}
.tb-megamenu .icon-facetime-video {
  background-position: -432px -48px;
}
.tb-megamenu .icon-picture {
  background-position: -456px -48px;
}
.tb-megamenu .icon-pencil {
  background-position: 0 -72px;
}
.tb-megamenu .icon-map-marker {
  background-position: -24px -72px;
}
.tb-megamenu .icon-adjust {
  background-position: -48px -72px;
}
.tb-megamenu .icon-tint {
  background-position: -72px -72px;
}
.tb-megamenu .icon-edit {
  background-position: -96px -72px;
}
.tb-megamenu .icon-share {
  background-position: -120px -72px;
}
.tb-megamenu .icon-check {
  background-position: -144px -72px;
}
.tb-megamenu .icon-move {
  background-position: -168px -72px;
}
.tb-megamenu .icon-step-backward {
  background-position: -192px -72px;
}
.tb-megamenu .icon-fast-backward {
  background-position: -216px -72px;
}
.tb-megamenu .icon-backward {
  background-position: -240px -72px;
}
.tb-megamenu .icon-play {
  background-position: -264px -72px;
}
.tb-megamenu .icon-pause {
  background-position: -288px -72px;
}
.tb-megamenu .icon-stop {
  background-position: -312px -72px;
}
.tb-megamenu .icon-forward {
  background-position: -336px -72px;
}
.tb-megamenu .icon-fast-forward {
  background-position: -360px -72px;
}
.tb-megamenu .icon-step-forward {
  background-position: -384px -72px;
}
.tb-megamenu .icon-eject {
  background-position: -408px -72px;
}
.tb-megamenu .icon-chevron-left {
  background-position: -432px -72px;
}
.tb-megamenu .icon-chevron-right {
  background-position: -456px -72px;
}
.tb-megamenu .icon-plus-sign {
  background-position: 0 -96px;
}
.tb-megamenu .icon-minus-sign {
  background-position: -24px -96px;
}
.tb-megamenu .icon-remove-sign {
  background-position: -48px -96px;
}
.tb-megamenu .icon-ok-sign {
  background-position: -72px -96px;
}
.tb-megamenu .icon-question-sign {
  background-position: -96px -96px;
}
.tb-megamenu .icon-info-sign {
  background-position: -120px -96px;
}
.tb-megamenu .icon-screenshot {
  background-position: -144px -96px;
}
.tb-megamenu .icon-remove-circle {
  background-position: -168px -96px;
}
.tb-megamenu .icon-ok-circle {
  background-position: -192px -96px;
}
.tb-megamenu .icon-ban-circle {
  background-position: -216px -96px;
}
.tb-megamenu .icon-arrow-left {
  background-position: -240px -96px;
}
.tb-megamenu .icon-arrow-right {
  background-position: -264px -96px;
}
.tb-megamenu .icon-arrow-up {
  background-position: -289px -96px;
}
.tb-megamenu .icon-arrow-down {
  background-position: -312px -96px;
}
.tb-megamenu .icon-share-alt {
  background-position: -336px -96px;
}
.tb-megamenu .icon-resize-full {
  background-position: -360px -96px;
}
.tb-megamenu .icon-resize-small {
  background-position: -384px -96px;
}
.tb-megamenu .icon-plus {
  background-position: -408px -96px;
}
.tb-megamenu .icon-minus {
  background-position: -433px -96px;
}
.tb-megamenu .icon-asterisk {
  background-position: -456px -96px;
}
.tb-megamenu .icon-exclamation-sign {
  background-position: 0 -120px;
}
.tb-megamenu .icon-gift {
  background-position: -24px -120px;
}
.tb-megamenu .icon-leaf {
  background-position: -48px -120px;
}
.tb-megamenu .icon-fire {
  background-position: -72px -120px;
}
.tb-megamenu .icon-eye-open {
  background-position: -96px -120px;
}
.tb-megamenu .icon-eye-close {
  background-position: -120px -120px;
}
.tb-megamenu .icon-warning-sign {
  background-position: -144px -120px;
}
.tb-megamenu .icon-plane {
  background-position: -168px -120px;
}
.tb-megamenu .icon-calendar {
  background-position: -192px -120px;
}
.tb-megamenu .icon-random {
  background-position: -216px -120px;
  width: 16px;
}
.tb-megamenu .icon-comment {
  background-position: -240px -120px;
}
.tb-megamenu .icon-magnet {
  background-position: -264px -120px;
}
.tb-megamenu .icon-chevron-up {
  background-position: -288px -120px;
}
.tb-megamenu .icon-chevron-down {
  background-position: -313px -119px;
}
.tb-megamenu .icon-retweet {
  background-position: -336px -120px;
}
.tb-megamenu .icon-shopping-cart {
  background-position: -360px -120px;
}
.tb-megamenu .icon-folder-close {
  background-position: -384px -120px;
  width: 16px;
}
.tb-megamenu .icon-folder-open {
  background-position: -408px -120px;
  width: 16px;
}
.tb-megamenu .icon-resize-vertical {
  background-position: -432px -119px;
}
.tb-megamenu .icon-resize-horizontal {
  background-position: -456px -118px;
}
.tb-megamenu .icon-hdd {
  background-position: 0 -144px;
}
.tb-megamenu .icon-bullhorn {
  background-position: -24px -144px;
}
.tb-megamenu .icon-bell {
  background-position: -48px -144px;
}
.tb-megamenu .icon-certificate {
  background-position: -72px -144px;
}
.tb-megamenu .icon-thumbs-up {
  background-position: -96px -144px;
}
.tb-megamenu .icon-thumbs-down {
  background-position: -120px -144px;
}
.tb-megamenu .icon-hand-right {
  background-position: -144px -144px;
}
.tb-megamenu .icon-hand-left {
  background-position: -168px -144px;
}
.tb-megamenu .icon-hand-up {
  background-position: -192px -144px;
}
.tb-megamenu .icon-hand-down {
  background-position: -216px -144px;
}
.tb-megamenu .icon-circle-arrow-right {
  background-position: -240px -144px;
}
.tb-megamenu .icon-circle-arrow-left {
  background-position: -264px -144px;
}
.tb-megamenu .icon-circle-arrow-up {
  background-position: -288px -144px;
}
.tb-megamenu .icon-circle-arrow-down {
  background-position: -312px -144px;
}
.tb-megamenu .icon-globe {
  background-position: -336px -144px;
}
.tb-megamenu .icon-wrench {
  background-position: -360px -144px;
}
.tb-megamenu .icon-tasks {
  background-position: -384px -144px;
}
.tb-megamenu .icon-filter {
  background-position: -408px -144px;
}
.tb-megamenu .icon-briefcase {
  background-position: -432px -144px;
}
.tb-megamenu .icon-fullscreen {
  background-position: -456px -144px;
}
.tb-megamenu .btn-group {
  position: relative;
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap;
  *margin-left: .3em;
}
.tb-megamenu .btn-group:first-child {
  *margin-left: 0;
}
.tb-megamenu .btn-group + .btn-group {
  margin-left: 5px;
}
.tb-megamenu .btn-toolbar {
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.tb-megamenu .btn-toolbar > .btn + .btn,
.tb-megamenu .btn-toolbar > .btn-group + .btn,
.tb-megamenu .btn-toolbar > .btn + .btn-group {
  margin-left: 5px;
}
.tb-megamenu .btn-group > .btn {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tb-megamenu .btn-group > .btn + .btn {
  margin-left: -1px;
}
.tb-megamenu .btn-group > .btn,
.tb-megamenu .btn-group > .dropdown-menu,
.tb-megamenu .btn-group > .popover {
  font-size: 14px;
}
.tb-megamenu .btn-group > .btn-mini {
  font-size: 10.5px;
}
.tb-megamenu .btn-group > .btn-small {
  font-size: 11.9px;
}
.tb-megamenu .btn-group > .btn-large {
  font-size: 17.5px;
}
.tb-megamenu .btn-group > .btn:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.tb-megamenu .btn-group > .btn:last-child,
.tb-megamenu .btn-group > .dropdown-toggle {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.tb-megamenu .btn-group > .btn.large:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
}
.tb-megamenu .btn-group > .btn.large:last-child,
.tb-megamenu .btn-group > .large.dropdown-toggle {
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
}
.tb-megamenu .btn-group > .btn:hover,
.tb-megamenu .btn-group > .btn:focus,
.tb-megamenu .btn-group > .btn:active,
.tb-megamenu .btn-group > .btn.active {
  z-index: 2;
}
.tb-megamenu .btn-group .dropdown-toggle:active,
.tb-megamenu .btn-group.open .dropdown-toggle {
  outline: 0;
}
.tb-megamenu .btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  -webkit-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  *padding-top: 5px;
  *padding-bottom: 5px;
}
.tb-megamenu .btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
  *padding-top: 2px;
  *padding-bottom: 2px;
}
.tb-megamenu .btn-group > .btn-small + .dropdown-toggle {
  *padding-top: 5px;
  *padding-bottom: 4px;
}
.tb-megamenu .btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
  *padding-top: 7px;
  *padding-bottom: 7px;
}
.tb-megamenu .btn-group.open .dropdown-toggle {
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.tb-megamenu .btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}
.tb-megamenu .btn-group.open .btn-primary.dropdown-toggle {
  background-color: #0044cc;
}
.tb-megamenu .btn-group.open .btn-warning.dropdown-toggle {
  background-color: #f89406;
}
.tb-megamenu .btn-group.open .btn-danger.dropdown-toggle {
  background-color: #bd362f;
}
.tb-megamenu .btn-group.open .btn-success.dropdown-toggle {
  background-color: #51a351;
}
.tb-megamenu .btn-group.open .btn-info.dropdown-toggle {
  background-color: #2f96b4;
}
.tb-megamenu .btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #222222;
}
.tb-megamenu .btn .caret {
  margin-top: 8px;
  margin-left: 0;
}
.tb-megamenu .btn-large .caret {
  margin-top: 6px;
}
.tb-megamenu .btn-large .caret {
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}
.tb-megamenu .btn-mini .caret,
.tb-megamenu .btn-small .caret {
  margin-top: 8px;
}
.tb-megamenu .dropup .btn-large .caret {
  border-bottom-width: 5px;
}
.tb-megamenu .btn-primary .caret,
.tb-megamenu .btn-warning .caret,
.tb-megamenu .btn-danger .caret,
.tb-megamenu .btn-info .caret,
.tb-megamenu .btn-success .caret,
.tb-megamenu .btn-inverse .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.tb-megamenu .btn-group-vertical {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
}
.tb-megamenu .btn-group-vertical > .btn {
  display: block;
  float: none;
  max-width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tb-megamenu .btn-group-vertical > .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.tb-megamenu .btn-group-vertical > .btn:first-child {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.tb-megamenu .btn-group-vertical > .btn:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.tb-megamenu .btn-group-vertical > .btn-large:first-child {
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.tb-megamenu .btn-group-vertical > .btn-large:last-child {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.tb-megamenu .nav {
  margin-left: 0;
  margin-bottom: 20px;
  list-style: none;
}
.tb-megamenu .nav > li > a {
  display: block;
}
.tb-megamenu .nav > li > a:hover,
.tb-megamenu .nav > li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}
.tb-megamenu .nav > li > a > img {
  max-width: none;
}
.tb-megamenu .nav > .pull-right {
  float: right;
}
.tb-megamenu .nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
  color: #999999;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}
.tb-megamenu .nav li + .nav-header {
  margin-top: 9px;
}
.tb-megamenu .nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.tb-megamenu .nav-list > li > a,
.tb-megamenu .nav-list .nav-header {
  margin-left: -15px;
  margin-right: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.tb-megamenu .nav-list > li > a {
  padding: 3px 15px;
}
.tb-megamenu .nav-list > .active > a,
.tb-megamenu .nav-list > .active > a:hover,
.tb-megamenu .nav-list > .active > a:focus {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-color: #0088cc;
}
.tb-megamenu .nav-list [class^="icon-"],
.tb-megamenu .nav-list [class*=" icon-"] {
  margin-right: 2px;
}
.tb-megamenu .nav-list .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}
.tb-megamenu .nav-tabs,
.tb-megamenu .nav-pills {
  *zoom: 1;
}
.tb-megamenu .nav-tabs:before,
.tb-megamenu .nav-pills:before,
.tb-megamenu .nav-tabs:after,
.tb-megamenu .nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .nav-tabs:after,
.tb-megamenu .nav-pills:after {
  clear: both;
}
.tb-megamenu .nav-tabs > li,
.tb-megamenu .nav-pills > li {
  float: left;
}
.tb-megamenu .nav-tabs > li > a,
.tb-megamenu .nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}
.tb-megamenu .nav-tabs {
  border-bottom: 1px solid #ddd;
}
.tb-megamenu .nav-tabs > li {
  margin-bottom: -1px;
}
.tb-megamenu .nav-tabs > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 20px;
  border: 1px solid transparent;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.tb-megamenu .nav-tabs > li > a:hover,
.tb-megamenu .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #dddddd;
}
.tb-megamenu .nav-tabs > .active > a,
.tb-megamenu .nav-tabs > .active > a:hover,
.tb-megamenu .nav-tabs > .active > a:focus {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}
.tb-megamenu .nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.tb-megamenu .nav-pills > .active > a,
.tb-megamenu .nav-pills > .active > a:hover,
.tb-megamenu .nav-pills > .active > a:focus {
  color: #ffffff;
  background-color: #0088cc;
}
.tb-megamenu .nav-stacked > li {
  float: none;
}
.tb-megamenu .nav-stacked > li > a {
  margin-right: 0;
}
.tb-megamenu .nav-tabs.nav-stacked {
  border-bottom: 0;
}
.tb-megamenu .nav-tabs.nav-stacked > li > a {
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tb-megamenu .nav-tabs.nav-stacked > li:first-child > a {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.tb-megamenu .nav-tabs.nav-stacked > li:last-child > a {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.tb-megamenu .nav-tabs.nav-stacked > li > a:hover,
.tb-megamenu .nav-tabs.nav-stacked > li > a:focus {
  border-color: #ddd;
  z-index: 2;
}
.tb-megamenu .nav-pills.nav-stacked > li > a {
  margin-bottom: 3px;
}
.tb-megamenu .nav-pills.nav-stacked > li:last-child > a {
  margin-bottom: 1px;
}
.tb-megamenu .nav-tabs .dropdown-menu {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.tb-megamenu .nav-pills .dropdown-menu {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.tb-megamenu .nav .dropdown-toggle .caret {
  border-top-color: #0088cc;
  border-bottom-color: #0088cc;
  margin-top: 6px;
}
.tb-megamenu .nav .dropdown-toggle:hover .caret,
.tb-megamenu .nav .dropdown-toggle:focus .caret {
  border-top-color: #005580;
  border-bottom-color: #005580;
}
/* move down carets for tabs */
.tb-megamenu .nav-tabs .dropdown-toggle .caret {
  margin-top: 8px;
}
.tb-megamenu .nav .active .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.tb-megamenu .nav-tabs .active .dropdown-toggle .caret {
  border-top-color: #555555;
  border-bottom-color: #555555;
}
.tb-megamenu .nav > .dropdown.active > a:hover,
.tb-megamenu .nav > .dropdown.active > a:focus {
  cursor: pointer;
}
.tb-megamenu .nav-tabs .open .dropdown-toggle,
.tb-megamenu .nav-pills .open .dropdown-toggle,
.tb-megamenu .nav > li.dropdown.open.active > a:hover,
.tb-megamenu .nav > li.dropdown.open.active > a:focus {
  color: #ffffff;
  background-color: #999999;
  border-color: #999999;
}
.tb-megamenu .nav li.dropdown.open .caret,
.tb-megamenu .nav li.dropdown.open.active .caret,
.tb-megamenu .nav li.dropdown.open a:hover .caret,
.tb-megamenu .nav li.dropdown.open a:focus .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}
.tb-megamenu .tabs-stacked .open > a:hover,
.tb-megamenu .tabs-stacked .open > a:focus {
  border-color: #999999;
}
.tb-megamenu .tabbable {
  *zoom: 1;
}
.tb-megamenu .tabbable:before,
.tb-megamenu .tabbable:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .tabbable:after {
  clear: both;
}
.tb-megamenu .tab-content {
  overflow: auto;
}
.tb-megamenu .tabs-below > .nav-tabs,
.tb-megamenu .tabs-right > .nav-tabs,
.tb-megamenu .tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tb-megamenu .tab-content > .tab-pane,
.tb-megamenu .pill-content > .pill-pane {
  display: none;
}
.tb-megamenu .tab-content > .active,
.tb-megamenu .pill-content > .active {
  display: block;
}
.tb-megamenu .tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}
.tb-megamenu .tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tb-megamenu .tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.tb-megamenu .tabs-below > .nav-tabs > li > a:hover,
.tb-megamenu .tabs-below > .nav-tabs > li > a:focus {
  border-bottom-color: transparent;
  border-top-color: #ddd;
}
.tb-megamenu .tabs-below > .nav-tabs > .active > a,
.tb-megamenu .tabs-below > .nav-tabs > .active > a:hover,
.tb-megamenu .tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #ddd #ddd #ddd;
}
.tb-megamenu .tabs-left > .nav-tabs > li,
.tb-megamenu .tabs-right > .nav-tabs > li {
  float: none;
}
.tb-megamenu .tabs-left > .nav-tabs > li > a,
.tb-megamenu .tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tb-megamenu .tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}
.tb-megamenu .tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.tb-megamenu .tabs-left > .nav-tabs > li > a:hover,
.tb-megamenu .tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}
.tb-megamenu .tabs-left > .nav-tabs .active > a,
.tb-megamenu .tabs-left > .nav-tabs .active > a:hover,
.tb-megamenu .tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}
.tb-megamenu .tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}
.tb-megamenu .tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.tb-megamenu .tabs-right > .nav-tabs > li > a:hover,
.tb-megamenu .tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}
.tb-megamenu .tabs-right > .nav-tabs .active > a,
.tb-megamenu .tabs-right > .nav-tabs .active > a:hover,
.tb-megamenu .tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}
.tb-megamenu .nav > .disabled > a {
  color: #999999;
}
.tb-megamenu .nav > .disabled > a:hover,
.tb-megamenu .nav > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.tb-megamenu .navbar {
  overflow: visible;
  margin-bottom: 20px;
  *position: relative;
  *z-index: 2;
}
.tb-megamenu .navbar-inner {
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fafafa;
  background-image: -moz-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#f2f2f2));
  background-image: -webkit-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: -o-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff2f2f2', GradientType=0);
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  *zoom: 1;
}
.tb-megamenu .navbar-inner:before,
.tb-megamenu .navbar-inner:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .navbar-inner:after {
  clear: both;
}
.tb-megamenu .navbar .container {
  width: auto;
}
.tb-megamenu .nav-collapse.collapse {
  height: auto;
  overflow: visible;
}
.tb-megamenu .navbar .brand {
  float: left;
  display: block;
  padding: 10px 20px 10px;
  margin-left: -20px;
  font-size: 20px;
  font-weight: 200;
  color: #777777;
  text-shadow: 0 1px 0 #ffffff;
}
.tb-megamenu .navbar .brand:hover,
.tb-megamenu .navbar .brand:focus {
  text-decoration: none;
}
.tb-megamenu .navbar-text {
  margin-bottom: 0;
  line-height: 40px;
  color: #777777;
}
.tb-megamenu .navbar-link {
  color: #777777;
}
.tb-megamenu .navbar-link:hover,
.tb-megamenu .navbar-link:focus {
  color: #333333;
}
.tb-megamenu .navbar .divider-vertical {
  height: 40px;
  margin: 0 9px;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #ffffff;
}
.tb-megamenu .navbar .btn,
.tb-megamenu .navbar .btn-group {
  margin-top: 5px;
}
.tb-megamenu .navbar .btn-group .btn,
.tb-megamenu .navbar .input-prepend .btn,
.tb-megamenu .navbar .input-append .btn,
.tb-megamenu .navbar .input-prepend .btn-group,
.tb-megamenu .navbar .input-append .btn-group {
  margin-top: 0;
}
.tb-megamenu .navbar-form {
  margin-bottom: 0;
  *zoom: 1;
}
.tb-megamenu .navbar-form:before,
.tb-megamenu .navbar-form:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .navbar-form:after {
  clear: both;
}
.tb-megamenu .navbar-form input,
.tb-megamenu .navbar-form select,
.tb-megamenu .navbar-form .radio,
.tb-megamenu .navbar-form .checkbox {
  margin-top: 5px;
}
.tb-megamenu .navbar-form input,
.tb-megamenu .navbar-form select,
.tb-megamenu .navbar-form .btn {
  display: inline-block;
  margin-bottom: 0;
}
.tb-megamenu .navbar-form input[type="image"],
.tb-megamenu .navbar-form input[type="checkbox"],
.tb-megamenu .navbar-form input[type="radio"] {
  margin-top: 3px;
}
.tb-megamenu .navbar-form .input-append,
.tb-megamenu .navbar-form .input-prepend {
  margin-top: 5px;
  white-space: nowrap;
}
.tb-megamenu .navbar-form .input-append input,
.tb-megamenu .navbar-form .input-prepend input {
  margin-top: 0;
}
.tb-megamenu .navbar-search {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-bottom: 0;
}
.tb-megamenu .navbar-search .search-query {
  margin-bottom: 0;
  padding: 4px 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.tb-megamenu .navbar-static-top {
  position: static;
  margin-bottom: 0;
}
.tb-megamenu .navbar-static-top .navbar-inner {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tb-megamenu .navbar-fixed-top,
.tb-megamenu .navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-bottom: 0;
}
.tb-megamenu .navbar-fixed-top .navbar-inner,
.tb-megamenu .navbar-static-top .navbar-inner {
  border-width: 0 0 1px;
}
.tb-megamenu .navbar-fixed-bottom .navbar-inner {
  border-width: 1px 0 0;
}
.tb-megamenu .navbar-fixed-top .navbar-inner,
.tb-megamenu .navbar-fixed-bottom .navbar-inner {
  padding-left: 0;
  padding-right: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tb-megamenu .navbar-static-top .container,
.tb-megamenu .navbar-fixed-top .container,
.tb-megamenu .navbar-fixed-bottom .container {
  width: 940px;
}
.tb-megamenu .navbar-fixed-top {
  top: 0;
}
.tb-megamenu .navbar-fixed-top .navbar-inner,
.tb-megamenu .navbar-static-top .navbar-inner {
  -webkit-box-shadow: 0 1px 10px rgba(0,0,0,.1);
  -moz-box-shadow: 0 1px 10px rgba(0,0,0,.1);
  box-shadow: 0 1px 10px rgba(0,0,0,.1);
}
.tb-megamenu .navbar-fixed-bottom {
  bottom: 0;
}
.tb-megamenu .navbar-fixed-bottom .navbar-inner {
  -webkit-box-shadow: 0 -1px 10px rgba(0,0,0,.1);
  -moz-box-shadow: 0 -1px 10px rgba(0,0,0,.1);
  box-shadow: 0 -1px 10px rgba(0,0,0,.1);
}
.tb-megamenu .navbar .nav {
  position: relative;
  left: 0;
  display: block;
  float: left;
  margin: 0 10px 0 0;
}
.tb-megamenu .navbar .nav.pull-right {
  float: right;
  margin-right: 0;
}
.tb-megamenu .navbar .nav > li {
  float: left;
}
.tb-megamenu .navbar .nav > li > a {
  float: none;
  padding: 10px 15px 10px;
  color: #777777;
  text-decoration: none;
  text-shadow: 0 1px 0 #ffffff;
}
.tb-megamenu .navbar .nav .dropdown-toggle .caret {
  margin-top: 8px;
}
.tb-megamenu .navbar .nav > li > a:focus,
.tb-megamenu .navbar .nav > li > a:hover {
  background-color: transparent;
  color: #333333;
  text-decoration: none;
}
.tb-megamenu .navbar .nav > .active > a,
.tb-megamenu .navbar .nav > .active > a:hover,
.tb-megamenu .navbar .nav > .active > a:focus {
  color: #555555;
  text-decoration: none;
  background-color: #e5e5e5;
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
}
.tb-megamenu .navbar .btn-navbar {
  display: none;
  float: right;
  padding: 7px 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ededed;
  background-image: -moz-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#e5e5e5));
  background-image: -webkit-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: -o-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: linear-gradient(to bottom, #f2f2f2, #e5e5e5);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffe5e5e5', GradientType=0);
  border-color: #e5e5e5 #e5e5e5 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e5e5e5;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
}
.tb-megamenu .navbar .btn-navbar:hover,
.tb-megamenu .navbar .btn-navbar:focus,
.tb-megamenu .navbar .btn-navbar:active,
.tb-megamenu .navbar .btn-navbar.active,
.tb-megamenu .navbar .btn-navbar.disabled,
.tb-megamenu .navbar .btn-navbar[disabled] {
  color: #ffffff;
  background-color: #e5e5e5;
  *background-color: #d9d9d9;
}
.tb-megamenu .navbar .btn-navbar:active,
.tb-megamenu .navbar .btn-navbar.active {
  background-color: #cccccc \9;
}
.tb-megamenu .navbar .btn-navbar .icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  background-color: #f5f5f5;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.tb-megamenu .btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}
.tb-megamenu .navbar .nav > li > .dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 9px;
}
.tb-megamenu .navbar .nav > li > .dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  position: absolute;
  top: -6px;
  left: 10px;
}
.tb-megamenu .navbar-fixed-bottom .nav > li > .dropdown-menu:before {
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  bottom: -7px;
  top: auto;
}
.tb-megamenu .navbar-fixed-bottom .nav > li > .dropdown-menu:after {
  border-top: 6px solid #ffffff;
  border-bottom: 0;
  bottom: -6px;
  top: auto;
}
.tb-megamenu .navbar .nav li.dropdown > a:hover .caret,
.tb-megamenu .navbar .nav li.dropdown > a:focus .caret {
  border-top-color: #333333;
  border-bottom-color: #333333;
}
.tb-megamenu .navbar .nav li.dropdown.open > .dropdown-toggle,
.tb-megamenu .navbar .nav li.dropdown.active > .dropdown-toggle,
.tb-megamenu .navbar .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #e5e5e5;
  color: #555555;
}
.tb-megamenu .navbar .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #777777;
  border-bottom-color: #777777;
}
.tb-megamenu .navbar .nav li.dropdown.open > .dropdown-toggle .caret,
.tb-megamenu .navbar .nav li.dropdown.active > .dropdown-toggle .caret,
.tb-megamenu .navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #555555;
  border-bottom-color: #555555;
}
.tb-megamenu .navbar .pull-right > li > .dropdown-menu,
.tb-megamenu .navbar .nav > li > .dropdown-menu.pull-right {
  left: auto;
  right: 0;
}
.tb-megamenu .navbar .pull-right > li > .dropdown-menu:before,
.tb-megamenu .navbar .nav > li > .dropdown-menu.pull-right:before {
  left: auto;
  right: 12px;
}
.tb-megamenu .navbar .pull-right > li > .dropdown-menu:after,
.tb-megamenu .navbar .nav > li > .dropdown-menu.pull-right:after {
  left: auto;
  right: 13px;
}
.tb-megamenu .navbar .pull-right > li > .dropdown-menu .dropdown-menu,
.tb-megamenu .navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: -1px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.tb-megamenu .navbar-inverse .navbar-inner {
  background-color: #1b1b1b;
  background-image: -moz-linear-gradient(top, #222222, #111111);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#222222), to(#111111));
  background-image: -webkit-linear-gradient(top, #222222, #111111);
  background-image: -o-linear-gradient(top, #222222, #111111);
  background-image: linear-gradient(to bottom, #222222, #111111);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff222222', endColorstr='#ff111111', GradientType=0);
  border-color: #252525;
}
.tb-megamenu .navbar-inverse .brand,
.tb-megamenu .navbar-inverse .nav > li > a {
  color: #999999;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.tb-megamenu .navbar-inverse .brand:hover,
.tb-megamenu .navbar-inverse .nav > li > a:hover,
.tb-megamenu .navbar-inverse .brand:focus,
.tb-megamenu .navbar-inverse .nav > li > a:focus {
  color: #ffffff;
}
.tb-megamenu .navbar-inverse .brand {
  color: #999999;
}
.tb-megamenu .navbar-inverse .navbar-text {
  color: #999999;
}
.tb-megamenu .navbar-inverse .nav > li > a:focus,
.tb-megamenu .navbar-inverse .nav > li > a:hover {
  background-color: transparent;
  color: #ffffff;
}
.tb-megamenu .navbar-inverse .nav .active > a,
.tb-megamenu .navbar-inverse .nav .active > a:hover,
.tb-megamenu .navbar-inverse .nav .active > a:focus {
  color: #ffffff;
  background-color: #111111;
}
.tb-megamenu .navbar-inverse .navbar-link {
  color: #999999;
}
.tb-megamenu .navbar-inverse .navbar-link:hover,
.tb-megamenu .navbar-inverse .navbar-link:focus {
  color: #ffffff;
}
.tb-megamenu .navbar-inverse .divider-vertical {
  border-left-color: #111111;
  border-right-color: #222222;
}
.tb-megamenu .navbar-inverse .nav li.dropdown.open > .dropdown-toggle,
.tb-megamenu .navbar-inverse .nav li.dropdown.active > .dropdown-toggle,
.tb-megamenu .navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #111111;
  color: #ffffff;
}
.tb-megamenu .navbar-inverse .nav li.dropdown > a:hover .caret,
.tb-megamenu .navbar-inverse .nav li.dropdown > a:focus .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.tb-megamenu .navbar-inverse .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #999999;
  border-bottom-color: #999999;
}
.tb-megamenu .navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret,
.tb-megamenu .navbar-inverse .nav li.dropdown.active > .dropdown-toggle .caret,
.tb-megamenu .navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.tb-megamenu .navbar-inverse .navbar-search .search-query {
  color: #ffffff;
  background-color: #515151;
  border-color: #111111;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
.tb-megamenu .navbar-inverse .navbar-search .search-query:-moz-placeholder {
  color: #cccccc;
}
.tb-megamenu .navbar-inverse .navbar-search .search-query:-ms-input-placeholder {
  color: #cccccc;
}
.tb-megamenu .navbar-inverse .navbar-search .search-query::-webkit-input-placeholder {
  color: #cccccc;
}
.tb-megamenu .navbar-inverse .navbar-search .search-query:focus,
.tb-megamenu .navbar-inverse .navbar-search .search-query.focused {
  padding: 5px 15px;
  color: #333333;
  text-shadow: 0 1px 0 #ffffff;
  background-color: #ffffff;
  border: 0;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  outline: 0;
}
.tb-megamenu .navbar-inverse .btn-navbar {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e0e0e;
  background-image: -moz-linear-gradient(top, #151515, #040404);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#151515), to(#040404));
  background-image: -webkit-linear-gradient(top, #151515, #040404);
  background-image: -o-linear-gradient(top, #151515, #040404);
  background-image: linear-gradient(to bottom, #151515, #040404);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff151515', endColorstr='#ff040404', GradientType=0);
  border-color: #040404 #040404 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #040404;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tb-megamenu .navbar-inverse .btn-navbar:hover,
.tb-megamenu .navbar-inverse .btn-navbar:focus,
.tb-megamenu .navbar-inverse .btn-navbar:active,
.tb-megamenu .navbar-inverse .btn-navbar.active,
.tb-megamenu .navbar-inverse .btn-navbar.disabled,
.tb-megamenu .navbar-inverse .btn-navbar[disabled] {
  color: #ffffff;
  background-color: #040404;
  *background-color: #000000;
}
.tb-megamenu .navbar-inverse .btn-navbar:active,
.tb-megamenu .navbar-inverse .btn-navbar.active {
  background-color: #000000 \9;
}
.tb-megamenu .dropup,
.tb-megamenu .dropdown {
  position: relative;
}
.tb-megamenu .dropdown-toggle {
  *margin-bottom: -3px;
}
.tb-megamenu .dropdown-toggle:active,
.tb-megamenu .open .dropdown-toggle {
  outline: 0;
}
.tb-megamenu .caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.tb-megamenu .dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.tb-megamenu .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.tb-megamenu .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.tb-megamenu .dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}
.tb-megamenu .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  color: #333333;
  white-space: nowrap;
}
.tb-megamenu .dropdown-menu > li > a:hover,
.tb-megamenu .dropdown-menu > li > a:focus,
.tb-megamenu .dropdown-submenu:hover > a,
.tb-megamenu .dropdown-submenu:focus > a {
  text-decoration: none;
  color: #ffffff;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}
.tb-megamenu .dropdown-menu > .active > a,
.tb-megamenu .dropdown-menu > .active > a:hover,
.tb-megamenu .dropdown-menu > .active > a:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}
.tb-megamenu .dropdown-menu > .disabled > a,
.tb-megamenu .dropdown-menu > .disabled > a:hover,
.tb-megamenu .dropdown-menu > .disabled > a:focus {
  color: #999999;
}
.tb-megamenu .dropdown-menu > .disabled > a:hover,
.tb-megamenu .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: default;
}
.tb-megamenu .open {
  *z-index: 1000;
}
.tb-megamenu .open > .dropdown-menu {
  display: block;
}
.tb-megamenu .dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}
.tb-megamenu .pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.tb-megamenu .dropup .caret,
.tb-megamenu .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000000;
  content: "";
}
.tb-megamenu .dropup .dropdown-menu,
.tb-megamenu .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.tb-megamenu .dropdown-submenu {
  position: relative;
}
.tb-megamenu .dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.tb-megamenu .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.tb-megamenu .dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  -webkit-border-radius: 5px 5px 5px 0;
  -moz-border-radius: 5px 5px 5px 0;
  border-radius: 5px 5px 5px 0;
}
.tb-megamenu .dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.tb-megamenu .dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.tb-megamenu .dropdown-submenu.pull-left {
  float: none;
}
.tb-megamenu .dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.tb-megamenu .dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.tb-megamenu .typeahead {
  z-index: 1051;
  margin-top: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.tb-megamenu .accordion {
  margin-bottom: 20px;
}
.tb-megamenu .accordion-group {
  margin-bottom: 2px;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.tb-megamenu .accordion-heading {
  border-bottom: 0;
}
.tb-megamenu .accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}
.tb-megamenu .accordion-toggle {
  cursor: pointer;
}
.tb-megamenu .accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}
@-ms-viewport {
  width: device-width;
}
.tb-megamenu .hidden {
  display: none;
  visibility: hidden;
}
.tb-megamenu .visible-phone {
  display: none !important;
}
.tb-megamenu .visible-tablet {
  display: none !important;
}
.tb-megamenu .hidden-desktop {
  display: none !important;
}
.tb-megamenu .visible-desktop {
  display: inherit !important;
}
@media (min-width: 768px) and (max-width: 979px) {
  .tb-megamenu .hidden-desktop {
    display: inherit !important;
  }
  .tb-megamenu .visible-desktop {
    display: none !important ;
  }
  .tb-megamenu .visible-tablet {
    display: inherit !important;
  }
  .tb-megamenu .hidden-tablet {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .tb-megamenu .hidden-desktop {
    display: inherit !important;
  }
  .tb-megamenu .visible-desktop {
    display: none !important;
  }
  .tb-megamenu .visible-phone {
    display: inherit !important;
  }
  .tb-megamenu .hidden-phone {
    display: none !important;
  }
}
.tb-megamenu .visible-print {
  display: none !important;
}
@media print {
  .tb-megamenu .visible-print {
    display: inherit !important;
  }
  .tb-megamenu .hidden-print {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .tb-megamenu body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tb-megamenu .navbar-fixed-top,
  .tb-megamenu .navbar-fixed-bottom,
  .tb-megamenu .navbar-static-top {
    margin-left: -20px;
    margin-right: -20px;
  }
  .tb-megamenu .container-fluid {
    padding: 0;
  }
  .tb-megamenu .dl-horizontal dt {
    float: none;
    clear: none;
    width: auto;
    text-align: left;
  }
  .tb-megamenu .dl-horizontal dd {
    margin-left: 0;
  }
  .tb-megamenu .container {
    width: auto;
  }
  .tb-megamenu .row-fluid {
    width: 100%;
  }
  .tb-megamenu .row,
  .tb-megamenu .thumbnails {
    margin-left: 0;
  }
  .tb-megamenu .thumbnails > li {
    float: none;
    margin-left: 0;
  }
  .tb-megamenu [class*="span"],
  .tb-megamenu .uneditable-input[class*="span"],
  .tb-megamenu .row-fluid [class*="span"] {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .tb-megamenu .span12,
  .tb-megamenu .row-fluid .span12 {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .tb-megamenu .row-fluid [class*="offset"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .input-large,
  .tb-megamenu .input-xlarge,
  .tb-megamenu .input-xxlarge,
  .tb-megamenu input[class*="span"],
  .tb-megamenu select[class*="span"],
  .tb-megamenu textarea[class*="span"],
  .tb-megamenu .uneditable-input {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .tb-megamenu .input-prepend input,
  .tb-megamenu .input-append input,
  .tb-megamenu .input-prepend input[class*="span"],
  .tb-megamenu .input-append input[class*="span"] {
    display: inline-block;
    width: auto;
  }
  .tb-megamenu .controls-row [class*="span"] + [class*="span"] {
    margin-left: 0;
  }
  .tb-megamenu .modal {
    position: fixed;
    top: 20px;
    left: 20px;
    right: 20px;
    width: auto;
    margin: 0;
  }
  .tb-megamenu .modal.fade {
    top: -100px;
  }
  .tb-megamenu .modal.fade.in {
    top: 20px;
  }
}
@media (max-width: 480px) {
  .tb-megamenu .nav-collapse {
    -webkit-transform: translate3d(0, 0, 0);
  }
  .tb-megamenu .page-header h1 small {
    display: block;
    line-height: 20px;
  }
  .tb-megamenu input[type="checkbox"],
  .tb-megamenu input[type="radio"] {
    border: 1px solid #ccc;
  }
  .tb-megamenu .form-horizontal .control-label {
    float: none;
    width: auto;
    padding-top: 0;
    text-align: left;
  }
  .tb-megamenu .form-horizontal .controls {
    margin-left: 0;
  }
  .tb-megamenu .form-horizontal .control-list {
    padding-top: 0;
  }
  .tb-megamenu .form-horizontal .form-actions {
    padding-left: 10px;
    padding-right: 10px;
  }
  .tb-megamenu .media .pull-left,
  .tb-megamenu .media .pull-right {
    float: none;
    display: block;
    margin-bottom: 10px;
  }
  .tb-megamenu .media-object {
    margin-right: 0;
    margin-left: 0;
  }
  .tb-megamenu .modal {
    top: 10px;
    left: 10px;
    right: 10px;
  }
  .tb-megamenu .modal-header .close {
    padding: 10px;
    margin: -10px;
  }
  .tb-megamenu .carousel-caption {
    position: static;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .tb-megamenu .row {
    margin-left: -20px;
    *zoom: 1;
  }
  .tb-megamenu .row:before,
  .tb-megamenu .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .tb-megamenu .row:after {
    clear: both;
  }
  .tb-megamenu [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 20px;
  }
  .tb-megamenu .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .tb-megamenu .row-fluid:before,
  .tb-megamenu .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .tb-megamenu .row-fluid:after {
    clear: both;
  }
  .tb-megamenu .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.7624309392265194%;
    *margin-left: 2.709239449864817%;
  }
  .tb-megamenu .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 2.7624309392265194%;
  }
  .tb-megamenu .row-fluid .span12 {
    width: 100%;
    *width: 99.94680851063829%;
  }
  .tb-megamenu .row-fluid .span11 {
    width: 91.43646408839778%;
    *width: 91.38327259903608%;
  }
  .tb-megamenu .row-fluid .span10 {
    width: 82.87292817679558%;
    *width: 82.81973668743387%;
  }
  .tb-megamenu .row-fluid .span9 {
    width: 74.30939226519337%;
    *width: 74.25620077583166%;
  }
  .tb-megamenu .row-fluid .span8 {
    width: 65.74585635359117%;
    *width: 65.69266486422946%;
  }
  .tb-megamenu .row-fluid .span7 {
    width: 57.18232044198895%;
    *width: 57.12912895262725%;
  }
  .tb-megamenu .row-fluid .span6 {
    width: 48.61878453038674%;
    *width: 48.56559304102504%;
  }
  .tb-megamenu .row-fluid .span5 {
    width: 40.05524861878453%;
    *width: 40.00205712942283%;
  }
  .tb-megamenu .row-fluid .span4 {
    width: 31.491712707182323%;
    *width: 31.43852121782062%;
  }
  .tb-megamenu .row-fluid .span3 {
    width: 22.92817679558011%;
    *width: 22.87498530621841%;
  }
  .tb-megamenu .row-fluid .span2 {
    width: 14.3646408839779%;
    *width: 14.311449394616199%;
  }
  .tb-megamenu .row-fluid .span1 {
    width: 5.801104972375691%;
    *width: 5.747913483013988%;
  }
  .tb-megamenu .row-fluid .offset12 {
    margin-left: 105.52486187845304%;
    *margin-left: 105.41847889972962%;
  }
  .tb-megamenu .row-fluid .offset12:first-child {
    margin-left: 102.76243093922652%;
    *margin-left: 102.6560479605031%;
  }
  .tb-megamenu .row-fluid .offset11 {
    margin-left: 96.96132596685082%;
    *margin-left: 96.8549429881274%;
  }
  .tb-megamenu .row-fluid .offset11:first-child {
    margin-left: 94.1988950276243%;
    *margin-left: 94.09251204890089%;
  }
  .tb-megamenu .row-fluid .offset10 {
    margin-left: 88.39779005524862%;
    *margin-left: 88.2914070765252%;
  }
  .tb-megamenu .row-fluid .offset10:first-child {
    margin-left: 85.6353591160221%;
    *margin-left: 85.52897613729868%;
  }
  .tb-megamenu .row-fluid .offset9 {
    margin-left: 79.8342541436464%;
    *margin-left: 79.72787116492299%;
  }
  .tb-megamenu .row-fluid .offset9:first-child {
    margin-left: 77.07182320441989%;
    *margin-left: 76.96544022569647%;
  }
  .tb-megamenu .row-fluid .offset8 {
    margin-left: 71.2707182320442%;
    *margin-left: 71.16433525332079%;
  }
  .tb-megamenu .row-fluid .offset8:first-child {
    margin-left: 68.50828729281768%;
    *margin-left: 68.40190431409427%;
  }
  .tb-megamenu .row-fluid .offset7 {
    margin-left: 62.70718232044199%;
    *margin-left: 62.600799341718584%;
  }
  .tb-megamenu .row-fluid .offset7:first-child {
    margin-left: 59.94475138121547%;
    *margin-left: 59.838368402492065%;
  }
  .tb-megamenu .row-fluid .offset6 {
    margin-left: 54.14364640883978%;
    *margin-left: 54.037263430116376%;
  }
  .tb-megamenu .row-fluid .offset6:first-child {
    margin-left: 51.38121546961326%;
    *margin-left: 51.27483249088986%;
  }
  .tb-megamenu .row-fluid .offset5 {
    margin-left: 45.58011049723757%;
    *margin-left: 45.47372751851417%;
  }
  .tb-megamenu .row-fluid .offset5:first-child {
    margin-left: 42.81767955801105%;
    *margin-left: 42.71129657928765%;
  }
  .tb-megamenu .row-fluid .offset4 {
    margin-left: 37.01657458563536%;
    *margin-left: 36.91019160691196%;
  }
  .tb-megamenu .row-fluid .offset4:first-child {
    margin-left: 34.25414364640884%;
    *margin-left: 34.14776066768544%;
  }
  .tb-megamenu .row-fluid .offset3 {
    margin-left: 28.45303867403315%;
    *margin-left: 28.346655695309746%;
  }
  .tb-megamenu .row-fluid .offset3:first-child {
    margin-left: 25.69060773480663%;
    *margin-left: 25.584224756083227%;
  }
  .tb-megamenu .row-fluid .offset2 {
    margin-left: 19.88950276243094%;
    *margin-left: 19.783119783707537%;
  }
  .tb-megamenu .row-fluid .offset2:first-child {
    margin-left: 17.12707182320442%;
    *margin-left: 17.02068884448102%;
  }
  .tb-megamenu .row-fluid .offset1 {
    margin-left: 11.32596685082873%;
    *margin-left: 11.219583872105325%;
  }
  .tb-megamenu .row-fluid .offset1:first-child {
    margin-left: 8.56353591160221%;
    *margin-left: 8.457152932878806%;
  }
  .tb-megamenu input,
  .tb-megamenu textarea,
  .tb-megamenu .uneditable-input {
    margin-left: 0;
  }
  .tb-megamenu .controls-row [class*="span"] + [class*="span"] {
    margin-left: 20px;
  }
}
@media (min-width: 1200px) {
  .tb-megamenu .row {
    margin-left: -30px;
    *zoom: 1;
  }
  .tb-megamenu .row:before,
  .tb-megamenu .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .tb-megamenu .row:after {
    clear: both;
  }
  .tb-megamenu [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 30px;
  }
  .tb-megamenu .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .tb-megamenu .row-fluid:before,
  .tb-megamenu .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .tb-megamenu .row-fluid:after {
    clear: both;
  }
  .tb-megamenu .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.564102564102564%;
    *margin-left: 2.5109110747408616%;
  }
  .tb-megamenu .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 2.564102564102564%;
  }
  .tb-megamenu .row-fluid .span12 {
    width: 100%;
    *width: 99.94680851063829%;
  }
  .tb-megamenu .row-fluid .span11 {
    width: 91.45299145299145%;
    *width: 91.39979996362975%;
  }
  .tb-megamenu .row-fluid .span10 {
    width: 82.90598290598291%;
    *width: 82.8527914166212%;
  }
  .tb-megamenu .row-fluid .span9 {
    width: 74.35897435897436%;
    *width: 74.30578286961266%;
  }
  .tb-megamenu .row-fluid .span8 {
    width: 65.81196581196582%;
    *width: 65.75877432260411%;
  }
  .tb-megamenu .row-fluid .span7 {
    width: 57.26495726495726%;
    *width: 57.21176577559556%;
  }
  .tb-megamenu .row-fluid .span6 {
    width: 48.717948717948715%;
    *width: 48.664757228587014%;
  }
  .tb-megamenu .row-fluid .span5 {
    width: 40.17094017094017%;
    *width: 40.11774868157847%;
  }
  .tb-megamenu .row-fluid .span4 {
    width: 31.623931623931625%;
    *width: 31.570740134569924%;
  }
  .tb-megamenu .row-fluid .span3 {
    width: 23.076923076923077%;
    *width: 23.023731587561375%;
  }
  .tb-megamenu .row-fluid .span2 {
    width: 14.52991452991453%;
    *width: 14.476723040552828%;
  }
  .tb-megamenu .row-fluid .span1 {
    width: 5.982905982905983%;
    *width: 5.929714493544281%;
  }
  .tb-megamenu .row-fluid .offset12 {
    margin-left: 105.12820512820512%;
    *margin-left: 105.02182214948171%;
  }
  .tb-megamenu .row-fluid .offset12:first-child {
    margin-left: 102.56410256410257%;
    *margin-left: 102.45771958537915%;
  }
  .tb-megamenu .row-fluid .offset11 {
    margin-left: 96.58119658119658%;
    *margin-left: 96.47481360247316%;
  }
  .tb-megamenu .row-fluid .offset11:first-child {
    margin-left: 94.01709401709402%;
    *margin-left: 93.91071103837061%;
  }
  .tb-megamenu .row-fluid .offset10 {
    margin-left: 88.03418803418803%;
    *margin-left: 87.92780505546462%;
  }
  .tb-megamenu .row-fluid .offset10:first-child {
    margin-left: 85.47008547008548%;
    *margin-left: 85.36370249136206%;
  }
  .tb-megamenu .row-fluid .offset9 {
    margin-left: 79.48717948717949%;
    *margin-left: 79.38079650845607%;
  }
  .tb-megamenu .row-fluid .offset9:first-child {
    margin-left: 76.92307692307693%;
    *margin-left: 76.81669394435352%;
  }
  .tb-megamenu .row-fluid .offset8 {
    margin-left: 70.94017094017094%;
    *margin-left: 70.83378796144753%;
  }
  .tb-megamenu .row-fluid .offset8:first-child {
    margin-left: 68.37606837606839%;
    *margin-left: 68.26968539734497%;
  }
  .tb-megamenu .row-fluid .offset7 {
    margin-left: 62.393162393162385%;
    *margin-left: 62.28677941443899%;
  }
  .tb-megamenu .row-fluid .offset7:first-child {
    margin-left: 59.82905982905982%;
    *margin-left: 59.72267685033642%;
  }
  .tb-megamenu .row-fluid .offset6 {
    margin-left: 53.84615384615384%;
    *margin-left: 53.739770867430444%;
  }
  .tb-megamenu .row-fluid .offset6:first-child {
    margin-left: 51.28205128205128%;
    *margin-left: 51.175668303327875%;
  }
  .tb-megamenu .row-fluid .offset5 {
    margin-left: 45.299145299145295%;
    *margin-left: 45.1927623204219%;
  }
  .tb-megamenu .row-fluid .offset5:first-child {
    margin-left: 42.73504273504273%;
    *margin-left: 42.62865975631933%;
  }
  .tb-megamenu .row-fluid .offset4 {
    margin-left: 36.75213675213675%;
    *margin-left: 36.645753773413354%;
  }
  .tb-megamenu .row-fluid .offset4:first-child {
    margin-left: 34.18803418803419%;
    *margin-left: 34.081651209310785%;
  }
  .tb-megamenu .row-fluid .offset3 {
    margin-left: 28.205128205128204%;
    *margin-left: 28.0987452264048%;
  }
  .tb-megamenu .row-fluid .offset3:first-child {
    margin-left: 25.641025641025642%;
    *margin-left: 25.53464266230224%;
  }
  .tb-megamenu .row-fluid .offset2 {
    margin-left: 19.65811965811966%;
    *margin-left: 19.551736679396257%;
  }
  .tb-megamenu .row-fluid .offset2:first-child {
    margin-left: 17.094017094017094%;
    *margin-left: 16.98763411529369%;
  }
  .tb-megamenu .row-fluid .offset1 {
    margin-left: 11.11111111111111%;
    *margin-left: 11.004728132387708%;
  }
  .tb-megamenu .row-fluid .offset1:first-child {
    margin-left: 8.547008547008547%;
    *margin-left: 8.440625568285142%;
  }
  .tb-megamenu input,
  .tb-megamenu textarea,
  .tb-megamenu .uneditable-input {
    margin-left: 0;
  }
  .tb-megamenu .controls-row [class*="span"] + [class*="span"] {
    margin-left: 30px;
  }
  .tb-megamenu .thumbnails {
    margin-left: -30px;
  }
  .tb-megamenu .thumbnails > li {
    margin-left: 30px;
  }
  .tb-megamenu .row-fluid .thumbnails {
    margin-left: 0;
  }
}
@media (max-width: 979px) {
  .tb-megamenu body {
    padding-top: 0;
  }
  .tb-megamenu .navbar-fixed-top,
  .tb-megamenu .navbar-fixed-bottom {
    position: static;
  }
  .tb-megamenu .navbar-fixed-top {
    margin-bottom: 20px;
  }
  .tb-megamenu .navbar-fixed-bottom {
    margin-top: 20px;
  }
  .tb-megamenu .navbar-fixed-top .navbar-inner,
  .tb-megamenu .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }
  .tb-megamenu .navbar .container {
    width: auto;
    padding: 0;
  }
  .tb-megamenu .navbar .brand {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 0 0 -5px;
  }
  .tb-megamenu .nav-collapse {
    clear: both;
  }
  .tb-megamenu .nav-collapse .nav {
    float: none;
    margin: 0 0 10px;
  }
  .tb-megamenu .nav-collapse .nav > li {
    float: none;
  }
  .tb-megamenu .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }
  .tb-megamenu .nav-collapse .nav > .divider-vertical {
    display: none;
  }
  .tb-megamenu .nav-collapse .nav .nav-header {
    color: #777777;
    text-shadow: none;
  }
  .tb-megamenu .nav-collapse .nav > li > a,
  .tb-megamenu .nav-collapse .dropdown-menu a {
    padding: 9px 15px;
    font-weight: bold;
    color: #777777;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .tb-megamenu .nav-collapse .btn {
    padding: 4px 10px 4px;
    font-weight: normal;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .tb-megamenu .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }
  .tb-megamenu .nav-collapse .nav > li > a:hover,
  .tb-megamenu .nav-collapse .nav > li > a:focus,
  .tb-megamenu .nav-collapse .dropdown-menu a:hover,
  .tb-megamenu .nav-collapse .dropdown-menu a:focus {
    background-color: #f2f2f2;
  }
  .tb-megamenu .navbar-inverse .nav-collapse .nav > li > a,
  .tb-megamenu .navbar-inverse .nav-collapse .dropdown-menu a {
    color: #999999;
  }
  .tb-megamenu .navbar-inverse .nav-collapse .nav > li > a:hover,
  .tb-megamenu .navbar-inverse .nav-collapse .nav > li > a:focus,
  .tb-megamenu .navbar-inverse .nav-collapse .dropdown-menu a:hover,
  .tb-megamenu .navbar-inverse .nav-collapse .dropdown-menu a:focus {
    background-color: #111111;
  }
  .tb-megamenu .nav-collapse.in .btn-group {
    margin-top: 5px;
    padding: 0;
  }
  .tb-megamenu .nav-collapse .dropdown-menu {
    position: static;
    top: auto;
    left: auto;
    float: none;
    display: none;
    max-width: none;
    margin: 0 15px;
    padding: 0;
    background-color: transparent;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .tb-megamenu .nav-collapse .open > .dropdown-menu {
    display: block;
  }
  .tb-megamenu .nav-collapse .dropdown-menu:before,
  .tb-megamenu .nav-collapse .dropdown-menu:after {
    display: none;
  }
  .tb-megamenu .nav-collapse .dropdown-menu .divider {
    display: none;
  }
  .tb-megamenu .nav-collapse .nav > li > .dropdown-menu:before,
  .tb-megamenu .nav-collapse .nav > li > .dropdown-menu:after {
    display: none;
  }
  .tb-megamenu .nav-collapse .navbar-form,
  .tb-megamenu .nav-collapse .navbar-search {
    float: none;
    padding: 10px 15px;
    margin: 10px 0;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
    -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
  }
  .tb-megamenu .navbar-inverse .nav-collapse .navbar-form,
  .tb-megamenu .navbar-inverse .nav-collapse .navbar-search {
    border-top-color: #111111;
    border-bottom-color: #111111;
  }
  .tb-megamenu .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }
  .tb-megamenu .nav-collapse,
  .tb-megamenu .nav-collapse.collapse {
    overflow: hidden;
    height: 0;
  }
  .tb-megamenu .navbar .btn-navbar {
    display: block;
  }
  .tb-megamenu .navbar-static .navbar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 980px) {
  .tb-megamenu .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
}

.tb-megamenu .clearfix {
  *zoom: 1;
}
.tb-megamenu .clearfix:before,
.tb-megamenu .clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .clearfix:after {
  clear: both;
}
.tb-megamenu .mega-inner {
  padding: 10px;
  *zoom: 1;
}
.tb-megamenu .mega-inner:before,
.tb-megamenu .mega-inner:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .mega-inner:after {
  clear: both;
}
.tb-megamenu .row-fluid + .row-fluid {
  padding-top: 10px;
  border-top: 1px solid #eeeeee;
}
.tb-megamenu .mega > .mega-dropdown-menu {
  min-width: 200px;
  display: none;
}
.tb-megamenu .mega.open > .mega-dropdown-menu,
.tb-megamenu .mega.dropdown-submenu:hover > .mega-dropdown-menu {
  display: block;
}
.tb-megamenu .mega-group {
  *zoom: 1;
}
.tb-megamenu .mega-group:before,
.tb-megamenu .mega-group:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .mega-group:after {
  clear: both;
}
.tb-megamenu .mega-nav .mega-group > .mega-group-title,
.tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group > .mega-group-title {
  background: inherit;
  font-weight: bold;
  padding: 0 0 0 10px;
  margin: 0;
  text-transform: uppercase;
}
.tb-megamenu .mega-nav .mega-group > .mega-group-title:hover,
.tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title:hover,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group > .mega-group-title:hover,
.tb-megamenu .mega-nav .mega-group > .mega-group-title:active,
.tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title:active,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group > .mega-group-title:active,
.tb-megamenu .mega-nav .mega-group > .mega-group-title:focus,
.tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title:focus,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group > .mega-group-title:focus {
  background: inherit;
  color: inherit;
}
.tb-megamenu .mega-group-ct {
  margin: 0;
  padding: 0;
  *zoom: 1;
}
.tb-megamenu .mega-group-ct:before,
.tb-megamenu .mega-group-ct:after {
  display: table;
  content: "";
  line-height: 0;
}
.tb-megamenu .mega-group-ct:after {
  clear: both;
}
.tb-megamenu .mega-group-ct > .row-fluid > [class*="span"] > .mega-inner {
  padding: 0;
}
.tb-megamenu .span12.mega-col-nav .mega-inner {
  padding: 10px;
}
.tb-megamenu .mega-nav,
.tb-megamenu .dropdown-menu .mega-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tb-megamenu .mega-nav > li,
.tb-megamenu .dropdown-menu .mega-nav > li {
  display: block;
  float: none;
  list-style: none;
  margin-left: 0;
}
.tb-megamenu .mega-nav > li a,
.tb-megamenu .dropdown-menu .mega-nav > li a {
  white-space: normal;
}
.tb-megamenu .mega-group > .mega-nav,
.tb-megamenu .dropdown-menu .mega-group > .mega-nav {
  margin-left: -5px;
  margin-right: -5px;
}
.tb-megamenu .mega-nav .dropdown-submenu > a::after {
  margin-right: 5px;
}
.tb-megamenu .tb-block {
  margin-bottom: 10px;
}
.tb-megamenu .tb-block .block-title {
  margin-bottom: 0;
}
.tb-megamenu .tb-block .block-ct {
  margin: 0;
  padding: 0;
}
.tb-megamenu .nav .caret,
.tb-megamenu .dropdown-submenu .caret,
.tb-megamenu .mega-menu .caret {
  display: none;
}
.tb-megamenu .nav > .dropdown > .dropdown-toggle .caret {
  display: inline-block;
}
.tb-megamenu .nav [class^="icon-"],
.tb-megamenu .nav [class*=" icon-"] {
  margin-right: 5px;
}

.tb-megamenu .nav {
  margin-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.tb-megamenu .nav > li > a {
  display: block;
}
.tb-megamenu .nav > li > a:hover {
  text-decoration: none;
  background-color: #eee;
}
.tb-megamenu .nav > .pull-right {
  float: right;
}

.tb-megamenu .dropup,
.tb-megamenu .dropdown {
  position: relative;
}
.tb-megamenu .dropdown-toggle {
  *margin-bottom: -3px;
}
.tb-megamenu .dropdown-toggle:active,
.tb-megamenu .open .dropdown-toggle {
  outline: 0;
}
.tb-megamenu .caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.tb-megamenu .dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.tb-megamenu .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.tb-megamenu .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.tb-megamenu .dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 8px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
}
.tb-megamenu .dropdown-menu a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 18px;
  color: #333;
  white-space: nowrap;
}
.tb-megamenu .dropdown-menu li > a:hover,
.tb-megamenu .dropdown-menu li > a:focus,
.tb-megamenu .dropdown-submenu:hover > a {
  text-decoration: none;
  color: #fff;
  background-color: #08c;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top,#08c,#0077b3);
  background-image: -webkit-gradient(linear,0 0,0 100%,from(#08c),to(#0077b3));
  background-image: -webkit-linear-gradient(top,#08c,#0077b3);
  background-image: -o-linear-gradient(top,#08c,#0077b3);
  background-image: linear-gradient(to bottom,#08c,#0077b3);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0076b2', GradientType=0);
}
.tb-megamenu .dropdown-menu .active > a,
.tb-megamenu .dropdown-menu .active > a:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #08c;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top,#08c,#0077b3);
  background-image: -webkit-gradient(linear,0 0,0 100%,from(#08c),to(#0077b3));
  background-image: -webkit-linear-gradient(top,#08c,#0077b3);
  background-image: -o-linear-gradient(top,#08c,#0077b3);
  background-image: linear-gradient(to bottom,#08c,#0077b3);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0076b2', GradientType=0);
}
.tb-megamenu .dropdown-menu .disabled > a,
.tb-megamenu .dropdown-menu .disabled > a:hover {
  color: #999;
}
.tb-megamenu .dropdown-menu .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.tb-megamenu .open {
  *z-index: 1000;
}
.tb-megamenu .open > .dropdown-menu {
  display: block;
}
.tb-megamenu .pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.tb-megamenu .dropup .caret {
  border-top: 0;
  border-bottom: 4px solid #000;
  content: "\2191";
}
.tb-megamenu .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.tb-megamenu .dropdown-submenu {
  position: relative;
}
.tb-megamenu .dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.tb-megamenu .dropdown-submenu:hover .dropdown-menu {
  display: block;
}
.tb-megamenu .dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.tb-megamenu .dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.tb-megamenu .dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.tb-megamenu .mega-align-left > .dropdown-menu {
  left: 0;
}
.tb-megamenu .mega-align-right > .dropdown-menu {
  left: auto;
  right: 0;
}
.tb-megamenu .mega-align-center > .dropdown-menu {
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
}
.tb-megamenu .dropdown-submenu.mega-align-left > .dropdown-menu {
  left: 100%;
}
.tb-megamenu .dropdown-submenu.mega-align-right > .dropdown-menu {
  left: auto;
  right: 100%;
}
.tb-megamenu .mega-align-justify {
  position: static;
}
.tb-megamenu .mega-align-justify > .dropdown-menu {
  left: 0;
  margin-left: 0;
  top: auto;
}
.tb-megamenu .mega-align-justify > .dropdown-menu.span12 {
  width: 100%;
}
.tb-megamenu .mega-caption {
  display: block;
  white-space: nowrap;
}
.tb-megamenu .fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -moz-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}
.tb-megamenu .fade.in {
  opacity: 1;
}
.tb-megamenu .collapse {
  position: relative;
  height: 0;
  overflow: visible;
  -webkit-transition: height .35s ease;
  -moz-transition: height .35s ease;
  -o-transition: height .35s ease;
  transition: height .35s ease;
}
.tb-megamenu .collapse.in {
  height: auto;
}
.tb-megamenu .close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.tb-megamenu .close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.tb-megamenu button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.tb-megamenu .btn {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 4px 14px;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 18px;
  *line-height: 18px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #333;
  text-shadow: 0 1px 1px rgba(255,255,255,0.75);
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top,#fff,#e6e6e6);
  background-image: -webkit-gradient(linear,0 0,0 100%,from(#fff),to(#e6e6e6));
  background-image: -webkit-linear-gradient(top,#fff,#e6e6e6);
  background-image: -o-linear-gradient(top,#fff,#e6e6e6);
  background-image: linear-gradient(to bottom,#fff,#e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe5e5e5', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
  *background-color: #e6e6e6;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border: 1px solid #bbb;
  *border: 0;
  border-bottom-color: #a2a2a2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *margin-left: .3em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.tb-megamenu .btn:hover,
.tb-megamenu .btn:active,
.tb-megamenu .btn.active,
.tb-megamenu .btn.disabled,
.tb-megamenu .btn[disabled] {
  color: #333;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}
.tb-megamenu .btn:active,
.tb-megamenu .btn.active {
  background-color: #cccccc \9;
}
.tb-megamenu .btn:first-child {
  *margin-left: 0;
}
.tb-megamenu .btn:hover {
  color: #333;
  text-decoration: none;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
  background-position: 0 -15px;
  -webkit-transition: background-position .1s linear;
  -moz-transition: background-position .1s linear;
  -o-transition: background-position .1s linear;
  transition: background-position .1s linear;
}
.tb-megamenu .btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.tb-megamenu .btn.active,
.tb-megamenu .btn:active {
  background-color: #e6e6e6;
  background-color: #d9d9d9 \9;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.tb-megamenu .btn.disabled,
.tb-megamenu .btn[disabled] {
  cursor: default;
  background-color: #e6e6e6;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.tb-megamenu .btn-danger {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
  background-color: #da4f49;
  background-image: -moz-linear-gradient(top,#ee5f5b,#bd362f);
  background-image: -webkit-gradient(linear,0 0,0 100%,from(#ee5f5b),to(#bd362f));
  background-image: -webkit-linear-gradient(top,#ee5f5b,#bd362f);
  background-image: -o-linear-gradient(top,#ee5f5b,#bd362f);
  background-image: linear-gradient(to bottom,#ee5f5b,#bd362f);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffbd362f', GradientType=0);
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
  *background-color: #bd362f;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tb-megamenu .btn-danger:hover,
.tb-megamenu .btn-danger:active,
.tb-megamenu .btn-danger.active,
.tb-megamenu .btn-danger.disabled,
.tb-megamenu .btn-danger[disabled] {
  color: #fff;
  background-color: #bd362f;
  *background-color: #a9302a;
}
.tb-megamenu .btn-danger:active,
.tb-megamenu .btn-danger.active {
  background-color: #942a25 \9;
}
.tb-megamenu .btn-success {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
  background-color: #5bb75b;
  background-image: -moz-linear-gradient(top,#62c462,#51a351);
  background-image: -webkit-gradient(linear,0 0,0 100%,from(#62c462),to(#51a351));
  background-image: -webkit-linear-gradient(top,#62c462,#51a351);
  background-image: -o-linear-gradient(top,#62c462,#51a351);
  background-image: linear-gradient(to bottom,#62c462,#51a351);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff51a351', GradientType=0);
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
  *background-color: #51a351;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tb-megamenu .btn-success:hover,
.tb-megamenu .btn-success:active,
.tb-megamenu .btn-success.active,
.tb-megamenu .btn-success.disabled,
.tb-megamenu .btn-success[disabled] {
  color: #fff;
  background-color: #51a351;
  *background-color: #499249;
}
.tb-megamenu .btn-success:active,
.tb-megamenu .btn-success.active {
  background-color: #408140 \9;
}
.tb-megamenu .btn-danger.active,
.tb-megamenu .btn-success.active {
  color: rgba(255,255,255,0.75);
}
.tb-megamenu .btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tb-megamenu .btn-block + .btn-block {
  margin-top: 5px;
}
.tb-megamenu .btn-primary.active,
.tb-megamenu .btn-warning.active,
.tb-megamenu .btn-danger.active,
.tb-megamenu .btn-success.active,
.tb-megamenu .btn-info.active,
.tb-megamenu .btn-inverse.active {
  color: rgba(255,255,255,0.95);
}
.tb-megamenu .btn {
  border-color: #c5c5c5;
  border-color: rgba(0,0,0,0.15) rgba(0,0,0,0.15) rgba(0,0,0,0.25);
}

.tb-megamenu button.btn,
.tb-megamenu input[type="submit"].btn {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
.tb-megamenu button.btn::-moz-focus-inner,
.tb-megamenu input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.tb-megamenu .btn-group {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  *margin-left: .3em;
}
.tb-megamenu .btn-group:first-child {
  *margin-left: 0;
}
.tb-megamenu .btn-group + .btn-group {
  margin-left: 5px;
}
.tb-megamenu .btn-group > .btn {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tb-megamenu .btn-group > .btn + .btn {
  margin-left: -1px;
}
.tb-megamenu .btn-group > .btn,
.tb-megamenu .btn-group > .dropdown-menu {
  font-size: 13px;
}
.tb-megamenu .btn-group > .btn.first,
.tb-megamenu .btn-group > .btn:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.tb-megamenu .btn-group > .btn.last,
.tb-megamenu .btn-group > .btn:last-child,
.tb-megamenu .btn-group > .dropdown-toggle {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.tb-megamenu .btn-group > .btn.large:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
}
.tb-megamenu .btn-group > .btn.large:last-child,
.tb-megamenu .btn-group > .large.dropdown-toggle {
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
}
.tb-megamenu .btn-group > .btn:hover,
.tb-megamenu .btn-group > .btn:focus,
.tb-megamenu .btn-group > .btn:active,
.tb-megamenu .btn-group > .btn.active {
  z-index: 2;
}
.tb-megamenu .btn-group .dropdown-toggle:active,
.tb-megamenu .btn-group.open .dropdown-toggle {
  outline: 0;
}
.tb-megamenu .btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  -webkit-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  *padding-top: 5px;
  *padding-bottom: 5px;
}
.tb-megamenu .btn-group.open .dropdown-toggle {
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.tb-megamenu .btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}
.tb-megamenu a.disabled,
.tb-megamenu a.disabled:hover {
  color: #999999;
  background-color: transparent;
  cursor: default;
  text-decoration: none;
}
#tb-megamenu-admin fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
.tb-megamenu legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 18px;
  font-size: 19.5px;
  line-height: 36px;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
.tb-megamenu legend small {
  font-size: 13.5px;
  color: #999;
}
.tb-megamenu label,
.tb-megamenu input,
.tb-megamenu button,
.tb-megamenu select,
.tb-megamenu textarea {
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
}
.tb-megamenu input,
.tb-megamenu button,
.tb-megamenu select,
.tb-megamenu textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.tb-megamenu label {
  display: block;
  margin-bottom: 5px;
}
.tb-megamenu select,
.tb-megamenu textarea,
.tb-megamenu input[type="text"],
.tb-megamenu input[type="password"],
.tb-megamenu input[type="datetime"],
.tb-megamenu input[type="datetime-local"],
.tb-megamenu input[type="date"],
.tb-megamenu input[type="month"],
.tb-megamenu input[type="time"],
.tb-megamenu input[type="week"],
.tb-megamenu input[type="number"],
.tb-megamenu input[type="email"],
.tb-megamenu input[type="url"],
.tb-megamenu input[type="search"],
.tb-megamenu input[type="tel"],
.tb-megamenu input[type="color"],
.tb-megamenu .uneditable-input {
  display: inline-block;
  height: 18px;
  padding: 4px 6px;
  margin-bottom: 9px;
  font-size: 13px;
  line-height: 18px;
  color: #555;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.tb-megamenu input,
.tb-megamenu textarea {
  width: 170px;
}
.tb-megamenu textarea {
  height: auto;
}
.tb-megamenu textarea,
.tb-megamenu input[type="text"],
.tb-megamenu input[type="password"],
.tb-megamenu input[type="datetime"],
.tb-megamenu input[type="datetime-local"],
.tb-megamenu input[type="date"],
.tb-megamenu input[type="month"],
.tb-megamenu input[type="time"],
.tb-megamenu input[type="week"],
.tb-megamenu input[type="number"],
.tb-megamenu input[type="email"],
.tb-megamenu input[type="url"],
.tb-megamenu input[type="search"],
.tb-megamenu input[type="tel"],
.tb-megamenu input[type="color"],
.tb-megamenu .uneditable-input {
  background-color: #fff;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  -webkit-transition: border linear .2s, box-shadow linear .2s;
  -moz-transition: border linear .2s, box-shadow linear .2s;
  -o-transition: border linear .2s, box-shadow linear .2s;
  transition: border linear .2s, box-shadow linear .2s;
}
.tb-megamenu textarea:focus,
.tb-megamenu input[type="text"]:focus,
.tb-megamenu input[type="password"]:focus,
.tb-megamenu input[type="datetime"]:focus,
.tb-megamenu input[type="datetime-local"]:focus,
.tb-megamenu input[type="date"]:focus,
.tb-megamenu input[type="month"]:focus,
.tb-megamenu input[type="time"]:focus,
.tb-megamenu input[type="week"]:focus,
.tb-megamenu input[type="number"]:focus,
.tb-megamenu input[type="email"]:focus,
.tb-megamenu input[type="url"]:focus,
.tb-megamenu input[type="search"]:focus,
.tb-megamenu input[type="tel"]:focus,
.tb-megamenu input[type="color"]:focus,
.tb-megamenu .uneditable-input:focus {
  border-color: rgba(82,168,236,0.8);
  outline: 0;
  outline: thin dotted \9;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}
.tb-megamenu input[type="radio"],
.tb-megamenu input[type="checkbox"] {
  margin: 4px 0 0;
  *margin-top: 0;
  margin-top: 1px \9;
  line-height: normal;
  cursor: pointer;
}
.tb-megamenu input[type="file"],
.tb-megamenu input[type="image"],
.tb-megamenu input[type="submit"],
.tb-megamenu input[type="reset"],
.tb-megamenu input[type="button"],
.tb-megamenu input[type="radio"],
.tb-megamenu input[type="checkbox"] {
  width: auto;
}
.tb-megamenu select,
.tb-megamenu input[type="file"] {
  height: 30px;
  *margin-top: 4px;
  line-height: 30px;
}
.tb-megamenu select {
  width: 220px;
  border: 1px solid #bbb;
  background-color: #fff;
}
.tb-megamenu select[multiple],
.tb-megamenu select[size] {
  height: auto;
}
.tb-megamenu select:focus,
.tb-megamenu input[type="file"]:focus,
.tb-megamenu input[type="radio"]:focus,
.tb-megamenu input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

@media (max-width: 979px) {
  .tb-megamenu .navbar .brand {
    font-size: 13px;
  }
  .tb-megamenu .navbar .nav {
    margin: 0 2px 0 0;
  }
  .tb-megamenu .navbar .nav > li > a {
    padding: 6px;
  }
  .tb-megamenu .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 767px) {
  .tb-megamenu .page-title {
    text-align: center;
  }
  .tb-megamenu .navbar-search.pull-right {
    float: none;
    text-align: center;
  }
  .tb-megamenu .subhead-fixed {
    position: static;
    width: auto;
  }
  .tb-megamenu .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 738px) {
  .tb-megamenu body {
    padding-top: 30px;
  }
}
@media (max-width: 738px) {
  .tb-megamenu .navbar .brand {
    font-size: 16px;
  }
}
.tb-megamenu .btn-subhead {
  display: none;
}
@media (min-width: 481px) {
  .tb-megamenu #filter-bar {
    height: 29px;
  }
}
@media (max-width: 480px) {
  .table th:nth-of-type(n+5),
  .table th:nth-of-type(3),
  .table th:nth-of-type(2),
  .table td:nth-of-type(n+5),
  .table td:nth-of-type(2),
  .table td:nth-of-type(3) {
    white-space: normal;
  }
  .tb-megamenu .pagination a {
    padding: 5px;
  }
  .tb-megamenu .btn-group.divider,
  .tb-megamenu .header .row-fluid .span3,
  .tb-megamenu .header .row-fluid .span7,
  .tb-megamenu .subhead-collapse {
    display: none;
  }
  .tb-megamenu .btn {
    font-size: 11px;
    padding: 1px 4px 2px 4px;
  }
  .tb-megamenu .navbar .btn {
    margin: 0;
  }
  .tb-megamenu .btn-subhead {
    display: block;
    margin: 10px 0;
  }
  .tb-megamenu .chzn-container,
  .tb-megamenu .chzn-container .chzn-results,
  .tb-megamenu .chzn-container-single .chzn-drop,
  .tb-megamenu .btn-toolbar > .btn-group,
  .tb-megamenu .btn-toolbar > .btn-group > .btn {
    width: 99% !important;
  }
  .tb-megamenu .login .chzn-single {
    width: 222px !important;
  }
  .tb-megamenu .login .chzn-container,
  .tb-megamenu .login .chzn-drop {
    width: 230px !important;
  }
}
@media (max-width: 738px) {
  .tb-megamenu .navbar .brand {
    margin-top: 2px;
  }
  .tb-megamenu .navbar .btn-navbar {
    margin-top: 3px;
    margin-right: 3px;
    margin-bottom: 3px;
  }
  .tb-megamenu .nav-collapse .nav .nav-header {
    color: #fff;
  }
  .tb-megamenu .nav-collapse.collapse.in {
    height: auto !important;
  }
  .tb-megamenu .nav-collapse .nav,
  .tb-megamenu .navbar .nav-collapse .nav.pull-right {
    margin: 0;
  }
  .tb-megamenu .nav-collapse .dropdown-menu {
    margin: 0;
  }
  .tb-megamenu .nav-collapse .nav li a {
    margin-bottom: 0;
    border-top: 1px solid #d9d9d9;
  }
  .tb-megamenu .nav-collapse .nav li ul li ul.dropdown-menu,
  .tb-megamenu .nav-collapse .nav li ul li:hover ul.dropdown-menu,
  .tb-megamenu .nav-collapse .caret {
    display: none !important;
  }
  .tb-megamenu .nav-collapse .nav > li > a,
  .tb-megamenu .nav-collapse .dropdown-menu a {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .tb-megamenu .navbar .nav-collapse .nav > li > .dropdown-menu::before,
  .tb-megamenu .navbar .nav-collapse .nav > li > .dropdown-menu::after,
  .tb-megamenu .navbar .nav-collapse .dropdown-submenu > a::after {
    display: none;
  }
  .tb-megamenu .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 0;
  }
}

.tb-megamenu {
  background-color: #000;
}
.tb-megamenu:before,
.tb-megamenu:after {
  content: "";
  display: table;
  line-height: 0;
}
.tb-megamenu:after {
  clear: both;
}
.tb-megamenu .nav {
  margin: 0;
}
.tb-megamenu .nav > li {
  float: left;
  margin-left: 0;
}
.tb-megamenu .nav > li > a {
  border-right: 1px solid #222;
  border-top: 0;
  color: #fff;
  font-weight: bold;
  padding: 15px 20px;
  text-shadow: none;
}
.tb-megamenu .nav > li > a:focus,
.tb-megamenu .nav > li > a:hover {
  background-color: #fff;
  color: #e54e4b;
  text-decoration: none;
  text-shadow: none;
  outline: none;
}
.tb-megamenu .nav > .active > a,
.tb-megamenu .nav > .active > a:hover,
.tb-megamenu .nav > .active > a:focus {
  background-color: #e54e4b;
  color: #fff;
  text-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.tb-megamenu .btn-navbar {
  display: none;
  float: left;
  padding: 10px;
  margin: 0;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
  background-color: #e8e8e8;
  *background-color: #d9d9d9;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
}
.tb-megamenu .btn-navbar:hover,
.tb-megamenu .btn-navbar:focus,
.tb-megamenu .btn-navbar:active,
.tb-megamenu .btn-navbar.active,
.tb-megamenu .btn-navbar.disabled,
.tb-megamenu .btn-navbar[disabled] {
  color: #fff;
  background-color: #d9d9d9;
  *background-color: #cccccc;
}
.tb-megamenu .btn-navbar:active,
.tb-megamenu .btn-navbar.active {
  background-color: #bfbfbf \9;
}
.tb-megamenu .btn-navbar [class^="icon-"],
.tb-megamenu .btn-navbar [class^=" icon-"] {
  color: #666;
  text-shadow: none;
}
.tb-megamenu .dropdown .caret {
  margin-left: 5px;
  margin-top: 9px;
}
.tb-megamenu .dropdown-menu {
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}
.tb-megamenu .dropdown-menu li > a {
  border-top: 1px solid #eee;
  padding: 5px 20px;
  text-shadow: none;
}
.tb-megamenu .dropdown-menu li:first-child > a {
  border-top: 0;
}
.tb-megamenu .mega-nav > li:first-child > a,
.tb-megamenu .dropdown-menu .mega-nav > li:first-child > a {
  margin-top: 0;
  padding-top: 0;
}
.tb-megamenu .nav > li > .dropdown-menu:before {
  display: none;
}
.tb-megamenu .nav > li > .dropdown-menu:after {
  display: none;
}
.tb-megamenu .dropdown-menu .disabled > a,
.tb-megamenu .dropdown-menu .disabled > a:hover {
  color: #999;
}
.tb-megamenu .dropdown-menu .disabled > a:hover {
  background-color: transparent;
}
.tb-megamenu .nav li.dropdown.open > .dropdown-toggle {
  background-color: #fff;
  color: #e54e4b;
}
.tb-megamenu .nav li.dropdown.active > .dropdown-toggle,
.tb-megamenu .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #e54e4b;
  color: #fff;
}
.tb-megamenu .mega-inner {
  padding: 10px;
}
.tb-megamenu .row-fluid + .row-fluid {
  border-top: 1px solid #ddd;
}
.tb-megamenu .mega-nav > li a,
.tb-megamenu .dropdown-menu .mega-nav > li a {
  padding: 5px 0;
}
.tb-megamenu .mega-nav .dropdown-submenu > a::after {
  margin-right: 0;
}
.tb-megamenu .tb-block .block-title {
  font-size: 12px;
  color: #333;
  display: block;
  line-height: 20px;
  margin: 0 0 15px;
  text-transform: uppercase;
}
.tb-megamenu .tb-block ul,
.tb-megamenu .tb-block .nav {
  margin: 0;
}
.tb-megamenu .tb-block ul li,
.tb-megamenu .tb-block .nav li {
  background: none;
  list-style: disc;
  display: list-item;
  float: none;
  margin: 0;
  padding: 0;
  border: 0;
}
.tb-megamenu .tb-block ul li a,
.tb-megamenu .tb-block .nav li a {
  display: inline;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 100%;
  background: none;
  font: inherit;
  white-space: normal;
}
.tb-megamenu .tb-block ul li a:hover,
.tb-megamenu .tb-block ul li a:focus,
.tb-megamenu .tb-block ul li a:active,
.tb-megamenu .tb-block .nav li a:hover,
.tb-megamenu .tb-block .nav li a:focus,
.tb-megamenu .tb-block .nav li a:active {
  background: none;
  font: inherit;
}
.tb-megamenu .mega-caption {
  color: #999;
  font-size: 12px;
  margin-top: 3px;
  font-weight: normal;
}


.tb-megamenu .caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.tb-megamenu .dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.tb-megamenu .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,0.2);
  border-bottom: 3px solid #e65855;
  border-top: none;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  padding: 0;
  margin: 0;
  -webkit-border-radius:0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.tb-megamenu .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.tb-megamenu .dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
}
.tb-megamenu .dropdown-menu li > a {
  clear: both;
  color: #333;
  display: block;
  line-height: 20px;
  font-weight: normal;
  padding: 3px 20px;
  text-shadow: none;
  white-space: nowrap;
}
.tb-megamenu .dropdown-menu li > a:hover,
.tb-megamenu .dropdown-menu li > a:focus,
.tb-megamenu .dropdown-submenu:hover > a {
  background-color: inherit;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  color: #e54e4b;
  text-decoration: none;
}
.tb-megamenu .dropdown-menu .active > a,
.tb-megamenu .dropdown-menu .active > a:hover {
  color: #eee;
  text-decoration: none;
  outline: 0;
  background-color: #e54e4b;
}
.tb-megamenu .dropdown-menu .disabled > a,
.tb-megamenu .dropdown-menu .disabled > a:hover {
  color: #999;
}
.tb-megamenu .dropdown-menu .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: default;
}
.tb-megamenu .open {
  *z-index: 1000;
}
.tb-megamenu .open > .dropdown-menu {
  display: block;
}
.tb-megamenu .nav li.dropdown.active > .dropdown-toggle,
.tb-megamenu .nav li.dropdown.open.active > .dropdown-toggle,
.tb-megamenu .nav > li.dropdown.open.active > a:hover {
  background-color: #e54e4b;
  border-color: #444;
  color: #eee;
}
.tb-megamenu .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #666;
  border-bottom-color: #666;
  margin-top: 8px;
}
.tb-megamenu .nav li.dropdown > .dropdown-toggle:hover .caret {
  border-top-color: #eee;
  border-bottom-color: #eee;
}
.tb-megamenu .nav li.dropdown.open > .dropdown-toggle .caret,
.tb-megamenu .nav li.dropdown.active > .dropdown-toggle .caret,
.tb-megamenu .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #eee;
  border-bottom-color: #eee;
}
.tb-megamenu .pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.tb-megamenu .dropup .caret,
.tb-megamenu .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000;
  content: "";
}
.tb-megamenu .dropup .dropdown-menu,
.tb-megamenu .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.tb-megamenu .dropdown-submenu {
  position: relative;
}
.tb-megamenu .dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tb-megamenu .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.tb-megamenu .touch .dropdown-submenu:hover > .dropdown-menu {
  display: none;
}
.tb-megamenu .touch .dropdown-submenu.open > .dropdown-menu {
  display: block;
}
.tb-megamenu .dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tb-megamenu .dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.tb-megamenu .dropdown-submenu:hover > a:after {
  border-left-color: #eee;
}
.tb-megamenu .dropdown-submenu.pull-left {
  float: none;
}
.tb-megamenu .dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.tb-megamenu .dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 980px) and (max-width: 1199px) {
  .tb-megamenu .row {
    margin-left: -40px;
    *zoom: 1;
  }
  .tb-megamenu .row:before,
  .tb-megamenu .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .tb-megamenu .row:after {
    clear: both;
  }
  .tb-megamenu [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 40px;
  }
  .tb-megamenu .container,
  .tb-megamenu .navbar-static-top .container,
  .tb-megamenu .navbar-fixed-top .container,
  .tb-megamenu .navbar-fixed-bottom .container {
    width: 932px;
  }
  .tb-megamenu .span12 {
    width: 932px;
  }
  .tb-megamenu .span11 {
    width: 851px;
  }
  .tb-megamenu .span10 {
    width: 770px;
  }
  .tb-megamenu .span9 {
    width: 689px;
  }
  .tb-megamenu .span8 {
    width: 608px;
  }
  .tb-megamenu .span7 {
    width: 527px;
  }
  .tb-megamenu .span6 {
    width: 446px;
  }
  .tb-megamenu .span5 {
    width: 365px;
  }
  .tb-megamenu .span4 {
    width: 284px;
  }
  .tb-megamenu .span3 {
    width: 203px;
  }
  .tb-megamenu .span2 {
    width: 122px;
  }
  .tb-megamenu .span1 {
    width: 41px;
  }
  .tb-megamenu .offset12 {
    margin-left: 1012px;
  }
  .tb-megamenu .offset11 {
    margin-left: 931px;
  }
  .tb-megamenu .offset10 {
    margin-left: 850px;
  }
  .tb-megamenu .offset9 {
    margin-left: 769px;
  }
  .tb-megamenu .offset8 {
    margin-left: 688px;
  }
  .tb-megamenu .offset7 {
    margin-left: 607px;
  }
  .tb-megamenu .offset6 {
    margin-left: 526px;
  }
  .tb-megamenu .offset5 {
    margin-left: 445px;
  }
  .tb-megamenu .offset4 {
    margin-left: 364px;
  }
  .tb-megamenu .offset3 {
    margin-left: 283px;
  }
  .tb-megamenu .offset2 {
    margin-left: 202px;
  }
  .tb-megamenu .offset1 {
    margin-left: 121px;
  }
  .tb-megamenu .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .tb-megamenu .row-fluid:before,
  .tb-megamenu .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .tb-megamenu .row-fluid:after {
    clear: both;
  }
  .tb-megamenu .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 4.2918454935622%;
    *margin-left: 4.2381974248927%;
  }
  .tb-megamenu .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 4.2918454935622%;
  }
  .tb-megamenu .row-fluid .span12 {
    width: 100%;
    *width: 99.94635193133%;
  }
  .tb-megamenu .row-fluid .span11 {
    width: 91.309012875536%;
    *width: 91.255364806867%;
  }
  .tb-megamenu .row-fluid .span10 {
    width: 82.618025751073%;
    *width: 82.564377682403%;
  }
  .tb-megamenu .row-fluid .span9 {
    width: 73.927038626609%;
    *width: 73.87339055794%;
  }
  .tb-megamenu .row-fluid .span8 {
    width: 65.236051502146%;
    *width: 65.182403433476%;
  }
  .tb-megamenu .row-fluid .span7 {
    width: 56.545064377682%;
    *width: 56.491416309013%;
  }
  .tb-megamenu .row-fluid .span6 {
    width: 47.854077253219%;
    *width: 47.800429184549%;
  }
  .tb-megamenu .row-fluid .span5 {
    width: 39.163090128755%;
    *width: 39.109442060086%;
  }
  .tb-megamenu .row-fluid .span4 {
    width: 30.472103004292%;
    *width: 30.418454935622%;
  }
  .tb-megamenu .row-fluid .span3 {
    width: 21.781115879828%;
    *width: 21.727467811159%;
  }
  .tb-megamenu .row-fluid .span2 {
    width: 13.090128755365%;
    *width: 13.036480686695%;
  }
  .tb-megamenu .row-fluid .span1 {
    width: 4.3991416309013%;
    *width: 4.3454935622318%;
  }
  .tb-megamenu .row-fluid .offset12 {
    margin-left: 108.58369098712%;
    *margin-left: 108.47639484979%;
  }
  .tb-megamenu .row-fluid .offset12:first-child {
    margin-left: 104.29184549356%;
    *margin-left: 104.18454935622%;
  }
  .tb-megamenu .row-fluid .offset11 {
    margin-left: 99.892703862661%;
    *margin-left: 99.785407725322%;
  }
  .tb-megamenu .row-fluid .offset11:first-child {
    margin-left: 95.600858369099%;
    *margin-left: 95.49356223176%;
  }
  .tb-megamenu .row-fluid .offset10 {
    margin-left: 91.201716738197%;
    *margin-left: 91.094420600858%;
  }
  .tb-megamenu .row-fluid .offset10:first-child {
    margin-left: 86.909871244635%;
    *margin-left: 86.802575107296%;
  }
  .tb-megamenu .row-fluid .offset9 {
    margin-left: 82.510729613734%;
    *margin-left: 82.403433476395%;
  }
  .tb-megamenu .row-fluid .offset9:first-child {
    margin-left: 78.218884120172%;
    *margin-left: 78.111587982833%;
  }
  .tb-megamenu .row-fluid .offset8 {
    margin-left: 73.81974248927%;
    *margin-left: 73.712446351931%;
  }
  .tb-megamenu .row-fluid .offset8:first-child {
    margin-left: 69.527896995708%;
    *margin-left: 69.420600858369%;
  }
  .tb-megamenu .row-fluid .offset7 {
    margin-left: 65.128755364807%;
    *margin-left: 65.021459227468%;
  }
  .tb-megamenu .row-fluid .offset7:first-child {
    margin-left: 60.836909871245%;
    *margin-left: 60.729613733906%;
  }
  .tb-megamenu .row-fluid .offset6 {
    margin-left: 56.437768240343%;
    *margin-left: 56.330472103004%;
  }
  .tb-megamenu .row-fluid .offset6:first-child {
    margin-left: 52.145922746781%;
    *margin-left: 52.038626609442%;
  }
  .tb-megamenu .row-fluid .offset5 {
    margin-left: 47.74678111588%;
    *margin-left: 47.639484978541%;
  }
  .tb-megamenu .row-fluid .offset5:first-child {
    margin-left: 43.454935622318%;
    *margin-left: 43.347639484979%;
  }
  .tb-megamenu .row-fluid .offset4 {
    margin-left: 39.055793991416%;
    *margin-left: 38.948497854077%;
  }
  .tb-megamenu .row-fluid .offset4:first-child {
    margin-left: 34.763948497854%;
    *margin-left: 34.656652360515%;
  }
  .tb-megamenu .row-fluid .offset3 {
    margin-left: 30.364806866953%;
    *margin-left: 30.257510729614%;
  }
  .tb-megamenu .row-fluid .offset3:first-child {
    margin-left: 26.072961373391%;
    *margin-left: 25.965665236052%;
  }
  .tb-megamenu .row-fluid .offset2 {
    margin-left: 21.673819742489%;
    *margin-left: 21.56652360515%;
  }
  .tb-megamenu .row-fluid .offset2:first-child {
    margin-left: 17.381974248927%;
    *margin-left: 17.274678111588%;
  }
  .tb-megamenu .row-fluid .offset1 {
    margin-left: 12.982832618026%;
    *margin-left: 12.875536480687%;
  }
  .tb-megamenu .row-fluid .offset1:first-child {
    margin-left: 8.6909871244635%;
    *margin-left: 8.5836909871245%;
  }
  .tb-megamenu input,
  .tb-megamenu textarea,
  .tb-megamenu .uneditable-input {
    margin-left: 0;
  }
  .tb-megamenu .controls-row [class*="span"] + [class*="span"] {
    margin-left: 40px;
  }
  .tb-megamenu input.span12, textarea.span12, .uneditable-input.span12 {
    width: 918px;
  }
  .tb-megamenu input.span11, textarea.span11, .uneditable-input.span11 {
    width: 837px;
  }
  .tb-megamenu input.span10, textarea.span10, .uneditable-input.span10 {
    width: 756px;
  }
  .tb-megamenu input.span9, textarea.span9, .uneditable-input.span9 {
    width: 675px;
  }
  .tb-megamenu input.span8, textarea.span8, .uneditable-input.span8 {
    width: 594px;
  }
  .tb-megamenu input.span7, textarea.span7, .uneditable-input.span7 {
    width: 513px;
  }
  .tb-megamenu input.span6, textarea.span6, .uneditable-input.span6 {
    width: 432px;
  }
  .tb-megamenu input.span5, textarea.span5, .uneditable-input.span5 {
    width: 351px;
  }
  .tb-megamenu input.span4, textarea.span4, .uneditable-input.span4 {
    width: 270px;
  }
  .tb-megamenu input.span3, textarea.span3, .uneditable-input.span3 {
    width: 189px;
  }
  .tb-megamenu input.span2, textarea.span2, .uneditable-input.span2 {
    width: 108px;
  }
  .tb-megamenu input.span1, textarea.span1, .uneditable-input.span1 {
    width: 27px;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .tb-megamenu .offset-12 {
    margin-left: -736px;
  }
  .tb-megamenu .offset-11 {
    margin-left: -673px;
  }
  .tb-megamenu .offset-10 {
    margin-left: -610px;
  }
  .tb-megamenu .offset-9 {
    margin-left: -547px;
  }
  .tb-megamenu .offset-8 {
    margin-left: -484px;
  }
  .tb-megamenu .offset-7 {
    margin-left: -421px;
  }
  .tb-megamenu .offset-6 {
    margin-left: -358px;
  }
  .tb-megamenu .offset-5 {
    margin-left: -295px;
  }
  .tb-megamenu .offset-4 {
    margin-left: -232px;
  }
  .tb-megamenu .offset-3 {
    margin-left: -169px;
  }
  .tb-megamenu .offset-2 {
    margin-left: -106px;
  }
  .tb-megamenu .offset-1 {
    margin-left: -43px;
  }
}
@media (min-width: 980px) and (max-width: 1199px) {
  .tb-megamenu .offset-12 {
    margin-left: -932px;
  }
  .tb-megamenu .offset-11 {
    margin-left: -851px;
  }
  .tb-megamenu .offset-10 {
    margin-left: -770px;
  }
  .tb-megamenu .offset-9 {
    margin-left: -689px;
  }
  .tb-megamenu .offset-8 {
    margin-left: -608px;
  }
  .tb-megamenu .offset-7 {
    margin-left: -527px;
  }
  .tb-megamenu .offset-6 {
    margin-left: -446px;
  }
  .tb-megamenu .offset-5 {
    margin-left: -365px;
  }
  .tb-megamenu .offset-4 {
    margin-left: -284px;
  }
  .tb-megamenu .offset-3 {
    margin-left: -203px;
  }
  .tb-megamenu .offset-2 {
    margin-left: -122px;
  }
  .tb-megamenu .offset-1 {
    margin-left: -41px;
  }
}
@media (min-width: 1200px) {
  .tb-megamenu .offset-12 {
    margin-left: -1196px;
  }
  .tb-megamenu .offset-11 {
    margin-left: -1093px;
  }
  .tb-megamenu .offset-10 {
    margin-left: -990px;
  }
  .tb-megamenu .offset-9 {
    margin-left: -887px;
  }
  .tb-megamenu .offset-8 {
    margin-left: -784px;
  }
  .tb-megamenu .offset-7 {
    margin-left: -681px;
  }
  .tb-megamenu .offset-6 {
    margin-left: -578px;
  }
  .tb-megamenu .offset-5 {
    margin-left: -475px;
  }
  .tb-megamenu .offset-4 {
    margin-left: -372px;
  }
  .tb-megamenu .offset-3 {
    margin-left: -269px;
  }
  .tb-megamenu .offset-2 {
    margin-left: -166px;
  }
  .tb-megamenu .offset-1 {
    margin-left: -63px;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .tb-megamenu .row,
  .tb-megamenu .row-fluid {
    width: 100%;
    margin-left: 0;
    *zoom: 1;
  }
  .tb-megamenu .row:before,
  .tb-megamenu .row:after,
  .tb-megamenu .row-fluid:before,
  .tb-megamenu .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .tb-megamenu .row:after,
  .tb-megamenu .row-fluid:after {
    clear: both;
  }
  .tb-megamenu .row [class*="span"],
  .tb-megamenu .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.7173913043478%;
    *margin-left: 2.6637432356783%;
  }
  .row [class*="span"]:first-child:not(.pull-right),
  .row-fluid [class*="span"]:first-child:not(.pull-right) {
    margin-left: 0;
  }
  .row [class*="span"].pull-right:first-child + [class*="span"]:not(.pull-right),
  .row-fluid [class*="span"].pull-right:first-child + [class*="span"]:not(.pull-right) {
    margin-left: 0;
  }
  .tb-megamenu .row .span12,
  .tb-megamenu .row-fluid .span12 {
    width: 100%;
    *width: 99.94635193133%;
  }
  .tb-megamenu .row .span11,
  .tb-megamenu .row-fluid .span11 {
    width: 91.440217391304%;
    *width: 91.386569322635%;
  }
  .tb-megamenu .row .span10,
  .tb-megamenu .row-fluid .span10 {
    width: 82.880434782609%;
    *width: 82.826786713939%;
  }
  .tb-megamenu .row .span9,
  .tb-megamenu .row-fluid .span9 {
    width: 74.320652173913%;
    *width: 74.267004105244%;
  }
  .tb-megamenu .row .span8,
  .tb-megamenu .row-fluid .span8 {
    width: 65.760869565217%;
    *width: 65.707221496548%;
  }
  .tb-megamenu .row .span7,
  .tb-megamenu .row-fluid .span7 {
    width: 57.201086956522%;
    *width: 57.147438887852%;
  }
  .tb-megamenu .row .span6,
  .tb-megamenu .row-fluid .span6 {
    width: 48.641304347826%;
    *width: 48.587656279157%;
  }
  .tb-megamenu .row .span5,
  .tb-megamenu .row-fluid .span5 {
    width: 40.08152173913%;
    *width: 40.027873670461%;
  }
  .tb-megamenu .row .span4,
  .tb-megamenu .row-fluid .span4 {
    width: 31.521739130435%;
    *width: 31.468091061765%;
  }
  .tb-megamenu .row .span3,
  .tb-megamenu .row-fluid .span3 {
    width: 22.961956521739%;
    *width: 22.90830845307%;
  }
  .tb-megamenu .row .span2,
  .tb-megamenu .row-fluid .span2 {
    width: 14.402173913043%;
    *width: 14.348525844374%;
  }
  .tb-megamenu .row .span1,
  .tb-megamenu .row-fluid .span1 {
    width: 5.8423913043478%;
    *width: 5.7887432356783%;
  }
  .tb-megamenu .span12 .row [class*="span"] {
    margin-left: 2.7173913043478%;
    *margin-left: 2.6637432356783%;
  }
  .tb-megamenu .span12 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span12 .row .span12 {
    width: 100%;
    *width: 99.94635193133%;
  }
  .tb-megamenu .span12 .row .span11 {
    width: 91.440217391304%;
    *width: 91.386569322635%;
  }
  .tb-megamenu .span12 .row .span10 {
    width: 82.880434782609%;
    *width: 82.826786713939%;
  }
  .tb-megamenu .span12 .row .span9 {
    width: 74.320652173913%;
    *width: 74.267004105244%;
  }
  .tb-megamenu .span12 .row .span8 {
    width: 65.760869565217%;
    *width: 65.707221496548%;
  }
  .tb-megamenu .span12 .row .span7 {
    width: 57.201086956522%;
    *width: 57.147438887852%;
  }
  .tb-megamenu .span12 .row .span6 {
    width: 48.641304347826%;
    *width: 48.587656279157%;
  }
  .tb-megamenu .span12 .row .span5 {
    width: 40.08152173913%;
    *width: 40.027873670461%;
  }
  .tb-megamenu .span12 .row .span4 {
    width: 31.521739130435%;
    *width: 31.468091061765%;
  }
  .tb-megamenu .span12 .row .span3 {
    width: 22.961956521739%;
    *width: 22.90830845307%;
  }
  .tb-megamenu .span12 .row .span2 {
    width: 14.402173913043%;
    *width: 14.348525844374%;
  }
  .tb-megamenu .span12 .row .span1 {
    width: 5.8423913043478%;
    *width: 5.7887432356783%;
  }
  .tb-megamenu .span11 .row [class*="span"] {
    margin-left: 2.9717682020802%;
    *margin-left: 2.9181201334107%;
  }
  .tb-megamenu .span11 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span11 .row .span11 {
    width: 91.440217391304%;
    *width: 91.386569322635%;
  }
  .tb-megamenu .span11 .row .span10 {
    width: 82.880434782609%;
    *width: 82.826786713939%;
  }
  .tb-megamenu .span11 .row .span9 {
    width: 74.320652173913%;
    *width: 74.267004105244%;
  }
  .tb-megamenu .span11 .row .span8 {
    width: 65.760869565217%;
    *width: 65.707221496548%;
  }
  .tb-megamenu .span11 .row .span7 {
    width: 57.201086956522%;
    *width: 57.147438887852%;
  }
  .tb-megamenu .span11 .row .span6 {
    width: 48.641304347826%;
    *width: 48.587656279157%;
  }
  .tb-megamenu .span11 .row .span5 {
    width: 40.08152173913%;
    *width: 40.027873670461%;
  }
  .tb-megamenu .span11 .row .span4 {
    width: 31.521739130435%;
    *width: 31.468091061765%;
  }
  .tb-megamenu .span11 .row .span3 {
    width: 22.961956521739%;
    *width: 22.90830845307%;
  }
  .tb-megamenu .span11 .row .span2 {
    width: 14.402173913043%;
    *width: 14.348525844374%;
  }
  .tb-megamenu .span11 .row .span1 {
    width: 5.8423913043478%;
    *width: 5.7887432356783%;
  }
  .tb-megamenu .span10 .row [class*="span"] {
    margin-left: 3.2786885245902%;
    *margin-left: 3.2250404559206%;
  }
  .tb-megamenu .span10 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span10 .row .span10 {
    width: 90.638930163447%;
    *width: 90.585282094778%;
  }
  .tb-megamenu .span10 .row .span9 {
    width: 81.277860326894%;
    *width: 81.224212258225%;
  }
  .tb-megamenu .span10 .row .span8 {
    width: 71.916790490342%;
    *width: 71.863142421672%;
  }
  .tb-megamenu .span10 .row .span7 {
    width: 62.555720653789%;
    *width: 62.502072585119%;
  }
  .tb-megamenu .span10 .row .span6 {
    width: 53.194650817236%;
    *width: 53.141002748567%;
  }
  .tb-megamenu .span10 .row .span5 {
    width: 43.833580980684%;
    *width: 43.779932912014%;
  }
  .tb-megamenu .span10 .row .span4 {
    width: 34.472511144131%;
    *width: 34.418863075461%;
  }
  .tb-megamenu .span10 .row .span3 {
    width: 25.111441307578%;
    *width: 25.057793238908%;
  }
  .tb-megamenu .span10 .row .span2 {
    width: 15.750371471025%;
    *width: 15.696723402356%;
  }
  .tb-megamenu .span10 .row .span1 {
    width: 6.3893016344725%;
    *width: 6.335653565803%;
  }
  .tb-megamenu .span9 .row [class*="span"] {
    margin-left: 3.6563071297989%;
    *margin-left: 3.6026590611294%;
  }
  .tb-megamenu .span9 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span9 .row .span9 {
    width: 89.672131147541%;
    *width: 89.618483078871%;
  }
  .tb-megamenu .span9 .row .span8 {
    width: 79.344262295082%;
    *width: 79.290614226412%;
  }
  .tb-megamenu .span9 .row .span7 {
    width: 69.016393442623%;
    *width: 68.962745373953%;
  }
  .tb-megamenu .span9 .row .span6 {
    width: 58.688524590164%;
    *width: 58.634876521494%;
  }
  .tb-megamenu .span9 .row .span5 {
    width: 48.360655737705%;
    *width: 48.307007669035%;
  }
  .tb-megamenu .span9 .row .span4 {
    width: 38.032786885246%;
    *width: 37.979138816576%;
  }
  .tb-megamenu .span9 .row .span3 {
    width: 27.704918032787%;
    *width: 27.651269964117%;
  }
  .tb-megamenu .span9 .row .span2 {
    width: 17.377049180328%;
    *width: 17.323401111658%;
  }
  .tb-megamenu .span9 .row .span1 {
    width: 7.0491803278689%;
    *width: 6.9955322591993%;
  }
  .tb-megamenu .span8 .row [class*="span"] {
    margin-left: 4.1322314049587%;
    *margin-left: 4.0785833362892%;
  }
  .tb-megamenu .span8 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span8 .row .span8 {
    width: 88.482632541133%;
    *width: 88.428984472464%;
  }
  .tb-megamenu .span8 .row .span7 {
    width: 76.965265082267%;
    *width: 76.911617013597%;
  }
  .tb-megamenu .span8 .row .span6 {
    width: 65.4478976234%;
    *width: 65.394249554731%;
  }
  .tb-megamenu .span8 .row .span5 {
    width: 53.930530164534%;
    *width: 53.876882095864%;
  }
  .tb-megamenu .span8 .row .span4 {
    width: 42.413162705667%;
    *width: 42.359514636998%;
  }
  .tb-megamenu .span8 .row .span3 {
    width: 30.895795246801%;
    *width: 30.842147178131%;
  }
  .tb-megamenu .span8 .row .span2 {
    width: 19.378427787934%;
    *width: 19.324779719265%;
  }
  .tb-megamenu .span8 .row .span1 {
    width: 7.8610603290676%;
    *width: 7.8074122603981%;
  }
  .tb-megamenu .span7 .row [class*="span"] {
    margin-left: 4.750593824228%;
    *margin-left: 4.6969457555585%;
  }
  .tb-megamenu .span7 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span7 .row .span7 {
    width: 86.98347107438%;
    *width: 86.929823005711%;
  }
  .tb-megamenu .span7 .row .span6 {
    width: 73.96694214876%;
    *width: 73.913294080091%;
  }
  .tb-megamenu .span7 .row .span5 {
    width: 60.950413223141%;
    *width: 60.896765154471%;
  }
  .tb-megamenu .span7 .row .span4 {
    width: 47.933884297521%;
    *width: 47.880236228851%;
  }
  .tb-megamenu .span7 .row .span3 {
    width: 34.917355371901%;
    *width: 34.863707303231%;
  }
  .tb-megamenu .span7 .row .span2 {
    width: 21.900826446281%;
    *width: 21.847178377611%;
  }
  .tb-megamenu .span7 .row .span1 {
    width: 8.8842975206612%;
    *width: 8.8306494519916%;
  }
  .tb-megamenu .span6 .row [class*="span"] {
    margin-left: 5.586592178771%;
    *margin-left: 5.5329441101014%;
  }
  .tb-megamenu .span6 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span6 .row .span6 {
    width: 85.035629453682%;
    *width: 84.981981385012%;
  }
  .tb-megamenu .span6 .row .span5 {
    width: 70.071258907363%;
    *width: 70.017610838694%;
  }
  .tb-megamenu .span6 .row .span4 {
    width: 55.106888361045%;
    *width: 55.053240292376%;
  }
  .tb-megamenu .span6 .row .span3 {
    width: 40.142517814727%;
    *width: 40.088869746057%;
  }
  .tb-megamenu .span6 .row .span2 {
    width: 25.178147268409%;
    *width: 25.124499199739%;
  }
  .tb-megamenu .span6 .row .span1 {
    width: 10.21377672209%;
    *width: 10.160128653421%;
  }
  .tb-megamenu .span5 .row [class*="span"] {
    margin-left: 6.7796610169492%;
    *margin-left: 6.7260129482796%;
  }
  .tb-megamenu .span5 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span5 .row .span5 {
    width: 82.402234636872%;
    *width: 82.348586568202%;
  }
  .tb-megamenu .span5 .row .span4 {
    width: 64.804469273743%;
    *width: 64.750821205073%;
  }
  .tb-megamenu .span5 .row .span3 {
    width: 47.206703910615%;
    *width: 47.153055841945%;
  }
  .tb-megamenu .span5 .row .span2 {
    width: 29.608938547486%;
    *width: 29.555290478817%;
  }
  .tb-megamenu .span5 .row .span1 {
    width: 12.011173184358%;
    *width: 11.957525115688%;
  }
  .tb-megamenu .span4 .row [class*="span"] {
    margin-left: 8.6206896551724%;
    *margin-left: 8.5670415865029%;
  }
  .tb-megamenu .span4 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span4 .row .span4 {
    width: 78.64406779661%;
    *width: 78.590419727941%;
  }
  .tb-megamenu .span4 .row .span3 {
    width: 57.28813559322%;
    *width: 57.234487524551%;
  }
  .tb-megamenu .span4 .row .span2 {
    width: 35.932203389831%;
    *width: 35.878555321161%;
  }
  .tb-megamenu .span4 .row .span1 {
    width: 14.576271186441%;
    *width: 14.522623117771%;
  }
  .tb-megamenu .span3 .row [class*="span"] {
    margin-left: 11.834319526627%;
    *margin-left: 11.780671457958%;
  }
  .tb-megamenu .span3 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span3 .row .span3 {
    width: 72.844827586207%;
    *width: 72.791179517537%;
  }
  .tb-megamenu .span3 .row .span2 {
    width: 45.689655172414%;
    *width: 45.636007103744%;
  }
  .tb-megamenu .span3 .row .span1 {
    width: 18.534482758621%;
    *width: 18.480834689951%;
  }
  .tb-megamenu .span2 .row [class*="span"] {
    margin-left: 18.867924528302%;
    *margin-left: 18.814276459632%;
  }
  .tb-megamenu .span2 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span2 .row .span2 {
    width: 62.721893491124%;
    *width: 62.668245422455%;
  }
  .tb-megamenu .span2 .row .span1 {
    width: 25.443786982249%;
    *width: 25.390138913579%;
  }
  .tb-megamenu .span1 .row [class*="span"] {
    margin-left: 46.511627906977%;
    *margin-left: 46.457979838307%;
  }
  .tb-megamenu .span1 .row [class*="span"]:first-child {
    margin-left: 0;
  }
  .tb-megamenu .span1 .row .span1 {
    width: 40.566037735849%;
    *width: 40.51238966718%;
  }
  .tb-megamenu .spanfirst {
    margin-left: 0 !important;
    clear: left;
  }
}
.tb-megamenu .row .span50,
.tb-megamenu .row-fluid .span50 {
  width: 50%;
  float: left;
}
.tb-megamenu .row .span33,
.tb-megamenu .row-fluid .span33 {
  width: 33.3333%;
  float: left;
}
.tb-megamenu .row .span25,
.tb-megamenu .row-fluid .span25 {
  width: 25%;
  float: left;
}
.tb-megamenu .row .span20,
.tb-megamenu .row-fluid .span20 {
  width: 20%;
  float: left;
}
.tb-megamenu .row .span16,
.tb-megamenu .row-fluid .span16 {
  width: 16.6666%;
  float: left;
}
.tb-megamenu .hidden {
  display: none !important;
  visibility: hidden;
}

@media (max-width: 979px) {
  .tb-megamenu .always-show .mega > .mega-dropdown-menu,
  .tb-megamenu .always-show .dropdown-menu {
    display: block !important;
  }
  .tb-megamenu .navbar-collapse-fixed-top,
  .tb-megamenu .navbar-collapse-fixed-bottom {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  .tb-megamenu .navbar-collapse-fixed-top .nav-collapse.in,
  .tb-megamenu .navbar-collapse-fixed-bottom .nav-collapse.in {
    position: fixed;
    width: 100%;
    overflow-y: auto;
    top: 40px;
    bottom: 0;
  }
  .tb-megamenu .navbar-collapse-fixed-top .nav-collapse.in > *,
  .tb-megamenu .navbar-collapse-fixed-bottom .nav-collapse.in > * {
    padding-bottom: 50px;
  }
  .tb-megamenu .navbar-collapse-fixed-bottom {
    bottom: 0;
    top: auto;
  }
  .tb-megamenu .navbar-collapse-fixed-bottom .nav-collapse.in {
    top: 0;
    bottom: 40px;
  }
}
@media (max-width: 979px) {
  .tb-megamenu {
    background-color: inherit;
    height: auto;
  }
  .tb-megamenu .btn-navbar {
    display: block;
    margin-bottom: 10px;
  }
  .tb-megamenu .btn-navbar:hover {
    cursor: pointer;
  }
  .tb-megamenu .nav-collapse {
    background: #000;
    margin-top: 10px;
    position: absolute;
    top: 27px;
    width: 100%;
    z-index: 999;
  }
  .tb-megamenu .nav-collapse .nav {
    margin: 0;
  }
  .tb-megamenu .nav-collapse .nav > li > a {
    font-size: 15px;
    margin-bottom: 0;
  }
  .tb-megamenu .nav-collapse .nav > li > a,
  .tb-megamenu .nav-collapse .dropdown-menu a {
    border-bottom: 1px solid #222;
    border-right: none;
    color: #fff;
    text-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .tb-megamenu .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 0;
  }
  .tb-megamenu .nav-collapse .nav > li > a:hover,
  .tb-megamenu .nav-collapse .nav > li > a:focus {
    background-color: #fff;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    color: #e54e4b;
  }
  .tb-megamenu .nav-collapse .nav > li.active > a:hover,
  .tb-megamenu .nav-collapse .nav > li.active > a:focus {
    background-color: #e54e4b;
  }
  .tb-megamenu .nav-collapse .dropdown-menu a {
    border-top: 1px solid #222;
    border-bottom: 0;
    font-weight: normal;
    padding: 10px 20px;
  }
  .tb-megamenu .nav-collapse .dropdown-menu a:hover,
  .tb-megamenu .nav-collapse .dropdown-menu a:focus {
    background-color: inherit;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  }
  .tb-megamenu .nav-collapse .dropdown-menu {
    margin: 0;
    padding: 0;
    background-color: #000;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100% !important;
  }
  .tb-megamenu.navbar-collapse-fixed-top {
    margin-left: 0;
    margin-right: 0;
  }
  .tb-megamenu .navbar .nav > li > .dropdown-menu:before,
  .tb-megamenu .navbar .nav > li > .dropdown-menu:after,
  .tb-megamenu .navbar .nav > li > .dropdown-menu .divider {
    display: none;
  }
  .tb-megamenu .customization {
    display: none;
  }
  .tb-megamenu .tb-block .block-title {
    color: #fff;
  }
}

@media (min-width: 980px) {
  .tb-megamenu.animate .mega > .mega-dropdown-menu {
    transition: all 400ms;
    -webkit-transition: all 400ms;
    -ms-transition: all 400ms;
    -o-transition: all 400ms;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
  }
  .tb-megamenu.animate .mega.animating > .mega-dropdown-menu {
    display: block!important;
  }
  .tb-megamenu.animate .mega.open > .mega-dropdown-menu,
  .tb-megamenu.animate .mega.animating.open > .mega-dropdown-menu {
    opacity: 1;
  }
  .tb-megamenu.animate.zoom .mega > .mega-dropdown-menu {
    transform: scale(0, 0);
    transform-origin: 20% 20%;
    -webkit-transform: scale(0, 0);
    -webkit-transform-origin: 20% 20%;
    -ms-transform: scale(0, 0);
    -ms-transform-origin: 20% 20%;
    -o-transform: scale(0, 0);
    -o-transform-origin: 20% 20%;
  }
  .tb-megamenu.animate.zoom .mega.open > .mega-dropdown-menu {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
  }
  .tb-megamenu.animate.elastic .level0 > .mega > .mega-dropdown-menu {
    transform: scale(1, 0);
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -o-transform: scale(1, 0);
  }
  .tb-megamenu.animate.elastic .mega > .mega-dropdown-menu {
    transform: scale(0, 1);
    transform-origin: 10% 0;
    -webkit-transform: scale(0, 1);
    -webkit-transform-origin: 10% 0;
    -ms-transform: scale(0, 1);
    -ms-transform-origin: 10% 0;
    -o-transform: scale(0, 1);
    -o-transform-origin: 10% 0;
  }
  .tb-megamenu.animate.elastic .mega.open > .mega-dropdown-menu {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
  }
  .tb-megamenu.animate.slide .mega.animating > .mega-dropdown-menu {
    overflow: hidden;
  }
  .tb-megamenu.animate.slide .mega > .mega-dropdown-menu > div {
    transition: all 400ms;
    -webkit-transition: all 400ms;
    -ms-transition: all 400ms;
    -o-transition: all 400ms;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-top: -100%;
  }
  .tb-megamenu.animate.slide .mega.open > .mega-dropdown-menu > div {
    margin-top: 0%;
  }
  .tb-megamenu.animate.slide .mega .mega > .mega-dropdown-menu {
    min-width: 0;
  }
  .tb-megamenu.animate.slide .mega .mega > .mega-dropdown-menu > div {
    min-width: 200px;
    margin-top: 0;
    margin-left: -500px;
  }
  .tb-megamenu.animate.slide .mega .mega.open > .mega-dropdown-menu > div {
    margin-left: 0;
  }
}

@media (max-width: 979px) {
  .tb-megamenu .mega-inner {
    padding: 10px 20px;
  }
  .tb-megamenu .row-fluid,
  .tb-megamenu .mega-dropdown-menu,
  .tb-megamenu .row-fluid [class*="span"] {
    width: 100% !important;
    min-width: 100% !important;
    left: 0 !important;
    margin-left: 0 !important;
    transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
  }
  .tb-megamenu .row-fluid + .row-fluid {
    padding-top: 10px;
    border-top: 1px solid #222;
  }
  .tb-megamenu .hidden-collapse,
  .tb-megamenu .always-show  .caret,
  .tb-megamenu .sub-hidden-collapse > .nav-child,
  .tb-megamenu .sub-hidden-collapse .caret,
  .tb-megamenu .sub-hidden-collapse > a:after,
  .tb-megamenu .always-show .dropdown-submenu > a:after {
    display: none !important;
  }
  .tb-megamenu .mega-caption {
    display: none !important;
  }
  .tb-megamenu .mega-nav > li a,
  .tb-megamenu .dropdown-menu .mega-nav > li a {
    padding: 5px 0;
    margin-left: 20px;
  }
  .tb-megamenu .tb-block {
    margin-bottom: 0;
  }
}

.block-view-demo .views-field-title a {
  border-top: none !important;
  color: #eee;
  padding: 0 !important;
}

.tb-megamenu .block-view-demo .views-field-field-image {
  float: left;
}

.tb-megamenu .block-view-demo .views-field-field-image img {
  border: 1px solid #333;
  margin: 0 20px 0 0;
  max-width: none !important;
  padding: 4px;
  width: auto;
}

.tb-megamenu .block-view-demo .item-list ul li {
  border-bottom: 1px solid #333;
  margin: 0;
  min-height: 60px;
  padding: 10px 0;
}

.tb-megamenu .block-view-demo .item-list ul li.views-row-first {
  padding-top: 0;
}

.tb-megamenu .block-view-demo a {
  padding: 0 !important;
}

.tb-megamenu .block-view-demo .views-field-title a {
  border-top: none;
  color: #aaa;
  padding: 0;
}

.tb-megamenu .block-view-demo a:hover,
.tb-megamenu .block-view-demo a:focus {
  background: none !important;
  border: none !important;
  color: #e54e4b;
  text-decoration: none;
}
