
// FONTS
$gotham: 'Montserrat', sans-serif;
/*$gotham: 'Gotham', sans-serif;*/
$gothamBook: Arial, Helvetica, sans-serif;
/*$gothamBook: 'Gotham Book', sans-serif;*/

// GRIDS
$mainContainer: 1200px;
$sliderContainer: 1330px;
$contentContainer: 840px;

// COLORS
$gray: #393837;
$whiteGray: #EDEDED;

$blue: #046FCA;
$purple: #69009B;

// MIXINS
@mixin reset-input($fontSize, $background, $color) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 10px;
  opacity: 1;
  border: none;
  background: $background !important;
  font-size: $fontSize;
  color: $color;
  &::-webkit-input-placeholder {
    color: $color;
    opacity: 1;
    caret-color: $color;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
    caret-color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
    caret-color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
    opacity: 1;
    caret-color: $color;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

// font mixins
@mixin font-size($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

footer {
  background: #393837;
  padding: 60px 0 35px;
  font-family: $gotham;
  color: #FFFFFF;
  a, p, label {
    color: #FFFFFF;
    &:hover,
    &:focus {
      color: #FFFFFF;
    }
  }
  a, p {
    font-family: Arial, Helvetica, sans-serif;
  }
  a {
    text-decoration: none;
  }
  .container {
    height: 100%;
  }
}

.footer {
  &-logo {
    padding-left: 25px;
    width: 16.25rem;
    height: auto;
  }
  &-contact {
    //padding-top: 20px;
    a {
      @include font-size(16px);
      font-weight: 500;
      padding-bottom: 1px;
      text-transform: capitalize;
      border-bottom: 1px solid #FFF;
      -webkit-transition: all 200ms;
      -moz-transition: all 200ms;
      -ms-transition: all 200ms;
      -o-transition: all 200ms;
      transition: all 200ms;
      &:hover,
      &:focus {
        border-bottom-width: 3px;
      }
      &[href^='tel:'] {
        border: none;
        @include font-size(32px);
        font-weight: 600;
        &:hover {
          color: inherit;
        }
      }
    }
    p {
      @include font-size(18px);
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
  &-signup {
    position: relative;
    form {
      position: relative;
    }
    label {
      display: inline-block;
      @include font-size(18px);
      font-weight: 500;
      margin-bottom: 10px;
    }
    input {
      &#newsletter-signup {
        width: 270px;
        height: 31px;
        @include reset-input(16px, #FFFFFF, #393837);
        margin-right: 15px;
        border: 2px solid #FFF;
        &.error {
          border-color: #B30000;
        }
      }
      &[type="submit"] {
        width: 20px;
        height: 35px;
        margin: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        margin: 0;
        opacity: 1;
        border: none;
        background: transparent url("images/arrow-signup.svg") no-repeat center;
        background-size: 16px 23px;
        cursor: pointer;
        right: 0;
        vertical-align: middle;
      }
    }
  }
  &-links {
    padding-right: 25px;
    ul {
      list-style: none;
      padding: 0;
      li {
        display: block;
        margin-bottom: 13px;
        &:last-of-type {
          margin-bottom: 0;
        }
        a {
          @include font-size(16px);
          font-weight: 500;
          padding-bottom: 1px;
          text-transform: capitalize;
          border-bottom: 1px solid #ffffff;
          -webkit-transition: all 200ms;
          -moz-transition: all 200ms;
          -ms-transition: all 200ms;
          -o-transition: all 200ms;
          transition: all 200ms;
          &:hover,
          &:focus,
          &.active {
            color: inherit;
            border-bottom-width: 3px;
          }
        }
      }
    }
  }
  &-social {
    margin-top: 0.9375rem;
    padding-left: 1.5625rem;
    display: block;
    .content {
      a {
        width: 1.875rem;
        height: 1.875rem;
        display: inline-block;
        margin-right: 0.625rem;
        &:last-of-type {
          margin-right: 0;
        }
        &.facebook {
          background: url("images/facebook.svg") no-repeat center;
          -webkit-background-size: 1.875rem 1.875rem;
          background-size: 1.875rem 1.875rem;
        }
        &.twitter {
          background: url("images/twitter.svg") no-repeat center;
          -webkit-background-size: 1.875rem 1.875rem;
          background-size: 1.875rem 1.875rem;
        }
        &.linkedin {
          background: url("images/linkedin.svg") no-repeat center;
          -webkit-background-size: 1.875rem 1.875rem;
          background-size: 1.875rem 1.875rem;
        }
      }
    }
  }
  &-info {
    @include font-size(16px);
    font-weight: 500;
    display: block;
    line-height: 1.2;
    p {
      margin: 0;
    }
  }
}

#footerTop {
  .col-three {
    max-width: 33.33333333333333%;
    width: auto;
  }
  & > .container {
    padding: 0 25px;
  }
  .flex-section {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    -ms-align-items: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}


#footerBottom {
  & > .container {
    &:first-of-type {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      padding-top: 30px;
      margin-top: 60px;
    }
    .footer-bottom-links {
      ul.menu {
        margin: 0 0 1.25rem;
        display: block;
        padding: 0;
        li {
          list-style: none;
          display: inline-block;
          margin: 0;
          padding: 0 0.5em 0 0;
          border-right: 1px solid #FFF;
          margin-right: 0.5em;
          &:last-of-type {
            border-right: none;
            a {
              margin-right: 0;
            }
          }
          a {
            @include font-size(16px);
            font-weight: 500;
            display: inline;
            padding-bottom: 2px;
            border-bottom: 1px solid #ffffff;
            -webkit-transition: all 200ms;
            -moz-transition: all 200ms;
            -ms-transition: all 200ms;
            -o-transition: all 200ms;
            transition: all 200ms;
            &:hover,
            &:focus,
            &.active {
              color: inherit;
              border-bottom-width: 3px;
            }
          }
        }
      }
    }
  }
}

.email-error-text {
  margin-top: 10px;
  background-color: #B30000;
  font-family: $gothamBook;
  padding: 10px;
  display: none;
  &.visible {
    display: inline-block;
  }
}

@media screen and (max-width: 78.75rem) {
  #abilityFooter {
    padding: 50px 0 30px;
    .container {
      margin: 0 25px;
      max-width: unset;
    }
  }
}

@media screen and (max-width: 64rem) {
  .footer {
    &-logo,
    &-social {
      padding-left: 0;
    }
    &-links {
      padding-right: 0;
    }
  }
  #abilityFooter {
    .container {
      margin: 0 25px;
    }
  }
  #footerTop {
    .container {
      margin: 0;
    }
    .flex-section {
      display: block;
      & > div {
        display: block;
        width: 100%;
        max-width: unset;
        margin: 0;
      }
    }
  }
  #footerBottom {
    & > .container {
      &:first-of-type {
        margin-top: 20px;
      }
    }
  }
  .footer-signup {
    input#newsletter-signup {
      width: 14.8125rem;
    }
  }
}

@media screen and (max-width: 53.75rem) {
  #footerBottom {
    & > .container {
      .footer-bottom-links {
        ul.menu {
          li {
            display: block;
            margin-right: 0;
            margin-bottom: 5px;
            padding-right: 0;
            border: 0;
            a {
              margin: 0 20px 0 0;
              &:hover {
                border-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  #footerTop {
    .col-three:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 47.9375rem) {
  #footerTop {
    .flex-section {
      & > div {
        margin-bottom: 40px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  #footerBottom {
    & > .container {
      .footer-bottom-links {
        ul.menu {
          li {
            a {
              //font-size: 12px;
              margin: 0 15px 0 0;
            }
          }
        }
      }
    }
  }
  .footer-links {
    ul {
      li {
        margin-left: 0;
      }
    }
  }
  .footer-signup {
    label {
      display: block;
    }
  }
}

@media screen and (max-width: 42.5rem) {
  footer {
    .container {
      height: auto;
    }
  }
  #footerBottom > .container .footer-bottom-links ul.menu {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 22.5rem) {
  #footerTop {
    .flex-section {
      align-items: flex-start;
      & > div {
        width: 100%;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .footer {
    &-signup {
      label {
        @include font-size(16px);
      }
      input {
        &#newsletter-signup {
          width: 220px;
        }
      }
    }
    &-contact {
      a {
        &[href^='tel:'] {
          @include font-size(20px);
        }
      }
      p {
        @include font-size(16px);
      }
    }
  }
}

@media screen and (max-width: 20rem) {
  .footer-signup input#newsletter-signup {
    width: 180px;
  }
}
