@import './colors';
@import './font-face';
@import './fonts';
@import './grid';
@import './mixins';
@import './placeholder-classes';

// include fonts
@include font-face('HK Grotesk', '../../fonts/HKGrotesk/Regular/HKGrotesk-Regular', normal);
@include font-face('HK Grotesk', '../../fonts/HKGrotesk/RegularItalic/HKGrotesk-Italic', normal, italic);
@include font-face('HK Grotesk', '../../fonts/HKGrotesk/MediumItalic/HKGrotesk-MediumItalic', 500, italic);
@include font-face('HK Grotesk', '../../fonts/HKGrotesk/Light/HKGrotesk-Light', 300);
@include font-face('HK Grotesk', '../../fonts/HKGrotesk/Bold/HKGrotesk-Bold', bold);
