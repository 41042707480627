@import '../../../../../src/assets/scss/settings/settings';

* {
  font-family: font(Montserrat), sans-serif;
}

html {
  overflow-x: hidden;
}

button {
  @include reset-button;
}

.container {
  max-width: rem(1204px);
  margin: 0 auto;
  &-inline {
    div {
      display: inline;
    }
  }
}

.erom-search-banner-block {
  position: relative;
  background-color: color(darkTeal, .05);
  &.fixed-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
    .erom-search-banner-block--container {
      padding-top: rem(30px);
      padding-bottom: rem(30px);
      .article-logo,
      & > h1,
      & > p {
        display: none;
      }
    }
    .article-logo {
      &__link {
        top: rem(30px);
      }
      &__cta {
        display: none;
      }
    }
  }
  h1 {
    max-width: rem(800px);
    color: color(black);
    font-family: font(Montserrat), sans-serif;
    font-weight: 600;
    font-size: rem(28px);
    line-height: rem(34px);
    margin-bottom: rem(20px);
  }
  p {
    max-width: rem(800px);
    color: color(black);
    font-family: font(Montserrat), sans-serif;
    font-weight: 500;
    font-size: rem(16px);
    line-height: rem(24px);
    margin-bottom: rem(50px);
  }
  &--container {
    position: relative;
    padding: rem(70px) rem(50px) rem(50px);
    max-width: rem(1304px);
    margin: 0 auto;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: rem(20px);
      width: rem(1px);
      height: 100%;
      background-color: color(white);
    }
  }
  &--search {
    position: relative;
    width: 100%;
    max-width: rem(550px);
    &:hover,
    &:focus-within {
      -webkit-box-shadow: 0 0 rem(30px) 0 #005C6E1A;
      -moz-box-shadow: 0 0 rem(30px) 0 #005C6E1A;
      box-shadow: 0 0 rem(30px) 0 #005C6E1A;
      input {
        border-color: color(darkTeal);
      }
      .erom-search-banner-block--search-icon {
        background-color: color(darkTeal);
      }
    }
    input {
      @include reset-input;
      color: color(black);
      font-size: rem(16px);
      line-height: rem(24px);
      font-family: font(Montserrat), sans-serif;
      font-weight: 500;
      width: 100%;
      height: rem(56px);
      background-color: color(white);
      border: rem(1px) solid color(darkTeal, .7);
      padding: 0 rem(60px) 0 rem(20px);
      z-index: 11;
      position: relative;
      @include input-placeholder-color(color(black), normal);
      &:not(.empty) {
        padding-right: rem(120px);
      }
    }
    &-listbox {
      display: block;
      width: 100%;
      padding: rem(22px) 0;
      margin: 0;
      list-style: none;
      max-height: rem(246px);
      overflow: auto;
      &__container {
        position: absolute;
        background-color: color(white);
        width: 100%;
        top: 100%;
        left: 0;
        z-index: 10;
        box-sizing: border-box;
        border: rem(1px) solid color(darkTeal);
        border-top: none;
        -webkit-box-shadow: 0 0 rem(30px) 0 #005C6E12;
        -moz-box-shadow: 0 0 rem(30px) 0 #005C6E12;
        box-shadow: 0 0 rem(30px) 0 #005C6E1A;
      }
      li {
        color: color(black);
        font-size: rem(16px);
        line-height: rem(24px);
        font-family: font(Montserrat), sans-serif;
        font-weight: 500;
        padding: rem(10px) rem(20px);
        cursor: pointer;
        &:hover,
        &[aria-selected="true"] {
          color: color(white);
          background-color: color(darkTeal);
          mark {
            color: color(white);
            background-color: transparent;
          }
        }
      }
    }
    &-reset {
      @include reset-button;
      position: absolute;
      top: rem(10px);
      right: rem(56px);
      z-index: 12;
      height: rem(34px);
      padding: 0 rem(20px);
      border-left: rem(1px) solid color(darkTeal, .3);
      svg {
        display: block;
        font-size: rem(24px);
        width: rem(24px);
        height: rem(24px);
        max-width: rem(15px);
        color: color(darkTeal, .7);
      }
      &:hover,
      &:focus {
        svg {
          color: color(darkTeal);
        }
      }
    }
    &-icon {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: rem(56px);
      height: rem(56px);
      color: color(white);
      background-color: color(darkTeal, .7);
      padding: rem(15px);
      z-index: 12;
      svg {
        font-size: rem(24px);
      }
    }
  }
  &--mobile-filters {
    display: none;
  }
}

.homepage-content-area {
  position: relative;
  max-width: rem(1304px);
  margin: 0 auto;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: rem(20px);
    top: 0;
    height: 100%;
    width: rem(1px);
    background-color: #EBEBEB;
  }
}

.erom-category-filters-block {
  display: block;
  margin-top: rem(20px);
  position: relative;
  &.fixed-filters {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    max-width: unset;
    background-color: color(white);
    -webkit-box-shadow: 0 0 rem(30px) 0 #005C6E1A;
    -moz-box-shadow: 0 0 rem(30px) 0 #005C6E1A;
    box-shadow: 0 0 rem(30px) 0 #005C6E1A;
    .erom-category-filters-block {
      &__title {
        @include flex-box;
      }
      &__filters {

      }
      &__area {
        border: none;
      }
      &__content {
        position: relative;
        max-width: rem(1304px);
        width: 100%;
        margin: 0 auto;
        padding: rem(20px) rem(50px);
        & > div {
          &:not(.erom-category-filters-block__title) {
            display: inline-block;
          }
        }
      }
      &__cta {
        display: block;
      }
      &__filters {
        &--reset {
          display: none;
        }
      }
    }
    .erom-select-multiple-selected {
      display: none;
    }
  }
  &__area {
    &.active-filters {
      border-bottom: rem(1px) solid #B2CED3;
    }
  }
  &__filters {
    @include flex-box;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin: 0 negativeRem(15px);
    erom-select {
      width: 100%;
      display: block;
    }
    .erom-select {
      &-native {
        label {
          color: color(darkTeal) !important;
        }
      }
    }
    & > div {
      @include flex-box;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      max-width: rem(195px);
      padding: 0 rem(15px);
      width: 100%;
      &:not(.navigator-filters) {
        .erom-select {
          margin-top: rem(12px);
          border-bottom: rem(1px) solid #B2CED3 !important;
          &-list_wrap {
            &_overflow {
              min-width: rem(300px);
            }
          }
          &-placeholder {
            &.has-label {
              label {
                font-size: rem(16px) !important;
                color: color(darkTeal);
                font-family: font(Montserrat), sans-serif;
                font-weight: 500;
                left: rem(10px) !important;
                margin-top: rem(3px);
              }
              input {
                color: color(black) !important;
                font-family: font(Montserrat), sans-serif !important;
                font-weight: 500 !important;
                padding-left: rem(10px) !important;
                padding-bottom: rem(10px) !important;
                padding-top: rem(18px) !important;
              }
            }
          }
          &.active {
            .erom-select {
              &-placeholder {
                &.has-label {
                  label {
                    margin-top: negativeRem(18px) !important;
                    font-size: rem(14px) !important;
                  }
                }
              }
            }
          }
        }
      }
      .erom-select {
        &-placeholder {
          input[type="text"] {
            padding-right: rem(20px) !important;
          }
          .text-placeholder {
            margin-top: rem(10px);
          }
          &.has-label {
            label {
              font-size: rem(16px) !important;
              color: color(darkTeal);
              font-family: font(Montserrat), sans-serif;
              font-weight: 500;
              left: rem(10px) !important;
              margin-top: rem(3px);
            }
            input {
              color: color(black) !important;
              font-family: font(Montserrat), sans-serif !important;
              font-weight: 500 !important;
              padding-left: rem(10px) !important;
              padding-bottom: rem(10px) !important;
              padding-top: rem(18px) !important;
            }
          }
        }
        &.active {
          .erom-select {
            &-arrow {
              top: rem(22px) !important;
              margin-top: unset !important;
              // transform: none !important;
            }
          }
        }
        &-arrow {
          right: rem(10px) !important;
          margin-top: negativeRem(3px) !important;
        }
      }
      &.navigator-filters {
        @include flex-box;
        max-width: unset;
        position: relative;
        erom-select {
          width: 100%;
          &:nth-of-type(1) {
            border-right: rem(.5px) solid color(white);
          }
          &:nth-of-type(2) {
            border-left: rem(.5px) solid color(white);
          }
        }
        .erom-select {
          font-family: font(Montserrat), sans-serif !important;
          font-weight: 500 !important;
          color: color(black) !important;
          &:not(.native) {
            height: rem(62px) !important;
          }
          &-native {
            select {
              height: rem(55px) !important;
              background-color: #F7F7F7 !important;
            }
          }
          &-face {
            border-radius: 0 !important;
            &.type_gray {
              background-color: #F7F7F7 !important;
            }
          }
          &-arrow {
            color: color(darkTeal, .7) !important;
          }
          &-list_wrap {
            &.type_gray {
              background-color: #F7F7F7 !important;
            }
            &_overflow {
              left: negativeRem(20px) !important;
              padding-left: rem(20px) !important;
              padding-right: rem(20px) !important;
              padding-bottom: rem(20px) !important;
              width: -moz-calc(100% + #{rem(40px)}) !important;
              width: -webkit-calc(100% + #{rem(40px)}) !important;
              width: calc(100% + #{rem(40px)}) !important;
            }
          }
          &-placeholder {
            &.has-label {
              label {
                font-size: rem(16px) !important;
                color: color(darkTeal);
                font-family: font(Montserrat), sans-serif;
                font-weight: 500;
                left: rem(10px) !important;
              }
              input {
                color: color(black) !important;
                font-family: font(Montserrat), sans-serif !important;
                font-weight: 500 !important;
                padding-left: rem(10px) !important;
              }
            }
          }
          &.selected {
            .erom-select {
              &-placeholder {
                &.has-label {
                  label {
                    font-size: rem(14px) !important;
                  }
                }
              }
              &-arrow {
                top: rem(32px) !important;
                margin-top: unset !important;
                // transform: none !important;
              }
            }
          }
          &.active {
            .erom-select {
              &-arrow {
                color: color(black) !important;
              }
              &-list_wrap_overflow {
                background-color: color(white) !important;
              }
            }
            .erom-select {
              &-placeholder {
                &.has-label {
                  label {
                    margin-top: negativeRem(12px) !important;
                  }
                }
              }
            }
          }
          &:hover {
            .erom-select {
              &-arrow {
                color: color(darkTeal) !important;
              }
            }
          }
        }
        .navigator-filters {
          &__confirmed {
            @include flex-box;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            color: color(black);
            font-size: rem(16px);
            line-height: rem(24px);
            font-family: font(Montserrat), sans-serif;
            font-weight: 500;
            padding: rem(16px);
            background-color: color(white);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            svg {
              color: #4CBFB1;
              width: rem(20px);
              min-width: rem(20px);
              height: rem(21px);
              margin-right: rem(10px);
            }
            button {
              @include reset-button;
            }
          }
        }
      }
    }
    .erom-select {
      &:not(.native) {
        height: rem(50px) !important;
      }
      &-face {
        background-color: transparent !important;
        border-radius: 0 !important;
      }
      &-arrow {
        color: color(darkTeal, .7) !important;
      }
      &-list {
        &_wrap {
          border-radius: 0 !important;
          padding: 0 !important;
          border-top: none !important;
          border-bottom: rem(1px) solid color(black) !important;
          &_overflow {
            left: negativeRem(20px) !important;
            padding-left: rem(20px) !important;
            padding-right: rem(20px) !important;
            padding-bottom: rem(20px) !important;
            width: -moz-calc(100% + #{rem(40px)}) !important;
            width: -webkit-calc(100% + #{rem(40px)}) !important;
            width: calc(100% + #{rem(40px)}) !important;
          }
        }
        &_item {
          &-checkbox {
            input {
              border-radius: 0 !important;
              -moz-outline-radius: 0 !important;
              &:checked {
                & + label {
                  color: color(black) !important;
                }
              }
            }
            label {
              color: #393837 !important;
              font-family: font(Montserrat), sans-serif !important;
              font-weight: 500 !important;
              fa-icon {
                color: color(black) !important;
              }
              &:after {
                border-radius: 0 !important;
              }
            }
          }
          &.active,
          &.focused {
            background-color: transparent !important;
            label {
              color: color(black) !important;
              &:after {
                border-color: color(black) !important;
              }
            }
          }
          &:hover {
            background-color: transparent !important;
            label {
              color: color(black) !important;
              &:after {
                border-color: color(black) !important;
              }
            }
          }
        }
      }
      &-multiple-selected {
        padding: rem(10px) rem(5px) !important;
        border-bottom: none !important;
        & > button {
          font-family: font(Montserrat), sans-serif !important;
          font-weight: 500 !important;
          color: color(darkTeal) !important;
          margin: rem(5px) !important;
          background-color: transparent !important;
          border: rem(1px) solid color(darkTeal, .7) !important;
          border-radius: rem(20px) !important;
          &:hover,
          &:focus-within {
            border-color: color(black) !important;
            color: color(black) !important;
            svg {
              color: color(black) !important;
            }
          }
          .clear-button {
            fa-icon {
              color: #7FADB6 !important;
              margin-left: rem(7px) !important;
            }
          }
        }
      }
      &.active {
        .erom-select {
          &-arrow {
            color: color(black) !important;
          }
          &-face {
            border-color: color(black) !important;
          }
          &-list_wrap_overflow {
            background-color: color(white) !important;
          }
        }
        input {
          &::-webkit-input-placeholder {
            color: color(black) !important;
          }
          &::-moz-placeholder {
            color: color(black) !important;
          }
          &:-ms-input-placeholder {
            color: color(black) !important;
          }
          &:-moz-placeholder {
            color: color(black) !important;
          }
        }
      }
      &:hover:not(.active) {
        .erom-select {
          &-arrow {
            color: color(darkTeal) !important;
          }
          &-face {
            border-color: #087281 !important;
          }
        }
        input {
          &::-webkit-input-placeholder {
            color: #087281 !important;
          }
          &::-moz-placeholder {
            color: #087281 !important;
          }
          &:-ms-input-placeholder {
            color: #087281 !important;
          }
          &:-moz-placeholder {
            color: #087281 !important;
          }
        }
      }
      input {
        color: color(black) !important;
        font-family: font(Montserrat), sans-serif !important;
        font-weight: 500 !important;
        padding-left: rem(10px) !important;
        &::-webkit-search-decoration {
          display: none !important;
        }
        &::-webkit-input-placeholder {
          color: color(darkTeal) !important;
        }
        &::-moz-placeholder {
          color: color(darkTeal) !important;
        }
        &:-ms-input-placeholder {
          color: color(darkTeal) !important;
        }
        &:-moz-placeholder {
          color: color(darkTeal) !important;
        }
      }
    }
    &--reset {
      text-align: right;
      margin-top: rem(30px);
      button {
        @include reset-button;
        color: #393837;
        font-size: rem(16px);
        line-height: rem(24px);
        font-family: font(Montserrat), sans-serif;
        font-weight: 500;
        span {
          text-decoration: underline;
        }
        &:hover {
          color: color(black);
          span {
            text-decoration-color: color(darkTeal);
            text-decoration-thickness: rem(2px);
            text-decoration-width: rem(2px);
          }
          svg {
            color: color(darkTeal);
          }
        }
        svg {
          vertical-align: middle;
          color: color(darkTeal, .7);
          width: rem(16px);
          height: rem(16px);
          margin-right: rem(10px);
        }
      }
    }
  }
  &__title {
    display: none;
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    margin-right: rem(10px);
    white-space: nowrap;
    -webkit-align-items: center;
    align-items: center;
    svg {
      display: block;
      width: rem(31px);
      height: auto;
      margin-right: rem(10px);
      .a {
        fill: #005c6e;
        stroke: rgba(0, 0, 0, 0);
        stroke-miterlimit: 10;
      }
      .b {
        fill: #4cbfb1;
      }
    }
    h2 {
      display: block;
      color: color(black);
      font-size: rem(16px);
      line-height: rem(24px);
      font-weight: bold;
      font-family: font(Montserrat), sans-serif;
      margin: rem(10px) 0 0;
    }
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      right: negativeRem(31px);
      top: 0;
      height: 100%;
      width: rem(1px);
      background-color: #EBEBEB;
    }
  }
  &__cta {
    display: none;
    position: absolute;
    top: rem(5px);
    left: 100%;
    margin-left: rem(10px);
    white-space: nowrap;
    text-align: right;
    padding: rem(20px) 0;
    h2 {
      color: color(black);
      font-size: rem(16px);
      line-height: rem(24px);
      font-weight: 500;
      font-family: font(Montserrat), sans-serif;
      margin: 0;
      padding: 0;
      a {
        color: color(black);
        font-size: rem(16px);
        line-height: rem(24px);
        font-weight: 500;
        font-family: font(Montserrat), sans-serif;
      }
    }
    & > div > a {
      color: color(darkTeal);
      font-size: rem(20px);
      line-height: rem(28px);
      font-weight: bold;
      font-family: font(Montserrat), sans-serif;
      text-decoration: none;
      margin-top: rem(5px);
      svg {
        display: inline-block;
        margin-right: rem(10px);
        width: rem(18px);
        height: rem(18px);
      }
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.erom-navigator-filters-block {
  position: relative;
  margin-top: rem(4px);
  &__container {
    background-color: color(black, .03);
  }
  &__selectors {
    @include flex-box;
    max-width: rem(852px);
    erom-select {
      width: 100%;
      &:nth-of-type(1) {
        border-right: rem(2px) solid color(white);
      }
      &:nth-of-type(2) {
        border-left: rem(2px) solid color(white);
      }
    }
  }
  .erom-select {
    font-family: font(Montserrat), sans-serif !important;
    font-weight: 500 !important;
    height: rem(82px) !important;
    color: color(black) !important;
    &-face {
      border-radius: 0 !important;
      &.type_gray {
        background-color: #F7F7F7 !important;
      }
    }
    &-arrow {
      color: color(darkTeal, .7) !important;
    }
    &-list_wrap {
      &.type_gray {
        background-color: #F7F7F7 !important;
      }
      &_overflow {
        left: negativeRem(20px) !important;
        padding-left: rem(20px) !important;
        padding-right: rem(20px) !important;
        padding-bottom: rem(20px) !important;
        width: -moz-calc(100% + #{rem(40px)}) !important;
        width: -webkit-calc(100% + #{rem(40px)}) !important;
        width: calc(100% + #{rem(40px)}) !important;
      }
    }
    &-placeholder {
      &.has-label {
        label {
          font-size: rem(16px) !important;
          color: color(darkTeal);
          font-family: font(Montserrat), sans-serif;
          font-weight: 500;
        }
        input {
          color: color(black) !important;
          font-family: font(Montserrat), sans-serif !important;
          font-weight: 500 !important;
        }
      }
    }
    &.selected {
      .erom-select {
        &-placeholder {
          &.has-label {
            label {
              font-size: rem(14px) !important;
            }
          }
        }
        &-arrow {
          top: rem(25px) !important;
          margin-top: unset !important;
          // transform: none !important;
        }
      }
    }
    &.active {
      .erom-select {
        &-arrow {
          color: color(black) !important;
        }
        &-list_wrap_overflow {
          background-color: color(white) !important;
        }
      }
    }
    &:hover {
      .erom-select {
        &-arrow {
          color: color(darkTeal) !important;
        }
      }
    }
  }
  &__title {
    color: color(black);
    font-family: font(Montserrat), sans-serif;
    font-weight: 500;
    font-size: rem(24px);
    line-height: rem(34px);
    margin-bottom: rem(30px);
    padding-left: rem(80px);
    padding-top: rem(3px);
  }
  &__text {
    margin-top: rem(40px);
    color: color(black);
    font-family: font(Montserrat), sans-serif;
    font-weight: 500;
    font-size: rem(16px);
    line-height: rem(24px);
    padding-left: rem(80px);
  }
  &__help {
    position: relative;
    padding: rem(20px) rem(20px) rem(20px) rem(44px);
    border-top: rem(1px) solid color(darkTeal, .5);
    &.expanded {
      padding-left: rem(20px);
    }
    &--icon {
      position: absolute;
      left: rem(20px);
      top: rem(24px);
      color: color(black, .8);
      svg {
        width: rem(16px);
        height: rem(16px);
      }
      &.expanded {
        svg {
          width: rem(20px);
          height: rem(21px);
        }
      }
    }
    &--button {
      @include reset-button;
      font-family: font(Montserrat), sans-serif;
      font-weight: 500;
      font-size: rem(16px);
      line-height: rem(24px);
      color: color(black, .8);
      text-align: left;
      span {
        border-bottom: rem(1px) solid #7F7F7F;
      }
      svg {
        width: rem(12px);
        height: rem(16px);
        margin-right: rem(8px);
        vertical-align: bottom;
      }
      &:hover,
      &:focus {
        color: color(black);
        span {
          border-color: color(black);
          border-bottom-width: rem(2px);
        }
      }
      &[aria-expanded="true"] {
        position: absolute;
        top: rem(20px);
        right: 0;
      }
    }
    &--text {
      padding-top: rem(60px);
      h3 {
        font-family: font(Montserrat), sans-serif;
        font-weight: 500;
        font-size: rem(24px);
        line-height: rem(34px);
        color: color(black);
      }
      p {
        font-family: font(Montserrat), sans-serif;
        font-weight: 500;
        margin-top: rem(50px);
        font-size: rem(16px);
        line-height: rem(24px);
        color: color(black);
      }
    }
  }
  &__actions {
    @include flex-box;
    -webkit-align-items: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: rem(80px);
    margin: rem(30px) negativeRem(15px) rem(13px);
    button {
      @include reset-button;
      font-size: rem(16px);
      line-height: rem(24px);
      font-family: font(Montserrat), sans-serif;
      font-weight: 500;
      color: color(black, .8);
      margin: 0 rem(15px) rem(17px);
    }
    .confirm-settings {
      background-color: color(white);
      border-radius: rem(50px);
      padding: rem(13px) rem(30px);
      border: rem(1px) solid #7F7F7F;
      &:hover,
      &:focus {
        color: color(black);
        border-color: color(black);
      }
    }
    .change-settings {
      padding: rem(13px) 0;
      span {
        border-bottom: rem(1px) solid #7F7F7F;
      }
      &:hover,
      &:focus {
        color: color(black);
        span {
          border-color: color(black);
          border-bottom-width: rem(2px);
        }
      }
    }
  }
  &__confirmed {
    @include flex-box;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    color: color(black);
    font-size: rem(16px);
    line-height: rem(24px);
    font-family: font(Montserrat), sans-serif;
    font-weight: 500;
    padding: rem(16px);
    background-color: color(white);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    .check-icon {
      color: #4CBFB1;
      width: rem(20px);
      min-width: rem(20px);
      height: rem(21px);
      margin-right: rem(10px);
    }
    .close-icon {
      color: #666666;
      width: rem(11px);
      min-width: rem(11px);
      height: rem(14px);
      margin-left: rem(30px);
      &:hover,
      &:focus {
        color: color(black);
      }
    }
    button {
      @include reset-button;
    }
    & > span {
      margin-top: negativeRem(4px);
    }
  }
  &__confirm-dialog {
    max-width: rem(1204px);
    margin: rem(20px) auto 0;
    &__container {
      position: relative;
      background: color(greenMint, .05);
      padding: rem(30px) rem(20px) rem(10px);
      border-bottom: rem(10px) solid color(greenMint);
      max-width: rem(892px);
      margin-left: negativeRem(40px);
    }
    &--icon {
      position: absolute;
      top: rem(30px);
      left: rem(20px);
      width: rem(40px);
      height: rem(40px);
      color: color(black);
      border: rem(1px) solid #7F7F7F;
      border-radius: rem(40px);
      margin-left: 20px;
      svg {
        position: absolute;
        width: rem(20px);
        height: rem(21px);
        left: 50%;
        top: 50%;
        margin-top: negativeRem(10px);
        margin-left: negativeRem(10px);
      }
    }
  }
}

.erom-search-results-block {
  margin-top: rem(70px);
  margin-bottom: rem(150px);
  &__rows {
    margin-bottom: rem(20px);
  }
  &__row {
    display: block;
    margin-bottom: rem(50px);
    max-width: rem(668px);
    font-family: font(Montserrat), sans-serif;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    z-index: 0;
    cursor: pointer;
    &--header {
      @include flex-box;
      -webkit-align-items: center;
      align-items: center;
      font-size: rem(14px);
      line-height: rem(20px);
      margin-bottom: rem(10px);
      .header {
        &-date {
          white-space: nowrap;
          color: color(darkTeal);
          margin-right: rem(10px);
        }
        &-link {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          background-color: #F7F7F7;
          padding: rem(5px) rem(10px);
          color: #4C4C4C;
          text-decoration: underline;
          max-width: 100%;
        }
      }
    }
    &--title {
      color: #333333;
      font-size: rem(24px);
      line-height: rem(34px);
      margin-bottom: rem(10px);
      font-weight: 600;
      a {
        color: #333333;
        font-size: rem(24px);
        line-height: rem(34px);
        font-weight: 600;
        display: block;
      }
    }
    &--text {
      color: #333333;
      font-size: rem(18px);
      line-height: rem(26px);
      font-family: Arial, Helvetica, sans-serif;
    }
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: negativeRem(28px);
      top: negativeRem(20px);
      width: -moz-calc(100% + #{rem(48px)});
      width: -webkit-calc(100% + #{rem(48px)});
      width: calc(100% + #{rem(48px)});
      height: -moz-calc(100% + #{rem(40px)});
      height: -webkit-calc(100% + #{rem(40px)});
      height: calc(100% + #{rem(40px)});
      background-color: color(black, .03);
      opacity: 0;
      z-index: -1;
      border-left: rem(8px) solid color(darkTeal);
      @include transition;
    }
    &:hover,
    &:focus-within,
    &:focus {
      .erom-search-results-block__row {
        &--title,
        &--header .header-link,
        &--text {
          color: color(black);
        }
      }
      &:before {
        opacity: 1;
      }
    }
  }
  &__no-results {
    padding: rem(30px) 0;
    h2 {
      @include flex-box;
      -webkit-align-items: baseline;
      align-items: baseline;
      font-size: rem(24px);
      line-height: rem(34px);
      color: color(black);
      font-weight: 600;
      svg {
        position: relative;
        top: rem(3.5px);
        width: rem(24px);
        min-width: rem(24px);
        height: rem(24px);
        margin-right: rem(15px);
      }
    }
    p {
      margin-top: rem(20px);
      font-size: rem(16px);
      line-height: rem(24px);
      font-weight: 500;
      color: color(black);
      padding-left: rem(40px);
      max-width: rem(600px);
    }
    a {
      color: color(black);
      text-decoration: underline;
    }
  }
  &__load_more {
    display: block;
    text-align: center;
    max-width: rem(668px);
    button {
      @include reset-button;
      color: #4C4C4C;
      font-size: rem(16px);
      line-height: rem(24px);
      font-family: font(Montserrat), sans-serif;
      font-weight: 500;
      padding: 0 rem(20px);
      height: rem(50px);
      border-radius: rem(50px);
      border: rem(1px) solid #707070;
      margin-top: rem(30px);
      &:hover,
      &:focus {
        color: color(black);
        border-color: color(black);
      }
    }
    span {
      color: #4C4C4C;
      font-size: rem(14px);
      line-height: rem(20px);
      font-family: font(Montserrat), sans-serif;
      font-weight: 500;
      display: block;
    }
  }
}

.article-content {
  max-width: rem(753px);
  &__head {
    position: relative;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: negativeRem(70px);
      top: negativeRem(70px);
      width: rem(1px);
      height: calc(100% + #{rem(100px)});
      background-color: #f3f3f2;
    }
  }
  h1 {
    font-size: rem(38px);
    line-height: rem(47px);
    margin-bottom: rem(30px);
    margin-top: rem(70px);
  }
  h2 {
    color: color(black);
    font-size: rem(28px);
    line-height: rem(34px);
    font-weight: 600;
    margin-bottom: rem(30px);
  }
  h3 {
    color: color(black);
    font-size: rem(24px);
    line-height: rem(34px);
    font-weight: 600;
    margin-bottom: rem(30px);
  }
  p {
    font-size: rem(18px);
    line-height: rem(26px);
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: rem(20px);
  }
  figure {
    width: 100%;
    margin-bottom: rem(30px);
    display: inline-block;
  }
  figcaption {
    display: block;
    color: #4C4C4C;
    font-size: rem(14px);
    line-height: rem(20px);
    font-family: Arial, Helvetica, sans-serif;
    margin-top: rem(10px);
  }
  img {
    max-width: 100%;
    height: auto;
    border: rem(1px) solid #CCCCCC;
  }
}

.article-summary {
  color: color(black);
  font-size: rem(18px);
  line-height: rem(26px);
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: rem(50px);
}

.article-short-guide {
  padding: 0 rem(25px) 0 0;
  background-color: #F7F7F7;
  margin-bottom: rem(50px);
  margin-right: negativeRem(20px);
  position: relative;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    right: 100%;
    background-color: #F7F7F7;
    width: 100vw;
    height: 100%;
  }
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: negativeRem(70px);
    width: rem(1px);
    height: 100%;
    background-color: color(white);
  }
  h2 {
    padding-top: rem(40px);
  }
  ol {
    margin-left: 0.5em;
    padding-left: 1em;
    padding-bottom: rem(40px);
    li {
      color: color(black);
      font-size: rem(18px);
      line-height: rem(26px);
      margin-left: 0;
      font-family: Arial, Helvetica, sans-serif;
      strong {
        padding: 0 rem(5px);
        background-color: #e5f5f3;
        font-family: Arial, Helvetica, sans-serif;
      }
      &:not(:last-of-type) {
        margin-bottom: rem(15px);
      }
    }
  }
}

.article-demo {
  margin-bottom: rem(70px);
  &__result {
    @include flex-box;
    & > div {
      width: 100%;
      border-left: rem(3px) solid transparent;
      &:first-of-type {
        border-color: #4C4C4C;
      }
      &:last-of-type {
        border-color: color(darkTeal, .7);
        .article-demo__result--title {
          color: color(darkTeal);
        }
      }
      &:not(:last-of-type) {
        margin-right: rem(30px);
      }
    }
    &--title {
      display: block;
      color: #393837;
      font-size: rem(24px);
      line-height: rem(34px);
      font-weight: 600;
      padding: rem(10px);
    }
    img {
      display: block;
      border: none;
    }
  }
}

.article-video {
  margin-bottom: rem(70px);
  iframe {
    max-width: 100%;
  }
}

.erom-article-share-block {
  @include flex-box;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 negativeRem(20px) rem(40px);
  padding: rem(30px) rem(20px);
  border-top: rem(1px) solid #B2CED3;
  border-bottom: rem(1px) solid #B2CED3;
  position: relative;
  &__buttons {
    @include flex-box;
    white-space: nowrap;
    margin: 0 negativeRem(15px);
    &--share {
      &-container {
      }
      &-listbox {
        list-style: none;
        margin: 0 0 0 rem(30px);
        padding: rem(15px) 0;
        position: absolute;
        z-index: 1;
        left: 100%;
        top: negativeRem(1px);
        min-width: rem(211px);
        background-color: #F2F7F8;
        li {
          color: color(black);
          font-size: rem(16px);
          line-height: 1.2;
          font-weight: 500;
          padding: rem(12px) rem(28px);
          border-left: rem(1px) solid transparent;
          border-right: rem(1px) solid transparent;
          svg {
            color: color(darkTeal, .7);
          }
          &:not([disabled="true"]) {
            cursor: pointer;
            span {
              text-decoration: underline;
            }
          }
          &:hover,
          &.focused,
          &[aria-selected="true"] {
            &:not([disabled="true"]) {
              background-color: color(white);
              border-left-color: #F2F7F8;
              border-right-color: #F2F7F8;
              svg {
                &:not(.copy-link-icon) {
                  color: color(darkTeal);
                }
              }
              span {
                text-decoration-color: color(darkTeal);
                text-decoration-thickness: rem(2px);
                text-decoration-width: rem(2px);
              }
            }
          }
        }
        &:before {
          content: ' ';
          position: absolute;
          right: 100%;
          top: rem(45px);
          border: solid transparent;
          height: 0;
          width: 0;
          pointer-events: none;
          border-right-color: #F2F7F8;
          border-width: rem(12px);
          margin-top: negativeRem(12px);
        }
      }
      &-icon {
        display: inline-block;
        vertical-align: middle;
        width: rem(32px);
        svg {
          &.copy-link-icon {
            color: #4CBFB1;
          }
        }
      }
    }
  }
  h2 {
    color: color(darkTeal);
    margin-bottom: 0;
  }
  button {
    @include reset-button;
    @include flex-box;
    -webkit-align-items: center;
    align-items: center;
    color: color(black);
    font-size: rem(16px);
    line-height: rem(24px);
    margin: 0 rem(15px);
    svg {
      color: color(darkTeal, .7);
      width: auto;
      height: rem(16px);
      margin-right: rem(12px);
    }
    span {
      text-decoration: underline;
    }
    &:focus,
    &:hover,
    &[aria-expanded="true"] {
      svg {
        color: color(darkTeal);
      }
      span {
        text-decoration-color: color(darkTeal);
        text-decoration-thickness: rem(2px);
        text-decoration-width: rem(2px);
      }
    }
  }
}

.article-paragraph {
  margin-bottom: rem(40px);
}

.article-links {
  margin-left: negativeRem(20px);
  margin-bottom: rem(50px);
  background-color: #F2F7F8;
  position: relative;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    right: 100%;
    background-color: #F2F7F8;
    width: 100vw;
    height: 100%;
  }
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: rem(1px);
    height: 100%;
    background-color: color(white);
  }
  ul {
    list-style: none;
    padding: rem(30px);
    margin: 0;
    li {
      color: color(black);
      font-size: rem(16px);
      line-height: rem(24px);
      a {
        color: color(black);
        font-size: rem(16px);
        line-height: rem(24px);
        text-decoration: underline;
        &:hover {
          text-decoration-color: color(darkTeal);
          text-decoration-thickness: rem(2px);
          text-decoration-width: rem(2px);
        }
      }
      &:not(:last-of-type) {
        margin-bottom: rem(20px);
      }
    }
  }
}

.article-logo {
  &__container {
    @include flex-box;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  &__link {
    display: block;
    position: absolute;
    width: rem(144px);
    height: rem(54px);
    top: rem(70px);
    right: 100%;
    margin-right: rem(10px);
  }
  &__cta {
    position: absolute;
    white-space: nowrap;
    left: 100%;
    bottom: rem(50px);
    text-align: right;
    margin-left: rem(10px);
    h2 {
      color: color(black);
      font-size: rem(16px);
      line-height: rem(24px);
      font-weight: 500;
      font-family: font(Montserrat), sans-serif;
      margin: 0;
      padding: 0;
      a {
        color: color(black);
        font-size: rem(16px);
        line-height: rem(24px);
        font-weight: 500;
        font-family: font(Montserrat), sans-serif;
      }
    }
    & > div > a {
      color: color(darkTeal);
      font-size: rem(20px);
      line-height: rem(28px);
      font-weight: bold;
      font-family: font(Montserrat), sans-serif;
      text-decoration: none;
      margin-top: rem(5px);
      svg {
        display: inline-block;
        margin-right: rem(10px);
        width: rem(18px);
        height: rem(18px);
      }
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
    &:hover {
      h2 {
        a {
          text-decoration: underline;
        }
      }
      svg {
        color: color(darkTeal);
      }
    }
  }
  svg {
    width: 100%;
    height: auto;
  }
}

.erom-article-feedback-block {
  margin-top: rem(10px);
  &__content {
    width: 100%;
    max-width: rem(384px);
    background-color: #F7F7F7;
    padding: rem(30px);
    h2 {
      color: color(black);
      font-weight: 500;
      font-size: rem(16px);
      line-height: rem(24px);
    }
  }
  &__actions {
    margin-top: rem(20px);
    button {
      @include reset-button;
      @include inline-flex;
      -webkit-align-items: center;
      align-items: center;
      color: color(black);
      font-weight: 500;
      font-size: rem(16px);
      line-height: rem(24px);
      svg {
        width: rem(18px);
        height: rem(18px);
        margin-right: rem(10px);
      }
      &:first-of-type {
        margin-right: rem(40px);
        svg {
          color: #7FD1C7;
        }
      }
      &:last-of-type {
        svg {
          color: #FFAC9A;
        }
      }
      &:hover {
        &:first-of-type {
          svg {
            color: color(greenMint);
          }
        }
        &:last-of-type {
          svg {
            color: #FF5A37;
          }
        }
      }
    }
    a {
      display: inline-block;
      color: color(black);
      font-weight: 500;
      font-size: rem(16px);
      line-height: rem(24px);
      padding: rem(12px) rem(20px);
      border-radius: rem(50px);
      border: rem(1px) solid color(darkTeal, .7);
      background-color: color(white);
      &:hover,
      &:focus {
        border-color: currentColor;
      }
    }
  }
}

.article-single-sidebar-cta {
  background-color: color(greenMint, .05);
  padding: rem(50px) rem(30px) rem(43px);
  margin-top: rem(50px);
  border-bottom: rem(7px) solid color(greenMint);
  & > div {
    @include flex-box;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  h2 {
    color: color(black);
    font-size: rem(20px);
    line-height: rem(28px);
    font-weight: 600;
    margin-bottom: rem(30px);
    margin-top: negativeRem(6px);
  }
  a {
    display: inline-block;
    color: color(black);
    background-color: color(white);
    padding: rem(12px) rem(20px);
    border-radius: rem(50px);
    font-size: rem(16px);
    line-height: rem(24px);
    font-weight: 500;
    text-align: center;
    width: 100%;
    border: rem(1px) solid color(darkTeal, .7);
    &:hover,
    &:focus {
      border-color: currentColor;
    }
  }
  svg {
    color: color(greenMint);
    width: rem(18px);
    height: rem(18px);
    margin-right: rem(15px);
  }
  &:focus-within,
  &:hover {
    svg {
      color: color(black);
    }
  }
}

.erom-article-filters-block {
  margin-bottom: rem(32px);
  background-color: color(black, .03);
  &__header {
    @include flex-box;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    font-size: rem(14px);
    line-height: rem(20px);
    color: color(darkTeal);
    padding: rem(20px);
    button {
      @include reset-button;
      @include flex-box;
      -webkit-align-items: center;
      align-items: center;
      font-size: rem(14px);
      line-height: rem(20px);
      color: color(black, .8);
      white-space: nowrap;
      svg {
        width: rem(12px);
        height: rem(14px);
        margin-right: rem(10px);
        @include transition;
      }
      span {
        text-decoration: underline;
      }
      &[aria-expanded="true"] {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    &.active {
      border-bottom: rem(1px) solid color(darkTeal, .3);
    }
  }
  &__container {
    padding: rem(20px);
  }
  &__filter {
    &--row {
      @include flex-box;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      &:not(:last-of-type) {
        margin-bottom: rem(16px);
      }
    }
    dt {
      padding: 0;
      font-size: rem(14px);
      line-height: rem(20px);
      color: color(black);
      font-weight: 500;
      min-width: rem(110px);
      margin: 0 rem(15px) 0 0;
      text-transform: capitalize;
      display: inline-block;
    }
    dd {
      padding: 0;
      margin: 0;
      font-size: rem(14px);
      line-height: rem(20px);
      color: color(darkTeal);
      font-weight: 500;
      display: inline-block;
      a {
        font-size: rem(14px);
        line-height: rem(20px);
        color: color(darkTeal);
        font-weight: 500;
        text-decoration: underline;
        &:hover,
        &:focus {
          text-decoration-thickness: rem(2px);
          text-decoration-width: rem(2px);
        }
      }
      span {
        padding: 0 rem(7.5px);
      }
    }
  }
}

.skip-to-content {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: rem(10px) rem(25px);
  background: #FFFFFF;
  color: #2C2A29;
  font-size: rem(18px);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  top: -100%;
  z-index: 10;
  &:focus {
    top: 0;
  }
}

.back-to-top-container {
  max-width: rem(1204px);
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999;
  button {
    cursor: pointer;
    line-height: 1;
    width: rem(73px);
    height: rem(82px);
    background: #015c6e url("footer/images/arrow-BackToTop.svg") no-repeat center top rem(13px);
    background-size: rem(17px) rem(12px);
    font-size: rem(14px);
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    position: absolute;
    bottom: rem(95px);
    right: negativeRem(73px);
    padding: 20px 15px 0;
    box-sizing: border-box;
    opacity: 0;
    -webkit-transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -ms-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    &:hover,
    &:focus {
      background-color: #2c2a29;
    }
    &.active {
      opacity: 1;
    }
  }
}

.cookie-bar {
  color: color(white);
  font-size: rem(16px);
  line-height: rem(24px);
  background-color: color(black);
  padding: rem(40px) 0;
  & > div {
    @include flex-box;
    -webkit-align-items: baseline;
    align-items: baseline;
  }
  &__icon {
    margin-right: rem(16px);
    color: color(yellow);
    svg {
      width: rem(16px);
      height: rem(16px);
      vertical-align: middle;
    }
  }
  &__text {
    display: inline-block;
    &--container {
      @include flex-box;
      -webkit-align-items: baseline;
      align-items: baseline;
    }
    a {
      color: inherit;
      line-height: inherit;
      text-decoration: none;
      border-bottom: rem(1px) solid color(yellow);
      &:hover,
      &:focus {
        color: color(yellow);
        border-bottom-width: rem(2px);
      }
    }
  }
  &__action {
    white-space: nowrap;
    color: color(yellow);
    text-decoration: none;
    border-bottom: rem(1px) solid color(yellow);
    margin-left: rem(16px);
    &:hover,
    &:focus {
      border-bottom-width: rem(2px);
    }
  }
  &__close {
    position: absolute;
    right: 0;
    top: rem(28px);
    padding: rem(15px);
    margin-left: rem(16px);
    margin-right: rem(35px);
    svg {
      width: rem(12px);
      height: rem(16px);
      vertical-align: middle;
    }
  }
}

.erom-article {
  .erom-navigator-filters-block {
    &__confirm-dialog {
      max-width: rem(924px);
      margin-top: rem(50px);
      margin-left: negativeRem(30px);
    }
  }
}

.erom-homepage {
  .erom-editor__content-row {
    margin: 0;
  }
  .erom-editor__rows-column {
    padding: 0;
  }
}

.erom-block {
   &__device-check {
     margin-left: negativeRem(37px);
     max-width: rem(783px);
     &.homepage-check {
       margin-top: rem(50px);
     }
     &__content {
       padding: rem(30px);
       border-left: rem(8px) solid color(greenMint, .7);
       background-color: color(greenMint, .03);
       position: relative;
     }
     &__title {
       @include flex-box;
       -webkit-align-items: flex-start;
       align-items: flex-start;
       font-weight: 500;
       font-size: rem(16px);
       line-height: rem(24px);
       color: color(black);
       font-family: font(Montserrat), sans-serif;
       svg {
         color: color(greenMint);
         margin-right: rem(10px);
         width: rem(20px);
         min-width: rem(20px);
         height: rem(21px);
         display: inline-block;
       }
       button {
         @include reset-button;
         text-decoration: underline;
       }
     }
     &__close {
       @include reset-button;
       color: color(greenMint, .7);
       position: absolute;
       top: rem(10px);
       right: rem(10px);
       width: rem(10px);
       height: rem(16px);
       svg {
         font-size: rem(16px);
       }
     }
   }
 }

@media screen and (max-width: rem(1730px)) {
  .article-logo {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding-top: rem(20px);
    padding-bottom: rem(20px);
    border-bottom: rem(1px) solid color(white);
    &__container {
      max-width: rem(1304px);
      margin: 0 auto;
      padding-left: rem(50px);
      padding-right: rem(50px);
    }
    &__link {
      position: relative;
      right: unset;
      top: unset;
      width: rem(117px);
      height: auto;
    }
    &__cta {
      position: relative;
      left: unset;
      bottom: unset;
      margin: unset;
    }
  }
  .erom-search-banner-block {
    &:not(.fixed-search) {
      .erom-search-banner-block {
        &--container {
          &:before {
            display: none;
          }
        }
      }
    }
    &.fixed-search {
      .erom-search-banner-block {
        &--container {
          position: relative;
        }
      }
    }
    &--container {
      position: unset;
      padding-top: rem(126px);
    }
  }
  .erom-category-filters-block {
    &__cta {
      display: none !important;
    }
    &__title {
      svg,
      h2 {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: rem(1400px)) {
  .back-to-top-container {
    button {
      right: 0;
      bottom: rem(70px);
    }
  }
}

@media screen and (max-width: rem(1366px)) {
  .cookie-bar {
    & > div {
      margin-right: rem(36px);
    }
    &__text {
      &--container {
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        & > div {
          width: 100%;
        }
      }
    }
    &__action {
      margin: rem(8px) 0 0;
    }
  }
}

@media screen and (max-width: rem(1340px)) {
  .erom-article {
    .erom-navigator-filters-block {
      &__confirm-dialog {
        max-width: unset;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: rem(1280px)) {
  .back-to-top-container {
    max-width: 100%;
    min-width: unset !important;
    margin: 0 !important;
    left: 0;
    width: 100% !important;
    button {
      right: rem(16px);
    }
  }
  .erom-navigator-filters-block {
    &__confirm-dialog {
      max-width: rem(1304px);
      padding-left: rem(50px);
      padding-right: rem(50px);
      &__container {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: rem(1180px)) {
  .article-logo__cta > div > a {
    font-size: rem(16px);
    line-height: rem(24px);
    svg {
      width: rem(18px);
      height: rem(18px);
    }
  }
  .erom-category-filters-block {
    &.fixed-filters {
      .erom-category-filters-block__title {
        display: none;
      }
    }
    &:before {
      display: none;
    }
    &__filters {
      -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 negativeRem(10px);
      & > div {
        padding: 0 rem(10px);
        &:not(.navigator-filters) {
          max-width: rem(172px);
        }
        &.navigator-filters {
          margin-top: rem(30px);
          width: 100%;
          max-width: rem(688px);
        }
      }
    }
    &__area {
      &.active-filters {
        border: none;
        padding-bottom: 0;
      }
    }
  }
  .erom-navigator-filters-block {
    &__selectors {
      -webkit-justify-content: center;
      justify-content: center;
      erom-select {
        width: 100%;
      }
    }
    &__confirmed {
      margin-bottom: rem(44px);
    }
    &__confirm-dialog {
      margin-top: rem(20px);
      margin-bottom: 0;
    }
  }
  .erom-search-results-block {
    margin-bottom: rem(110px);
  }
  .article-content {
    h1 {
      margin-top: rem(50px);
    }
  }
  .article-short-guide {
    margin-top: rem(50px);
    &:after {
      left: negativeRem(25px);
    }
  }
  .erom-article-feedback-block {
    @include flex-box;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    &__content {
      margin-right: negativeRem(50px);
      padding-right: rem(50px);
    }
  }
  .cookie-bar {
    padding: rem(20px) 0;
    & > div {
      margin-right: rem(50px);
    }
    &__close {
      top: rem(10px);
      margin-right: rem(25px);
    }
  }
  .homepage-content-area {
    &:before {
      display: none;
    }
  }
  .erom-block__device-check {
    max-width: rem(676px);
    margin: 0 0 0 negativeRem(8px);
    &.homepage-check {
      margin-top: rem(30px);
    }
  }
  .erom-search-banner-block--container {
    &:before {
      display: none;
    }
  }
}

@media screen and (max-width: rem(1080px)) {
  .erom-article-share-block {
    &__buttons {
      &--share {
        &-listbox {
          top: 100%;
          left: unset;
          right: 0;
          border-right: rem(20px) solid color(white);
          border-left: rem(20px) solid color(white);
          border-bottom: rem(20px) solid color(white);
          box-sizing: content-box;
          &:before {
            content: ' ';
            position: absolute;
            top: unset;
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            height: 0;
            width: 0;
            pointer-events: none;
            border-bottom-color: #F2F7F8;
            border-width: rem(12px);
            margin-left: negativeRem(12px);
          }
        }
      }
    }
  }
}

@media screen and (max-width: rem(767px)) {
  .erom-category-filters-block {
    &__filters {
      & > div {
        &:not(.navigator-filters) {
          max-width: 50%;
          width: 100%;
        }
        &:not(:last-of-type) {
          margin-bottom: rem(10px);
        }
        &.navigator-filters {
          .erom-select {
            &-arrow {
              margin-top: negativeRem(15px) !important;
            }
            &.active,
            &.selected {
              .erom-select {
                &-arrow {
                  margin-top: negativeRem(8px) !important;
                }
              }
            }
          }
        }
        erom-select {
          max-width: unset;
        }
        .erom-select {
          &-arrow {
            width: rem(30px);
            height: rem(30px);
            right: 0 !important;
            margin-top: negativeRem(12px) !important;
          }
          &.active,
          &.selected {
            .erom-select {
              &-arrow {
                margin-top: negativeRem(12px) !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: rem(600px)) {
  app-page {
    .erom-call-to-action-block > div {
      padding-left: rem(30px);
      padding-right: rem(30px);
    }
  }
  .homepage-content-area {
    &:before {
      display: none;
    }
  }
  .homepage-category-filters {
    position: relative;
  }
  .article-logo {
    padding: rem(20px) 0;
    &__container {
      padding-left: rem(15px);
      padding-right: rem(15px);
    }
  }
  .erom-search-banner-block {
    &--container {
      padding: rem(116px) rem(15px) rem(30px);
    }
    &--search {
      @include flex-box;
      width: 100%;
      max-width: 100%;
      & > div {
        position: relative;
        width: 100%;
      }
    }
    &--mobile-filters {
      display: block;
      position: relative;
      margin-left: rem(5px);
      width: rem(56px);
      min-width: rem(56px);
      height: rem(56px);
      font-size: rem(20px);
      text-align: left;
      padding: 0 rem(12px);
      color: color(darkTeal);
      background-color: color(white);
      svg {
        pointer-events: none;
        max-width: rem(20px);
      }
      &__counter {
        position: absolute;
        pointer-events: none;
        top: rem(8px);
        left: rem(28px);
        color: color(black, .7);
        font-weight: 500;
        font-size: rem(14px);
        line-height: rem(18px);
        padding: 0 rem(4px);
        min-width: rem(20px);
        text-align: center;
        display: inline-block;
        border-radius: rem(20px);
        border: rem(1px) solid color(white);
        background-color: color(black, .05);
        &.has-filters {
          color: color(white);
          background-color: color(greenMint);
        }
      }
    }
    p {
      margin-bottom: rem(30px);
    }
  }
  .erom-navigator-filters-block {
    &__selectors {
      margin-left: negativeRem(10px);
      margin-right: negativeRem(10px);
      padding: 0;
    }
    &__title {
      padding-left: rem(60px);
    }
    .erom-select {
      &.selected {
        .erom-select-placeholder {
          &.has-label {
            label {
              left: rem(10px);
            }
          }
          input[type="text"] {
            padding-left: rem(10px) !important;
          }
        }
      }
    }
    &__actions {
      padding-left: 0;
    }
    &__confirm-dialog {
      padding-left: 0;
      padding-right: 0;
      &--icon {
        margin-left: 0;
      }
    }
  }
  .erom-category-filters-block {
    position: relative;
    background: white;
    margin: rem(15px) 0 0;
    padding: rem(30px) rem(20px);
    -webkit-box-shadow: 0 0 rem(30px) 0 #005C6E1A;
    -moz-box-shadow: 0 0 rem(30px) 0 #005C6E1A;
    box-shadow: 0 0 rem(30px) 0 #005C6E1A;
    &:not(.mobile-filters-active) {
      position: absolute !important;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0 !important;
      border: 0 !important;
      height: 1px !important;
      width: 1px !important;
      overflow: hidden;
    }
    &__filters {
      display: block;
      margin: 0;
      & > div {
        padding: 0 !important;
        margin: rem(30px) 0 0 !important;
        max-width: 100% !important;
        &:not(.navigator-filters) {
          .erom-select {
            margin-top: 0;
          }
        }
        &.navigator-filters {
          -ms-flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
          erom-select {
            border: none !important;
            &:nth-of-type(2) {
              margin-top: rem(1px) !important;
            }
          }
          .erom-select {
            &.native {
              margin-bottom: rem(20px);
            }
            &-placeholder {
              input {
                color: color(black) !important;
                padding-top: rem(33px) !important;
                &::-webkit-input-placeholder {
                  color: color(black) !important;
                }
                &::-moz-placeholder {
                  color: color(black) !important;
                }
                &:-ms-input-placeholder {
                  color: color(black) !important;
                }
                &:-moz-placeholder {
                  color: color(black) !important;
                }
              }
            }
          }
        }
      }
      &--reset {
        background-color: #F7F7F7;
        text-align: center;
        padding: rem(16px) 0;
      }
    }
    &__area {
      &.active-filters {
        border-bottom: none;
      }
    }
    &__title {
      @include flex-box;
      position: relative;
      top: unset;
      right: unset;
      margin: 0;
      svg,
      h2 {
        display: block !important;
      }
    }
  }
  .erom-search-results-block {
    margin-bottom: rem(82px);
    margin-top: rem(45px);
    &__row {
      &:last-of-type {
        margin-bottom: rem(70px);
      }
    }
  }
  .article-content {
    .flex-section {
      display: block;
      margin-left: 0;
      margin-right: 0;
      & > * {
        margin-left: 0;
        margin-right: 0;
      }
    }
    figure {
      width: auto;
    }
  }
  .article-short-guide {
    margin-right: 0;
    margin-bottom: rem(50px);
    padding-left: rem(20px);
    &:after {
      left: 0;
    }
  }
  .article-demo__result {
    display: block;
    & > div {
      margin: 0;
      &:not(:last-of-type) {
        margin-bottom: rem(30px);
      }
    }
  }
  .article-demo {
    margin-bottom: rem(50px);
  }
  .article-video {
    margin-bottom: rem(50px);
  }
  .erom-article-share-block {
    display: block;
    margin-left: negativeRem(10px);
    margin-right: negativeRem(10px);
    padding-left: rem(10px);
    padding-right: rem(10px);
    margin-bottom: rem(30px);
    h2 {
      margin-bottom: rem(30px);
    }
    &__buttons {
      display: block;
      margin: 0;
      button {
        margin: 0;
      }
      &--share {
        &-container {
          position: relative;
          margin-top: rem(20px);
          display: inline-block;
        }
        &-listbox {
          right: unset;
          left: negativeRem(60px);
          margin-top: rem(15px);
        }
      }
    }
  }
  .article-links {
    margin-left: 0;
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
  .erom-navigator-filters-block {
    padding-left: rem(10px);
    padding-right: rem(10px);
    &__container {
      padding: 0;
    }
    &__selectors {
      margin-left: negativeRem(22px);
      margin-right: negativeRem(22px);
    }
    &__actions {
      display: block;
      text-align: center;
      button {
        &:not(:last-of-type) {
          margin-bottom: rem(30px);
        }
      }
    }
  }
  .back-to-top-container {
    button {
      bottom: rem(30px);
    }
  }
  .cookie-bar {
    & > div {
      margin-right: rem(40px);
    }
    &__close {
      margin-right: rem(5px);
    }
  }
  .erom-article {
    .erom-navigator-filters-block {
      padding: 0;
    }
  }
  .erom-block__device-check {
    margin-left: 0;
  }
  textarea,
  select,
  input {
    font-size: 16px !important;
    &:focus {
      font-size: 16px !important;
    }
  }
}

@media screen and (max-width: rem(360px)) {
  .erom-search-banner-block--search {
    & > div {
      &:first-of-type {
        background-color: color(white);
        border: rem(1px) solid color(darkTeal, .7);
      }
    }
    input {
      padding-left: rem(5px) !important;
      padding-right: rem(5px) !important;
      font-size: rem(14px) !important;
      background-color: transparent;
      border: none;
      height: rem(54px);
      &:not(.empty) {
        padding-right: 0;
      }
    }
    &-icon {
      height: rem(54px);
    }
    &:not(.empty) {
      div[role="combobox"] {
        padding-right: rem(115px);
      }
    }
    &:hover,
    &:focus-within {
      & > div {
        &:first-of-type {
          border-color: color(darkTeal);
        }
      }
    }
  }
}
