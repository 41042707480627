@import './settings/settings';

.keyboard-focus {
  :focus {
    outline: color(focus) solid rem(2px) !important;
    outline-offset: rem(1px);
  }
}

.form {
  &-group {
    margin-bottom: rem(32px);
    position: relative;
    &__repeater {
      width: 100%;
      margin-bottom: rem(32px);
      .form-group {
        margin-right: rem(102px);
      }
      &--new,
      &--remove {
        @include reset-button;
        color: color(main, .8);
        font-size: rem(14px);
        line-height: rem(20px);
        margin-left: rem(16px);
        span {
          display: inline-block;
          line-height: rem(18px);
          border-bottom: rem(1px) solid color(main, .4);
        }
        fa-icon {
          color: color(main, .4);
          font-size: rem(14px);
          line-height: rem(18px);
          margin-right: rem(8px);
        }
        &:hover,
        &:focus {
          color: color(main);
          span {
            border-color: color(main, .8);
          }
          fa-icon {
            color: color(main, .8);
          }
        }
      }
      &--remove {
        position: absolute;
        left: 100%;
        bottom: rem(16px);
        white-space: nowrap;
      }
    }
    &__half {
      @include flex-box;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      margin: 0 negativeRem(16px);
      .form-group {
        width: 50%;
        padding: 0 rem(16px);
      }
    }
  }
  &-label {
    display: block;
    font-size: rem(14px);
    line-height: rem(20px);
    color: color(main, .8);
    margin-bottom: rem(8px);
    font-family: font(HKGrotesk), sans-serif;
    a {
      color: inherit;
      border-bottom: rem(1px) solid color(main, .4);
      &:hover,
      &:focus {
        color: color(main);
        border-color: color(main, .8);
      }
    }
  }
  &-control {
    &__input {
      @include reset-input;
      width: 100%;
      height: rem(53px);
      font-family: font(HKGrotesk), sans-serif;
      font-size: rem(16px);
      line-height: rem(24px);
      color: color(main, .8);
      padding: rem(16px);
      border-radius: rem(4px);
      border: rem(1px) solid color(main, .4);
      @include input-placeholder-color(color(main, .8));
      &.invalid {
        border-color: color(crismon, .8);
      }
    }
    &__textarea {
      @include reset-textarea;
      width: 100%;
      font-family: font(HKGrotesk), sans-serif;
      font-size: rem(16px);
      line-height: rem(24px);
      color: color(main, .8);
      padding: rem(28px) rem(16px);
      border-radius: rem(4px);
      border: rem(1px) solid color(main, .4);
      @include input-placeholder-color(color(main, .8));
      &.invalid {
        border-color: color(crismon, .8);
      }
    }
    &__radio {
      @include reset-input;
      position: absolute;
      width: rem(24px);
      height: rem(24px);
      left: 0;
      top: 0;
      &:checked + label:before,
      &:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: rem(24px);
        height: rem(24px);
        border: rem(1px) solid color(main, .4);
        border-radius: rem(24px);
        background: transparent;
        box-sizing: border-box;
      }
      &:checked + label:after,
      &:not(:checked) + label:after {
        content: ' ';
        width: rem(16px);
        height: rem(16px);
        border-radius: rem(16px);
        background-color: color(main, .8);
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: rem(4px);
        -webkit-transform-origin: top center;
        -moz-transform-origin: top center;
        -ms-transform-origin: top center;
        -o-transform-origin: top center;
        transform-origin: top center;
        @include transition;
      }
      &:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0) translateY(-50%);
        -moz-transform: scale(0) translateY(-50%);
        -ms-transform: scale(0) translateY(-50%);
        -o-transform: scale(0) translateY(-50%);
        transform: scale(0) translateY(-50%);
      }
      &:checked + label {
        &:before {
          border-color: color(main, .8);
        }
        &:after {
          opacity: 1;
          -webkit-transform: scale(1) translateY(-50%);
          -moz-transform: scale(1) translateY(-50%);
          -ms-transform: scale(1) translateY(-50%);
          -o-transform: scale(1) translateY(-50%);
          transform: scale(1) translateY(-50%);
        }
      }
      &:hover,
      &:focus {
        & + label {
          color: color(main);
          &:before {
            border-color: color(main, .8);
          }
          &:after {
            background-color: color(main);
          }
        }
      }
      &:checked + label {
        color: color(main, .8);
        &:before {
          border-color: color(main, .8);
        }
      }
      & + label {
        padding-left: rem(40px);
        font-size: rem(16px);
        line-height: rem(24px);
        color: color(main, .8);
        position: relative;
        cursor: pointer;
        display: inline-block;
        margin: 0;
        &:hover {
          color: color(main);
          &:before {
            border-color: color(main, .8);
          }
          &:after {
            background-color: color(main);
          }
        }
      }
    }
    &__checkbox {
      @include reset-input;
      position: absolute;
      width: rem(24px);
      height: rem(24px);
      left: 0;
      top: 0;
      &:checked + label:before,
      &:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: rem(24px);
        height: rem(24px);
        border: rem(1px) solid color(main, .4);
        border-radius: rem(4px);
        background: transparent;
        box-sizing: border-box;
      }
      &:checked + label:after,
      &:not(:checked) + label:after {
        content: ' ';
        width: rem(14px);
        height: rem(8px);
        border-bottom: rem(3px) solid color(main, .8);
        border-left: rem(3px) solid color(main, .8);
        position: absolute;
        top: 50%;
        margin-top: negativeRem(1px);
        -webkit-transform: translateY(-50%) rotate(-45deg);
        -moz-transform: translateY(-50%) rotate(-45deg);
        -ms-transform: translateY(-50%) rotate(-45deg);
        -o-transform: translateY(-50%) rotate(-45deg);
        transform: translateY(-50%) rotate(-45deg);
        left: rem(2px);
        -webkit-transform-origin: top center;
        -moz-transform-origin: top center;
        -ms-transform-origin: top center;
        -o-transform-origin: top center;
        transform-origin: top center;
        @include transition;
      }
      &:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0) translateY(-50%) rotate(-45deg);
        -moz-transform: scale(0) translateY(-50%) rotate(-45deg);
        -ms-transform: scale(0) translateY(-50%) rotate(-45deg);
        -o-transform: scale(0) translateY(-50%) rotate(-45deg);
        transform: scale(0) translateY(-50%) rotate(-45deg);
      }
      &:checked + label {
        &:before {
          border-color: color(main, .8);
        }
        &:after {
          opacity: 1;
          -webkit-transform: scale(1) translateY(-50%) rotate(-45deg);
          -moz-transform: scale(1) translateY(-50%) rotate(-45deg);
          -ms-transform: scale(1) translateY(-50%) rotate(-45deg);
          -o-transform: scale(1) translateY(-50%) rotate(-45deg);
          transform: scale(1) translateY(-50%) rotate(-45deg);
        }
      }
      &:hover,
      &:focus {
        & + label {
          color: color(main);
          &:before {
            border-color: color(main, .8);
          }
          &:after {
            border-color: color(main);
          }
        }
      }
      &:checked + label {
        color: color(main, .8);
        &:before {
          border-color: color(main, .8);
        }
      }
      & + label {
        padding-left: rem(40px);
        font-size: rem(16px);
        line-height: rem(24px);
        color: color(main, .8);
        position: relative;
        cursor: pointer;
        display: inline-block;
        margin: 0;
        &:hover {
          color: color(main);
          &:before {
            border-color: color(main, .8);
          }
          &:after {
            border-color: color(main);
          }
        }
      }
    }
    &__hint {
      display: block;
      margin-top: rem(8px);
      font-family: font(HKGrotesk), sans-serif;
      font-size: rem(14px);
      line-height: rem(20px);
      color: color(main, .8);
    }
    &__warning {
      @include flex-box;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      color: color(main, .8);
      font-size: rem(16px);
      line-height: rem(24px);
      padding: rem(16px);
      margin: rem(32px) rem(16px) 0;
      max-width: rem(588px);
      border-radius: rem(4px);
      background-color: color(canary, .05);
      fa-icon {
        color: color(canary, .6);
        font-size: rem(18px);
        line-height: rem(18px);
        margin-right: rem(8px);
        margin-top: rem(2px);
      }
    }
    &__show-password {
      @include reset-button;
      position: absolute;
      right: rem(32px);
      bottom: rem(17px);
      color: color(main, .4);
      font-size: rem(14px);
      line-height: rem(18px);
      &:hover,
      &:focus {
        color: color(main, .8);
      }
      &.active {
        color: color(main);
      }
    }
    &__image {
      &--preview {
        width: rem(64px);
        height: rem(64px);
        background-color: color(main, .05);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &--remove {
        @include reset-button;
        color: color(main, .8);
        font-size: rem(14px);
        line-height: rem(20px);
        margin-top: rem(10px);
        span {
          display: inline-block;
          line-height: rem(18px);
          border-bottom: rem(1px) solid color(main, .4);
        }
        fa-icon {
          color: color(main, .4);
          font-size: rem(14px);
          line-height: rem(18px);
          margin-right: rem(8px);
        }
        &:hover,
        &:focus {
          color: color(main);
          span {
            border-color: color(main, .8);
          }
          fa-icon {
            color: color(main, .8);
          }
        }
      }
      &--input {
        position: relative;
        margin-top: rem(14px);
        input[type="file"] {
          @include reset-input;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: .25em;
          text-indent: -999%;
          position: absolute;
          overflow: hidden;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
        &__text {
          color: color(main, .8);
          font-size: rem(14px);
          line-height: rem(20px);
          span {
            display: inline-block;
            line-height: rem(18px);
            border-bottom: rem(1px) solid color(main, .4);
          }
          fa-icon {
            color: color(main, .4);
            font-size: rem(14px);
            line-height: rem(18px);
            margin-right: rem(8px);
          }
        }
      }
    }
  }
  &-actions {
    text-align: right;
  }
  &-submit {
    @include reset-button;
    color: color(main, .8);
    font-size: rem(16px);
    line-height: rem(24px);
    border-radius: rem(4px);
    padding: rem(16px) rem(32px);
    background-color: color(main, .05);
  }
}

.erom {
  &-editor {
    .breadcrumbs {
      border-right: rem(1px) solid color(white);
      .admin-container {
        max-width: rem(1280px);
      }
    }
  }
  &-infinite-spinner {
    -webkit-animation: erom-infinite-spinner 2s linear infinite;
    -moz-animation: erom-infinite-spinner 2s linear infinite;
    -ms-animation: erom-infinite-spinner 2s linear infinite;
    -o-animation: erom-infinite-spinner 2s linear infinite;
    animation: erom-infinite-spinner 2s linear infinite;
    @-webkit-keyframes erom-infinite-spinner {
      from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes erom-infinite-spinner {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
  @keyframes erom-spinner {
    to {transform: rotate(360deg);}
  }
  &-spinner {
    position: relative;
    text-indent: -999% !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    pointer-events: none !important;
    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(20px);
      height: rem(20px);
      margin-top: negativeRem(10px);
      margin-left: negativeRem(10px);
      border-radius: 50%;
      border: rem(2px) solid color(main, .1);
      border-top-color: color(main);
      animation: erom-spinner .8s linear infinite;
    }
  }
  &-tooltip {
    display: none;
    position: absolute;
    left: 50%;
    top: rem(34px);
    transform: translateX(-50%);
    background-color: color(main, .05);
    border-radius: rem(4px);
    padding: rem(16px);
    color: color(main);
    font-size: rem(16px);
    line-height: rem(24px);
    white-space: nowrap;
    border: rem(2px) solid color(white);
    z-index: 2;
    &__container {
      position: relative;
      display: inline-block;
      &:hover {
        .erom-tooltip {
          display: block;
        }
      }
    }
    span {
      display: block;
    }
    &:after,
    &:before {
      content: ' ';
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: transparent;
      border-bottom-color: color(main, .05);
      border-width: rem(10px);
      margin-left: negativeRem(10px);
    }
    &:before {
      border-color: transparent;
      border-bottom-color: color(white);
      border-width: rem(13px);
      margin-left: rem(13px);
    }
  }
}

.ps {
  &__rail-y {
    width: rem(8px)!important;
    background-color: color(main, .1)!important;
    opacity: 1 !important;
    border: rem(1px) solid color(white) !important;
    .ps__thumb-y {
      background-color: color(main, .2) !important;
      width: rem(4px) !important;
      right: rem(1px) !important;
      border-radius: 0 !important;
      &:hover {
        background-color: color(main, .4) !important;
      }
    }
  }
}



.erom-editor {
  &__rows {
    &-column {
      width: 100%;
      padding: 0 rem(15px);
    }
  }
  &__content {
    &-row {
      @include flex-box;
      margin: 0 negativeRem(15px);
      &.grid {
        &_50-50 {
          & > div {
            width: 50%;
          }
        }
        &_75-25 {
          & > div {
            &:nth-of-type(1) {
              width: 75%;
            }
            &:nth-of-type(2) {
              width: 25%;
            }
          }
        }
        &_65-35 {
          & > div {
            &:nth-of-type(1) {
              width: 65%;
            }
            &:nth-of-type(2) {
              width: 35%;
            }
          }
        }
        &_25-75 {
          & > div {
            &:nth-of-type(1) {
              width: 25%;
            }
            &:nth-of-type(2) {
              width: 75%;
            }
          }
        }
        &_25-25-50 {
          & > div {
            &:nth-of-type(1) {
              width: 25%;
            }
            &:nth-of-type(2) {
              width: 25%;
            }
            &:nth-of-type(3) {
              width: 50%;
            }
          }
        }
        &_25-50-25 {
          & > div {
            &:nth-of-type(1) {
              width: 25%;
            }
            &:nth-of-type(2) {
              width: 50%;
            }
            &:nth-of-type(3) {
              width: 25%;
            }
          }
        }
        &_50-25-25 {
          & > div {
            &:nth-of-type(1) {
              width: 50%;
            }
            &:nth-of-type(2) {
              width: 25%;
            }
            &:nth-of-type(3) {
              width: 25%;
            }
          }
        }
        &_25-25-25-25 {
          & > div {
            width: 25%;
          }
        }
      }
    }
    &-container {
      max-width: rem(1304px);
      margin: 0 auto;
      padding-left: rem(50px);
      padding-right: rem(50px);
    }
  }
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

.bg-cover-full {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.grid-align-left {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.grid-align-center {
  -webkit-justify-content: center;
  justify-content: center;
}

.grid-align-right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.grid-align-top {
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.grid-align-middle {
  -webkit-align-items: center;
  align-items: center;
}

.grid-align-bottom {
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.flex-section {
  @include flex-box;
  margin: 0 negativeRem(15px);
  & > * {
    margin: 0 rem(15px);
  }
}

.tox .tox-collection--list .tox-collection__item {
  &[title="Delete table"] {
    display: none;
  }
}

.login-page {
  font-family: font(HKGrotesk), sans-serif;
  padding-top: rem(128px);
  padding-bottom: rem(128px);
  &-wrapper {
    max-width: gridValue(loginContainer);
    margin: 0 auto;
    &.info-message {
      max-width: rem(600px);
    }
  }
  &-container {
    border-radius: rem(4px);
    border: rem(1px) solid color(main, .06);
    @include box-shadow(0, 0, rem(32px), 0, color(main, .08));
  }
  &-header {
    color: color(main);
    &__logo {
      color: inherit;
      display: block;
      margin: 0 auto rem(48px);
      width: rem(75.63px);
      height: rem(16px);
      svg {
        width: 100%;
        height: auto;
      }
    }
    &__title {
      font-size: rem(24px);
      line-height: rem(36px);
      margin-bottom: rem(32px);
      text-align: center;
    }
  }
  &-form {
    padding: rem(32px);
  }
  &-footer {
    padding: rem(32px);
    background-color: color(main, .03);
    a {
      color: color(main, .8);
      font-size: rem(16px);
      line-height: rem(24px);
      border-bottom: rem(1px) solid color(main, .4);
    }
  }
  &-loading {
    margin: rem(100px) 0;
    svg {
      width: rem(32px);
      &.fa-spinner {
        width: rem(40px);
        -webkit-animation: spin 1.5s linear infinite;
        -moz-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
      }
    }
    &--icon {
      text-align: center;
    }
    &--text {
      text-align: center;
      margin-top: rem(16px);
      font-size: rem(18px);
      line-height: rem(24px);
    }
  }
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
}

.page-not-found {
  position: relative;
  font-family: font(Montserrat), sans-serif;
  &__container {
    position: relative;
    max-width: rem(1304px);
    margin: 0 auto;
    padding: 0 rem(50px);
    .article-logo__link {
      top: rem(92px);
    }
  }
  &__content {
    max-width: rem(753px);
    margin: 0 auto;
    padding: rem(100px) 0;
    h1 {
      @include flex-box;
      -webkit-align-items: center;
      align-items: center;
      font-size: rem(38px);
      line-height: rem(47px);
      font-weight: 600;
      color: color(black, .8);
      svg {
        color: color(orange);
        width: rem(28px);
        height: auto;
        margin-right: rem(16px);
      }
    }
    h2 {
      font-size: rem(24px);
      line-height: rem(34px);
      font-weight: 600;
      color: color(black);
      margin-top: rem(50px);
    }
    p {
      font-size: rem(20px);
      line-height: rem(28px);
      font-weight: 500;
      color: color(black);
      margin-top: rem(20px);
    }
    a {
      font-size: rem(16px);
      line-height: rem(24px);
      font-weight: 500;
      color: color(black, .8);
      text-decoration: none;
      display: inline-block;
      padding: rem(10px) rem(15px);
      margin-top: rem(30px);
      border-radius: rem(50px);
      border: rem(1px) solid color(black, .6);
      &:hover,
      &:focus {
        color: color(black);
        border-color: color(black);
      }
    }
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: rem(20px);
      top: 0;
      width: rem(1px);
      height: 100%;
      background-color: color(black, .03);
    }
  }
  &__article-logo {
    &__container {
      @include flex-box;
      max-width: rem(1304px);
      margin: 0 auto;
      position: relative;
      -webkit-justify-content: space-between;
      justify-content: space-between;
    }
    &__link {
      display: block;
      position: absolute;
      width: rem(144px);
      height: auto;
      top: rem(92px);
      left: 0;
      margin-left: negativeRem(144px);
    }
    svg {
      width: 100%;
      height: auto;
    }
    &__cta {
      display: none;
      white-space: nowrap;
      text-align: right;
      h2 {
        color: color(black);
        font-size: rem(16px);
        line-height: rem(24px);
        font-weight: 500;
        font-family: font(Montserrat), sans-serif;
        margin: 0;
        padding: 0;
        a {
          color: color(black);
          font-size: rem(16px);
          line-height: rem(24px);
          font-weight: 500;
          font-family: font(Montserrat), sans-serif;
        }
      }
      & > div > a {
        color: color(darkTeal);
        font-size: rem(20px);
        line-height: rem(28px);
        font-weight: bold;
        font-family: font(Montserrat), sans-serif;
        text-decoration: none;
        margin-top: rem(5px);
        svg {
          display: inline-block;
          margin-right: rem(10px);
          width: rem(18px);
          height: rem(18px);
        }
      }
      &:hover {
        h2 {
          a {
            text-decoration: underline;
          }
        }
        svg {
          color: color(darkTeal);
        }
      }
    }
  }
}

@media screen and (max-width: rem(1630px)) {
  .page-not-found {
    &__article-logo {
      background-color: color(darkTeal, .05);
      &__container {
        padding: rem(20px) rem(50px);
      }
      &__link {
        position: relative;
        top: unset;
        left: unset;
        margin: 0;
        width: rem(117px)
      }
      &__cta {
        display: block;
      }
    }
  }
}

@media screen and (max-width: rem(1180px)) {
  .page-not-found {
    &__content {
      padding-top: rem(70px);
      padding-bottom: rem(70px);
      h2 {
        margin-top: rem(30px);
      }
    }
  }
}

@media screen and (max-width: rem(600px)) {
  .page-not-found {
    &__container {
      padding-left: rem(15px);
      padding-right: rem(15px);
    }
    &__content {
      &:before {
        display: none;
      }
    }
    &__article-logo {
      &__container {
        padding-left: rem(15px);
        padding-right: rem(15px);
      }
    }
  }
}

@media screen and (max-width: rem(1180px)) {
  .erom-hide-on-tablet {
    display: none;
  }
  .erom-editor__content-row {
    &.grid_75-25 {
      -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      & > div {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          width: 100%;
        }
      }
    }
  }
  .erom-reverse-order-tablet {
    -ms-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: rem(600px)) {
  .erom-editor__content-container {
    padding-left: rem(15px);
    padding-right: rem(15px);
  }
}
