
// FONTS
$montserrat: 'Montserrat', sans-serif;
$arial: Arial, Helvetica, sans-serif;

// GRIDS
$mainContainer: 1200px;
$sliderContainer: 1330px;
$contentContainer: 840px;
$persianGreen: #00A490;

// COLORS
$gray: #2c2a29;
$whiteGray: #ebebeb;
$mainColor: #005C6E;

// THEME COLORS
$orange: #ffcc00;
$red: #f52744;
$wisteria: #aa5ab8;
$cranberry: #DD5197;
$blue: #1f6fc5;
$green: #00d693;

// MIXINS
@mixin reset-input($fontSize, $background, $color) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 10px;
  opacity: 1;
  border: none;
  background: $background !important;
  @include font-size($fontSize);
  color: $color;
  &::-webkit-input-placeholder {
    color: $color;
    opacity: 0.3;
    caret-color: $color;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 0.3;
    caret-color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 0.3;
    caret-color: $color;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

// font mixins
@mixin font-size($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

.header-mobile-menu {
  display: none;
}

#abilityHeader {
  background: $mainColor;
  height: 100px;
  .container {
    height: 100%;
  }
  .header-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    height: 100%;
  }
  .logo {
    @include font-size(16px);
    font-family: $arial;
    color: #FFFFFF;
    img {
      width: 190px;
      height: auto;
    }
  }
  .top {
    &-menu {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: space-between;
      vertical-align: middle;
      align-items: center;
      .menu {
        list-style: none;
        margin: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        padding: 0;
        li {
          display: inline-block;
          padding: 0 10px;
          margin: 0;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          line-height: normal;
          &:last-of-type {
            border-right: none;
          }
          a {
            color: #FFFFFF;
            text-decoration: none;
            line-height: 18px;
            display: inline;
            @include font-size(18px);
            font-family: $arial;
            position: relative;
            z-index: 0;
            border-bottom: 0px solid $persianGreen;
            -webkit-transition: border-bottom-width .2s ease-in-out;
            -moz-transition: border-bottom-width .2s ease-in-out;
            -ms-transition: border-bottom-width .2s ease-in-out;
            -o-transition: border-bottom-width .2s ease-in-out;
            transition: border-bottom-width .2s ease-in-out;
            &:hover,
            &:focus {
              border-bottom-width: 5px;
            }
          }
        }
      }
    }
    &-search {
      .mobile-search-trigger {
        width: 2.1875rem;
        height: 2.1875rem;
        background: #00A490 url("data:image/svg+xml,%3Csvg id%3D%22Layer_1%22 data-name%3D%22Layer 1%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox%3D%220 0 17.74 17.74%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3Esearch-loop%3C%2Ftitle%3E%3Cpath class%3D%22cls-1%22 d%3D%22M1297.92%2C58.08l-4.31-4.31a7.57%2C7.57%2C0%2C1%2C0-.75.75l4.31%2C4.31a0.54%2C0.54%2C0%2C0%2C0%2C.37.16%2C0.52%2C0.52%2C0%2C0%2C0%2C.37-0.16%2C0.53%2C0.53%2C0%2C0%2C0%2C0-.75h0ZM1281.4%2C48.8a6.5%2C6.5%2C0%2C1%2C1%2C6.5%2C6.51%2C6.51%2C6.51%2C0%2C0%2C1-6.5-6.51h0Zm0%2C0%22 transform%3D%22translate(-1280.34 -41.24)%22%2F%3E%3C%2Fsvg%3E") no-repeat center;
        background-size: 1.25rem 1.25rem;
        text-indent: -9999px;
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        &[aria-expanded="true"] {
          background: #00A490 url("images/search-exit.png") no-repeat center;
          background-size: 1.25rem 1.25rem;
        }
      }
      .search {
        &-label {
          width: 2.1875rem;
          height: 2.1875rem;
          background: #00A490 url("data:image/svg+xml,%3Csvg id%3D%22Layer_1%22 data-name%3D%22Layer 1%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox%3D%220 0 17.74 17.74%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3Esearch-loop%3C%2Ftitle%3E%3Cpath class%3D%22cls-1%22 d%3D%22M1297.92%2C58.08l-4.31-4.31a7.57%2C7.57%2C0%2C1%2C0-.75.75l4.31%2C4.31a0.54%2C0.54%2C0%2C0%2C0%2C.37.16%2C0.52%2C0.52%2C0%2C0%2C0%2C.37-0.16%2C0.53%2C0.53%2C0%2C0%2C0%2C0-.75h0ZM1281.4%2C48.8a6.5%2C6.5%2C0%2C1%2C1%2C6.5%2C6.51%2C6.51%2C6.51%2C0%2C0%2C1-6.5-6.51h0Zm0%2C0%22 transform%3D%22translate(-1280.34 -41.24)%22%2F%3E%3C%2Fsvg%3E") no-repeat center;
          background-size: 1.25rem 1.25rem;
          text-indent: -9999px;
          position: absolute;
          right: 0;
          top: 0;
          span {
            position: absolute;
            clip: rect(1px, 1px, 1px, 1px);
            padding: 0;
            border: 0;
            height: 1px;
            width: 1px;
            overflow: hidden;
          }
        }
      }
      form {
        position: relative;
        height: 2.1875rem;
        margin-left: 20.9375rem;
        input {
          visibility: visible;
          &[type="submit"] {
            @include reset-input(18px, transparent, $gray);
            overflow: hidden;
            text-indent: -9999px;
            width: 2.1875rem;
            height: 2.1875rem;
            position: absolute;
            right: 0;
            top: 0;
            background: #00A490 url("data:image/svg+xml,%3Csvg id%3D%22Layer_1%22 data-name%3D%22Layer 1%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox%3D%220 0 17.74 17.74%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3Esearch-loop%3C%2Ftitle%3E%3Cpath class%3D%22cls-1%22 d%3D%22M1297.92%2C58.08l-4.31-4.31a7.57%2C7.57%2C0%2C1%2C0-.75.75l4.31%2C4.31a0.54%2C0.54%2C0%2C0%2C0%2C.37.16%2C0.52%2C0.52%2C0%2C0%2C0%2C.37-0.16%2C0.53%2C0.53%2C0%2C0%2C0%2C0-.75h0ZM1281.4%2C48.8a6.5%2C6.5%2C0%2C1%2C1%2C6.5%2C6.51%2C6.51%2C6.51%2C0%2C0%2C1-6.5-6.51h0Zm0%2C0%22 transform%3D%22translate(-1280.34 -41.24)%22%2F%3E%3C%2Fsvg%3E") no-repeat center;
            background-size: 1.25rem 1.25rem;
            z-index: 2;
            cursor: pointer;
          }
          &[type="text"] {
            @include reset-input(18px, #ffffff, $gray);
            width: 17.1875rem;
            padding: 0 0.625rem;
            height: 2.1875rem;
            position: absolute;
            right: 2.1875rem;
            -webkit-transition: width .2s ease-in-out;
            -moz-transition: width .2s ease-in-out;
            -ms-transition: width .2s ease-in-out;
            -o-transition: width .2s ease-in-out;
            transition: width .2s ease-in-out;
            &::-webkit-input-placeholder {
              @include font-size(18px);
            }
            &:-moz-placeholder {
              @include font-size(18px);
            }
            &::-moz-placeholder {
              @include font-size(18px);
            }
            &:-ms-input-placeholder {
              @include font-size(18px);
            }
            &::-ms-input-placeholder {
              @include font-size(18px);
            }
            &::placeholder {
              @include font-size(18px);
            }
          }
        }
      }
    }
  }
  .top-menu-trigger {
    display: none;
  }
}

.main-menu-trigger {
  display: none;
}

.expand-submenu-icon {
  display: none;
  position: absolute;
  right: 15px;
  top: 16px;
  width: 20px;
  height: 20px;
  background: url("images/plus.svg") no-repeat center center;
  background-size: 11px;
}

@media screen and (max-width: 80rem) {
  #abilityHeader {
    .container {
      max-width: 100%;
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 73.75rem) {
  #abilityHeader {
    height: 5rem;
    .logo {
      img {
        width: 8.0625rem;
      }
    }
    .top {
      &-menu {
        &.search-active {
          .top {
            &-menu-items {
              opacity: 0;
            }
            &-search {
              form {
                input {
                  visibility: visible;
                  &[type="text"] {
                    padding: 0 0.9375rem !important;
                    width: 22.8125rem !important;
                    right: 2.5rem;
                  }
                  &[type="text"] {
                    display: block;
                  }
                  &[type="submit"] {
                    display: block;
                  }
                }
              }
              .mobile-search-trigger {
                background-color: transparent !important;
              }
              .search-exit {
                display: block;
              }
            }
          }
        }
        &-items {
          opacity: 1;
          -webkit-transition: opacity .4s ease-in-out;
          -moz-transition: opacity .4s ease-in-out;
          -ms-transition: opacity .4s ease-in-out;
          -o-transition: opacity .4s ease-in-out;
          transition: opacity .4s ease-in-out;
        }
      }
      &-search {
        form {
          margin-left: 4.0625rem;
          input {
            &[type="text"] {
              width: 0px !important;
              padding: 0 !important;
              position: absolute;
              visibility: visible !important;
              &:focus {
                padding: 0 0.9375rem !important;
                width: 22.8125rem !important;
              }
            }
            &[type="text"] {
              display: none;
            }
            &[type="submit"] {
              display: none;
              top: 0;
              width: 2.1875rem;
              height: 2.1875rem;
              background: #00A490 url("data:image/svg+xml,%3Csvg id%3D%22Layer_1%22 data-name%3D%22Layer 1%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox%3D%220 0 17.74 17.74%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3Esearch-loop%3C%2Ftitle%3E%3Cpath class%3D%22cls-1%22 d%3D%22M1297.92%2C58.08l-4.31-4.31a7.57%2C7.57%2C0%2C1%2C0-.75.75l4.31%2C4.31a0.54%2C0.54%2C0%2C0%2C0%2C.37.16%2C0.52%2C0.52%2C0%2C0%2C0%2C.37-0.16%2C0.53%2C0.53%2C0%2C0%2C0%2C0-.75h0ZM1281.4%2C48.8a6.5%2C6.5%2C0%2C1%2C1%2C6.5%2C6.51%2C6.51%2C6.51%2C0%2C0%2C1-6.5-6.51h0Zm0%2C0%22 transform%3D%22translate(-1280.34 -41.24)%22%2F%3E%3C%2Fsvg%3E") no-repeat center !important;
              background-size: 1.25rem 1.25rem !important;
              text-indent: -9999px;
              position: absolute;
              right: 2.5rem;
              z-index: 9999;
            }
          }
          label {
            z-index: 3;
          }
        }
        .search-label {
          display: none;
        }
        .mobile-search-trigger {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 60.5rem) {
  #abilityHeader {
    height: 3.75rem;
    position: relative;
    .logo {
      img {
        width: 5.375rem;
      }
    }
    & > .container {
      padding: 0 15px;
    }
    .top {
      &-search {
        .mobile-search-trigger {
          background-color: transparent !important;
        }
        .search {
          &-label {
            background-color: transparent;
          }
          &-exit {
            background-color: transparent;
          }
        }
        form {
          margin-left: 3.4375rem;
          input {
            &[type="text"] {
              width: 0px !important;
              padding: 0 !important;
              position: absolute;
              visibility: visible !important;
              right: 40px;
              &:focus {
                padding: 0 10px !important;
                width: 200px !important;
              }
            }
          }
        }
      }
      &-menu {
        height: 100%;
        &.search-active {
          input {
            &[type="text"] {
              padding: 0 10px !important;
              width: 200px !important;
            }
            &[type="submit"] {
              visibility: visible;
              display: block;
            }
          }
          .search {
            &-label {
              display: none;
            }
          }
        }
      }
    }
  }
  #navbar {
    display: none;
  }
  .top-menu-items {
    display: none;
  }
  .header-mobile-menu {
    display: inline-block;
    padding-right: 0;
    height: 100%;
    &.active {
      & + div.top-search {
        border-color: transparent !important;
      }
      .main-menu {
        &-trigger {
          & > span {
            & > span {
              &:nth-of-type(1) {
                top: 0.4375rem;
                transform: rotate(-45deg);
              }
              &:nth-of-type(2) {
                -webkit-transition-delay: 0.5s;
                -moz-transition-delay: 0.5s;
                -ms-transition-delay: 0.5s;
                -o-transition-delay: 0.5s;
                transition-delay: 0.5s;
                opacity: 0;
                display: none;
              }
              &:nth-of-type(3) {
                bottom: 0.4375rem;
                transform: rotate(45deg);
              }
            }
          }
        }
        &-container {
          display: block;
        }
      }
    }
  }
  .main-menu {
    &-trigger {
      display: block;
      @include font-size(18px);
      font-family: $arial;
      font-weight: bold;
      position: relative;
      height: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      opacity: 1;
      border: none;
      background: transparent ;
      color: #FFFFFF;
      cursor: pointer;
      padding: 0 2.8125rem 0 1.25rem;
      outline: 0 !important;
      & > span {
        width: 1.375rem;
        height: 1rem;
        position: absolute;
        right: 0.3125rem;
        top: 50%;
        margin-top: -0.5rem;
        span {
          content: '';
          display: block;
          position: absolute;
          width: 1.375rem;
          height: 0.125rem;
          background: #FFFFFF;
          -webkit-border-radius: 0.125rem;
          -moz-border-radius: 0.125rem;
          border-radius: 0.125rem;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          opacity: 1;
          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            -webkit-transition-delay: 0.5s;
            -moz-transition-delay: 0.5s;
            -ms-transition-delay: 0.5s;
            -o-transition-delay: 0.5s;
            transition-delay: 0.5s;
            top: 0.4375rem;
          }
          &:nth-of-type(3) {
            bottom: 0;
          }
        }
      }
    }
    &-container {
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 99;
      width: 100%;
      max-width: 25.9375rem;
      background: #FFFFFF;
      border-top: 0.625rem solid #00a490;
      border-bottom: 0.625rem solid #00a490;
      & > ul {
        font-weight: bold;
        list-style: none;
        margin: 20px 0;
        padding: 0;
        & > li {
          -webkit-transition: all .2s ease-in-out;
          -moz-transition: all .2s ease-in-out;
          -ms-transition: all .2s ease-in-out;
          -o-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
          position: relative;
          & > a,
          & > button {
            padding: 5px 0;
            margin: 0 15px;
            display: block;
            color: $gray;
            border-bottom: 1px solid #00a490;
            font-family: $arial;
            display: block;
            width: calc(100% - 30px);
            font-weight: bold;
            text-align: left;
            @include font-size(16px);
          }
          &.active {
            border-bottom-width: 5px;
            & > a {
              color: #6b6a69;
            }
            &.has-submenu {
              & > .nav-item-collapse {
                &:after {
                  -webkit-transform: rotate(-90deg);
                  -moz-transform: rotate(-90deg);
                  -ms-transform: rotate(-90deg);
                  -o-transform: rotate(-90deg);
                  transform: rotate(-90deg);
                }
              }
              .sub-nav-footer,
              .sub-nav-group {
                display: block;
              }
            }
          }
          &.has-submenu {
            & > a,
            & > button {
              padding-right: 30px;
              line-height: 1.75rem;
            }
            & > .nav-item-collapse {
              padding-right: 30px;
              position: relative;
              background: transparent;
              &:after {
                content: ' ';
                position: absolute;
                right: 0.625rem;
                top: 50%;
                margin-top: -0.3125rem;
                width: 0.625rem;
                height: 0.9375rem;
                background: url("images/arrow-gray.svg") no-repeat center;
                background-size: 0.5625rem 0.75rem;
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                transform: rotate(90deg);
                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                -ms-transition: all .2s ease-in-out;
                -o-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
              }
            }
            .sub-nav-group {
              display: none;
              list-style: none;
              margin: -1px 0 0;
              background: #005c6e;
              border-top: 5px solid #00a490;
              border-bottom: 5px solid #00a490;
              padding: 10px 25px 10px 30px;
              a {
                color: #FFFFFF;
                font-weight: normal;
                font-family: $arial;
                display: block;
                padding: 0.3125rem 0;
              }
            }
          }
        }
      }
      .sub-nav-footer {
        display: none;
        margin-top: -1px;
        background-color: #ebebeb;
        border-top: 4px solid #00a490;
        border-bottom: 10px solid #00a490;
        text-align: center;
        .content {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          justify-content: center;
          vertical-align: middle;
          align-items: center;
          height: 5rem;
          padding: 0 15px;
          img {
            display: inline-block;
            height: 2.8125rem !important;
            width: auto !important;
            margin-right: 0.9375rem;
          }
          p {
            display: inline-block;
            margin: 0;
            padding: 0;
          }
          a {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            width: 100%;
            @include font-size(22px);
            font-family: $montserrat;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2c2a29;
          }
        }
      }
    }
  }
  .expand-submenu-icon {
    display: block;
  }
}

@media screen and (max-width: 60.5rem) {
  #abilityHeader {
    .top-menu-items {
      &.active {
        .top-menu-trigger {
          &:after {
            -webkit-transform: rotateX(-180deg);
            -moz-transform: rotateX(-180deg);
            -ms-transform: rotateX(-180deg);
            -o-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
          }
        }
        .region-top-nav {
          position: absolute;
          display: block;
          right: 0;
        }
      }
    }
    .top-menu-trigger {
      display: block;
      height: 2.8125rem;
      line-height: 2.8125rem;
      margin: 0;
      padding: 0 2.5rem 0 1.25rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      margin: 0;
      opacity: 1;
      border: none;
      background: transparent ;
      color: #ffffff;
      @include font-size(16px);
      font-family: $arial;
      cursor: pointer;
      border-right: 0.0625rem solid rgba(255, 255, 255, 0.5);
      border-left: 0.0625rem solid rgba(255, 255, 255, 0.5);
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0.5625rem;
        height: 0.375rem;
        right: 1.25rem;
        top: 50%;
        margin-top: -0.1875rem;
        background: url(images/arrow.svg) no-repeat center;
        background-size: 0.5625rem 0.375rem;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
    .region-top-nav {
      display: none;
    }
  }
}

@media screen and (max-width: 36.25rem) {
  #abilityHeader .top-menu.search-active .top-search form input[type="text"] {
    width: 11.25rem !important;
  }
}

@media screen and (max-width: 22.5rem) {
  #abilityHeader .top-menu.search-active .top-search form input[type="text"] {
    width: 8.75rem !important;
  }
}

@media screen and (max-width: 60.5rem) {
  #abilityHeader {
    .top-menu-items {
      &.active {
        .top-menu-trigger {
          &:after {
            -webkit-transform: rotateX(-180deg);
            -moz-transform: rotateX(-180deg);
            -ms-transform: rotateX(-180deg);
            -o-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
          }
        }
        .region-top-nav {
          position: absolute;
          display: block;
          right: 0;
        }
      }
    }
    .top-menu-trigger {
      display: block;
      height: 45px;
      line-height: 45px;
      margin: 0;
      padding: 0 40px 0 20px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      margin: 0;
      opacity: 1;
      border: none;
      background: transparent ;
      color: #ffffff;
      @include font-size(16px);
      font-family: $arial;
      cursor: pointer;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 9px;
        height: 6px;
        right: 20px;
        top: 50%;
        margin-top: -3px;
        background: url(images/arrow.svg) no-repeat center;
        background-size: 9px 6px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
    .region-top-nav {
      display: none;
    }
  }
}


// mega //

#navbar {
  border-bottom: 1px solid #EBEBEB;
  & > ul {
    min-height: 4.375rem;
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    -webkit-align-items: stretch;
    align-items: stretch;
    position: relative;
    & > li {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      width: 100%;
      &:nth-of-type(1),
      &:nth-of-type(2) {
        .sub-nav {
          left: 0px;
          transform: none;
        }
      }
      &:nth-of-type(5),
      &:nth-of-type(6) {
        .sub-nav {
          right: 0px;
          transform: none;
        }
      }
      & > a {
        height: auto;
        box-sizing: border-box;
        line-height: 1.5625rem;
        text-decoration: none;
        border: none;
        color: $gray;
        text-align: center;
        @include font-size(18px);
        font-family: $arial;
        font-weight: bold;
        padding: 22px 20px;
        text-shadow: none;
        display: block;
        &:focus {
          span {
            border-bottom-width: 5px;
          }
        }
        span {
          border-bottom: 0px solid $persianGreen;
          -webkit-transition: all .2s ease-in-out;
          -moz-transition: all .2s ease-in-out;
          -ms-transition: all .2s ease-in-out;
          -o-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
        }
        .arrow {
          display: inline-block;
          width: 8px;
          height: 12px;
          background: url("images/arrow-gray.svg") no-repeat center;
          background-size: 9px 12px;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
          -webkit-transition: all .2s ease-in-out;
          -moz-transition: all .2s ease-in-out;
          -ms-transition: all .2s ease-in-out;
          -o-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
          vertical-align: inherit;
          margin-left: 5px;
        }
      }
      & > a {
        &.open {
          span {
            border-width: 5px;
          }
          .arrow {
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
  .nav-item > a.open {
    background-color: #fff;
    border-bottom: none;
    z-index: 1;
  }

  .sub-nav {
    position: absolute;
    display: none;
    top: 100%;
    padding: 35px 0 0;
    border-top: 5px solid $persianGreen;
    border-bottom: 15px solid $persianGreen;
    background: rgb(0, 92, 110);
    z-index: 100;
    &-footer {
      min-height: 70px;
      background: #EBEBEB;
    }
    &.open {
      display: block;
    }
    & > ul {
      display: inline-block;
      vertical-align: top;
      padding: 0 40px;
      margin: 0 0 50px;
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      -webkit-column-gap: 80px; /* Chrome, Safari, Opera */
      -moz-column-gap: 80px; /* Firefox */
      column-gap: 80px;
      & > li {
        display: block;
        list-style-type: none;
        margin: 0;
        border-bottom: 1px solid #FFFFFF;
        page-break-inside: avoid;
        break-inside: avoid-column;
        list-style-type: none;
        & > a {
          display: block;
          min-width: 305px;
          padding: 5px 60px 5px 0;
          color: #ffffff;
          font-family: Arial, Helvetica, sans-serif;
          @include font-size(18px);
          position: relative;
          &:after {
            content: ' ';
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -7px;
            width: 10px;
            height: 14px;
            background: url("images/menu-arrow.png") no-repeat center;
            background-size: 10px 14px;
          }
          &:hover,
          &:focus {
            padding-left: 10px;
            padding-right: 50px;
            -webkit-transition: padding-left, background-color .2s, .2s ease-in-out;
            -moz-transition: padding-left, background-color .2s, .2s ease-in-out;
            -ms-transition: padding-left, background-color .2s, .2s ease-in-out;
            -o-transition: padding-left, background-color .2s, .2s ease-in-out;
            transition: padding-left, background-color .2s, .2s ease-in-out;
            -webkit-transition-delay: 0s, .5s ;
            -moz-transition-delay: 0s, .5s ;
            -ms-transition-delay: 0s, .5s ;
            -o-transition-delay: 0s, .5s ;
            transition-delay: 0s, .5s ;
            background-color: #ebebeb;
            color: #2c2a29;
            &:after {
              background: url("images/arrow-gray.svg") no-repeat center;
              background-size: 7px 11px;
              right: 10px;
              -webkit-transition: right .2s ease-in-out;
              -moz-transition: right .2s ease-in-out;
              -ms-transition: right .2s ease-in-out;
              -o-transition: right .2s ease-in-out;
              transition: right .2s ease-in-out;
            }
          }
        }
      }
    }
    &-footer {
      text-align: center;
      .content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
        height: 80px;
        padding: 0 15px;
        img {
          display: inline-block;
          height: 45px !important;
          width: auto !important;
          margin-right: 15px;
        }
        p {
          display: inline-block;
          margin: 0;
          padding: 0;
        }
        a {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          justify-content: center;
          vertical-align: middle;
          align-items: center;
          width: 100%;
          @include font-size(22px);
          font-family: $montserrat;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2c2a29;
          span {
            border-bottom: 0px solid #2c2a29;
            -webkit-transition: all .2s ease-in-out;
            -moz-transition: all .2s ease-in-out;
            -ms-transition: all .2s ease-in-out;
            -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
          }
          &:focus,
          &:hover {
            span {
              margin-bottom: -5px;
              border-bottom-width: 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 100.9375rem) {
  #navbar {
    & > ul {
      & > li {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          .sub-nav {
            left: 0px;
            transform: none;
          }
        }
        &:nth-of-type(5),
        &:nth-of-type(6) {
          .sub-nav {
            right: 0px;
            transform: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 67.5rem) {
  #navbar {
    & > ul {
      & > li {
        .sub-nav {
          left: 0px;
          transform: none;
          width: 100%;
          text-align: center;
          & > ul {
            text-align: left;
          }
        }
      }
    }
  }
}
